<template>
    <v-app>
        <v-system-bar
            v-if="showBanner()"
            color="orange"
            app
            height="30">
            <v-row align="center">
                <v-col cols="auto">
                    <span class="white--text">Stage Environment</span>
                </v-col>
                <v-col cols="auto">
                    <v-chip
                        dark
                        color="orange darken-3"
                        x-small
                        :href="rcTagLink()"
                        target="_blank">
                        {{rcTag()}}
                    </v-chip>
                </v-col>
                <v-spacer>
                </v-spacer>
                <v-col cols="auto">
                    <v-btn
                        class="mt-n1"
                        href="https://growlabai.atlassian.net/jira/software/projects/GROWLABAI/boards/1"
                        target="_blank"
                        x-small
                        color="error">
                        Submit Bug Ticket
                    </v-btn>
                </v-col>
            </v-row>
        </v-system-bar>
        <v-navigation-drawer
            v-if="isDashboard || isMobilePort"
            v-model="drawer"
            :width="isMobilePort ? '100%' : '320px'"
            height="100%"
            app
            style="z-index: 202;">
            <template>
                <v-list>
                    <v-list-item>
                        <v-list-item-content>
                            <v-row align="center">
                                <v-col>
                                    <span
                                        class="cp"
                                        @click="goHome()">
                                        <logo-branding>
                                        </logo-branding>
                                    </span>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn
                                        icon
                                        @click="drawer = false">
                                        <v-icon small>
                                            mdi-close
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider>
                </v-divider>
            </template>
            <v-list
                nav>
                <v-list-item-group
                    v-model="selectedItem"
                    color="primary">
                    <div v-for="(item, i) in navigation" :key="i">
                        <template v-if="!item.locked || (item.locked && user)">
                            <v-list-item
                                v-if="item.to"
                                :to="item.to"
                                @click="checkMobile()">
                                <v-list-item-icon
                                    v-if="item.icon"
                                    class="mr-1">
                                    <v-icon
                                        v-text="item.icon">
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{item.title}}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-group
                                v-else
                                :key="item.title"
                                no-action
                                :value="false">
                                <template v-slot:activator>
                                    <v-list-item-icon
                                        v-if="item.icon"
                                        class="mr-1">
                                        <v-icon
                                            v-text="item.icon">
                                        </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        {{ item.title }}
                                        <v-chip
                                            v-if="item.beta"
                                            class="ml-1"
                                            dark
                                            color="blue lighten-1">
                                            Beta
                                        </v-chip>
                                    </v-list-item-title>
                                </template>
                                <v-list-item
                                    class="pl-6"
                                    v-for="subLink in item.subLinks"
                                    :to="subLink.to"
                                    :key="subLink.title">
                                    <v-list-item-icon class="mr-1">
                                        <v-icon
                                            v-text="subLink.icon">
                                        </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        {{ subLink.title }}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list-group>
                        </template>
                    </div>
                </v-list-item-group>
            </v-list>
            <v-divider>
            </v-divider>
            <v-container>
                <v-row>
                    <v-col>
                        <v-btn
                            v-if="!isDashboard && !user"
                            block
                            rounded
                            large
                            color="success"
                            @click="goRegister()">
                            <v-icon left>
                                mdi-gift-outline
                            </v-icon>
                            Try for Free
                        </v-btn>
                        <v-btn
                            v-if="!isDashboard && user"
                            block
                            rounded
                            large
                            color="primary"
                            @click="goDashboard()">
                            <v-icon left>
                                mdi-view-dashboard-outline
                            </v-icon>
                            Dashboard
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-navigation-drawer>
        <app-bar
            ref="mainAppBar"
            id="main-app-bar"
            @toggle-drawer="drawer = !drawer">
        </app-bar>
        <v-main>
            <v-container
                fluid
                class="pa-0"
                :style="$route.name !== 'MonaAI' ? 'margin-bottom: 100px;' : ''">
                <notification-header>
                </notification-header>
                <router-view />
                <global-add v-if="user && !($route.name === 'MonaAI' || $route.name === 'Checkout')">
                </global-add>
            </v-container>
            <global-footer
                class="grey darken-4"
                v-if="!($route.name === 'MonaAI')">
            </global-footer>
        </v-main>
        <modal/>
        <snack-bar />
        <confirm-modal>
        </confirm-modal>
        <select-profile>
        </select-profile>
        <image-delete-modal>
        </image-delete-modal>
        <registration-redirect>
        </registration-redirect>
        <report-content-modal>
        </report-content-modal>
        <social-share>
        </social-share>
        <account-required-modal v-if="!user">
        </account-required-modal>
        <add-note-modal>
        </add-note-modal>
        <add-image-modal>
        </add-image-modal>
        <add-todo-list-modal>
        </add-todo-list-modal>
        <add-todo-item-modal>
        </add-todo-item-modal>
    </v-app>
</template>

<script>
    import Modal from './components/Modals/Modal.vue'
    import ConfirmModal from "./components/Modals/ConfirmDelete";
    import AppBar from './components/Application/AppBar.vue'
    import NavMenu from './components/Application/NavMenu.vue'
    import SnackBar from './components/Application/SnackBar.vue'
    import NotificationHeader from "./components/Application/NotificationHeader";
    import LogoBranding from './components/Application/LogoBranding';
    import SelectProfile from "./components/Images/SelectProfile";
    import ImageDeleteModal from "./components/Images/ImageDeleteModal";
    import RegistrationRedirect from "./components/Modals/RegistrationRedirect";
    import GlobalAdd from "./components/Application/GlobalAdd.vue";
    import ReportContentModal from "./components/ReportedContent/ReportContentModal.vue";
    import SocialShare from "./components/Modals/SocialShare.vue";
    import AccountRequiredModal from "./components/Modals/AccountRequiredModal.vue";
    import AddNoteModal from "./components/Notes/AddNoteModal.vue";
    import AddImageModal from "./components/Images/AddImageModal.vue";
    import GlobalFooter from "./components/Global/Footer.vue";
    import {UsersService} from "@/services/users.service";
    import AddTodoListModal from "@/components/Todo/AddTodoListModal.vue";
    import AddTodoItemModal from "@/components/Todo/AddTodoItemModal.vue";
    import {ReferralService} from "@/services/referral.service";

    export default {
        metaInfo: {
            title: 'GrowLab AI - Simplify Space, Plants, and Genetics Management',
            description: '',
            meta: [
                { 'charset': 'utf-8' },
                {
                    'property': 'description',
                    'content': 'Streamline your indoor growing journey with GrowLab AI. With our advanced platform, you can easily manage your spaces, plants, and genetics while adding notes and images for efficient cultivation. Enhance your experience further with Mona AI, our AI-powered chatbot that analyzes images and provides crucial insights to ensure the optimal health of your plants. Upgrade your growing game with GrowLab AI today!',
                    'vmid': 'description'
                },
                {
                    'property': 'og:title',
                    'content': 'GrowLab AI',
                    'template': chunk => `${chunk} - Welcome`,
                    'vmid': 'og:title'
                },
                {
                    'property': 'og:image',
                    'content': '/images/ad_png.png',
                    'vmid': 'og:image'
                }
            ],
            htmlAttrs: {
                lang: 'en',
                amp: true
            }
        },
        name: 'App',

        components: {
            AddTodoItemModal,
            AddTodoListModal,
            GlobalFooter,
            AddImageModal,
            AddNoteModal,
            AccountRequiredModal,
            GlobalAdd,
            ConfirmModal,
            AppBar,
            Modal,
            NavMenu,
            SnackBar,
            NotificationHeader,
            LogoBranding,
            SelectProfile,
            ImageDeleteModal,
            RegistrationRedirect,
            ReportContentModal,
            SocialShare
        },

        data: () => ({
            fab: false,
            drawer: false,
            selectedItem: 0,
            items: [
                {
                    title: 'Dashboard',
                    icon: 'mdi-view-dashboard-outline',
                    to: '/app/dashboard',
                    locked: true
                },
                {
                    title: 'Mona',
                    icon: 'mdi-creation-outline',
                    to: '/app/mona-ai'
                },
                {
                    title: 'Automation',
                    icon: 'mdi-cloud-sync-outline',
                    beta: true,
                    subLinks: [
                        {
                            title: 'Devices',
                            icon: 'mdi-cube-outline',
                            to: '/app/devices',
                        }
                    ]
                },
                {
                    title: 'My Garden',
                    icon: 'mdi-desktop-classic',
                    subLinks: [
                        {
                            title: 'Spaces',
                            icon: 'mdi-grid-large',
                            to: '/app/spaces'
                        },
                        {
                            title: 'Genetics',
                            icon: 'mdi-dna',
                            to: '/app/genetics'
                        },
                        {
                            title: 'Plants',
                            icon: 'mdi-sprout-outline',
                            to: '/app/plants'
                        },
                    ]
                },
                {
                    title: 'Collections',
                    icon: 'mdi-database-outline',
                    locked: true,
                    subLinks: [
                        {
                            title: 'Todo Lists',
                            icon: 'mdi-format-list-checkbox',
                            to: '/app/todo-lists',
                            locked: true
                        },
                        {
                            title: 'Notes',
                            icon: 'mdi-note-multiple-outline',
                            to: '/app/notes',
                            locked: true
                        },
                        {
                            title: 'Images',
                            icon: 'mdi-camera-outline',
                            to: '/app/images',
                            locked: true
                        },
                        {
                            title: 'Feeding Schedules',
                            icon: 'mdi-calendar-clock',
                            to: '/app/feeding-schedules'
                        },
                    ]
                },
            ],
            mini: true,
            interval: null,
            upSell: true,
            upSellExclude: [
                'SignIn',
                'Overview',
                'Register'
            ]
        }),
        computed: {
            navigation() {
                let links
                if (!this.isDashboard && this.isMobilePort) {
                    links = this.mainNavigation
                    const loginTitle = 'Login'
                    const loginChecker = links.findIndex(obj => obj.title === loginTitle)

                    if (this.user) {
                        if (loginChecker > 0) {
                            links.splice(loginChecker, 1)
                        }
                    } else {
                        if (loginChecker < 0) {
                            links.push({
                                title: loginTitle,
                                to: '/sign-in'
                            })
                        }
                    }
                    return links
                }
                links = this.items
                return links
            }
        },
        methods: {
            checkMobile() {
                if (this.isMobilePort) {
                    this.drawer = false;
                }
            },
            async checkUser() {
                try {
                    const response = await UsersService.verifyUser()
                    this.user = response.data.user
                    if (this.user && this.isDashboardLink) {
                        this.isDashboard = true
                    }
                } catch (error) {
                }
            },
            showBanner() {
                return process.env.VUE_APP_SHOW_BANNER === '1';
            },
            rcTag() {
                return process.env.VUE_APP_BANNER_TAG
            },
            rcTagLink() {
                return process.env.VUE_APP_BANNER_TAG_LINK
            },
            goHome() {
                this.isDashboard = false
                this.$router.push({name: 'Overview'})
            },
            goDashboard() {
                if (this.isMobilePort) {
                    this.drawer = false
                }
                this.$router.push({name: 'Dashboard'})
            },
            goRegister() {
                if (this.isMobilePort) {
                    this.drawer = false
                }
                this.$router.push({name: 'Register'})
            }
        },
        created() {
            this.checkUser();
        },
        mounted() {
            if (!this.isMobilePort) {
                this.drawer = true
            }
        }
    }
</script>

<style lang="scss">
    .cp {
        span {
            cursor: pointer;
        }
    }
    .fs48 {
        font-size: 48px!important;
        line-height: 58px!important;
    }
    .fs36 {
        font-size: 36px!important;
        line-height: 40px!important;
    }
    .cp {
        cursor: pointer;
    }
    .main-container {
        min-height: 100vh;
    }
    .content-container {
        max-width: 1200px;
        margin: 0 auto;
    }
    .v-btn {
        text-transform: none!important;
    }
    .bounce-transition {
        &-enter-active,
        &-leave,
        &-leave-to {
            transition: transform 3s cubic-bezier(0.3 0.7, 1);
        }

        &-enter,
        &-leave-to {
            transform: translateY(100px);
        }
    }
    .theme--light.v-application {
        background: #efefef!important;
    }
    .wysiwyg-output {
        p {
            margin-bottom: 10px;
        }
        ul {
            li {
                p {
                    margin-bottom: 0;
                }
            }
        }
    }
    .cky-consent-bar, .cky-modal {
        font-family: Roboto;
    }
    .cky-revisit-bottom-left {
        bottom: 20px!important;
        max-width: 20px;
        max-height: 20px;
        z-index: 1!important;
    }
    .v-btn--is-elevated {
        box-shadow: unset;
    }
    .theme--dark.v-sheet .markdown-it-container * {
        color: #FFFFFF;
    }

    .custom-scroll-light::-webkit-scrollbar, .custom-scroll-dark::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    .custom-scroll-light::-webkit-scrollbar-thumb, .custom-scroll-dark::-webkit-scrollbar-thumb {
        background: var(--scrollbar-thumb-bg);
        border-radius: 5px;
    }
    .custom-scroll-light::-webkit-scrollbar-thumb:hover, .custom-scroll-dark::-webkit-scrollbar-thumb:hover {
        background: var(--scrollbar-thumb-bg-hover);
    }
    .custom-scroll-light::-webkit-scrollbar-track, .custom-scroll-dark::-webkit-scrollbar-track {
        background: var(--scrollbar-track-bg);
    }
    /* Light theme */
    .custom-scroll-light {
        --scrollbar-thumb-bg: rgba(0, 0, 0, 0.2);
        --scrollbar-thumb-bg-hover: rgba(0, 0, 0, 0.4);
        --scrollbar-track-bg: transparent;
    }
    .custom-scroll-light::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.4);
    }
    .custom-scroll-light::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.1);
    }
    /* Dark theme */
    .custom-scroll-dark {
        --scrollbar-thumb-bg: rgba(255, 255, 255, 0.2);
        --scrollbar-thumb-bg-hover: rgba(255, 255, 255, 0.4);
        --scrollbar-track-bg: rgba(255, 255, 255, 0.1);
    }
    .custom-scroll-dark::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.4);
    }
    .custom-scroll-dark::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
    }
</style>
