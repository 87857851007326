<template>
    <v-dialog
        max-width="800"
        v-model="dialog"
        :fullscreen="isMobilePort">
        <v-card class="text-center">
            <v-card-title>
                <h3 class="font-weight-regular mx-auto">
                    Share
                </h3>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <p class="text-center mb-0">
                            Share this content on other networks or copy the link below.
                        </p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="title"
                            dense
                            outlined
                            label="Title"
                            hide-details>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea
                            v-model="description"
                            dense
                            outlined
                            hide-details
                            label="Description">
                        </v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <share-network
                            class="share-chip"
                            v-for="(network, key) in networks"
                            :key="key"
                            :network="network.name"
                            :url="link"
                            :title="title"
                            :description="description">
                            <v-chip
                                class="ma-1"
                                :color="network.color">
                                <v-icon
                                    left
                                    color="white">
                                    {{ network.icon }}
                                </v-icon>
                                <p class="mb-0 white--text">
                                    {{ network.name }}
                                </p>
                            </v-chip>
                        </share-network>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="link"
                            ref="link"
                            dense
                            outlined
                            hide-details
                            readonly
                            v-on:focus="$event.target.select()">
                        </v-text-field>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            icon
                            @click="copyLink"
                            :color="copied ? 'success' : 'primary'">
                            <v-icon>
                                {{copied ? 'mdi-check' : 'mdi-content-copy'}}
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer>
                </v-spacer>
                <v-btn
                    rounded
                    text
                    @click="dialog = false">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            copied: false,
            networks: [
                {
                    name: 'email',
                    icon: 'mdi-email',
                    color: 'black'
                },
                {
                    name: 'facebook',
                    icon: 'mdi-facebook',
                    color: 'blue'
                },
                {
                    name: 'reddit',
                    icon: 'mdi-reddit',
                    color: 'red'
                },
                {
                    name: 'linkedin',
                    icon: 'mdi-linkedin',
                    color: 'blue darken-2'
                },
                {
                    name: 'messenger',
                    icon: 'mdi-facebook-messenger',
                    color: 'blue'
                },
                {
                    name: 'skype',
                    icon: 'mdi-skype',
                    color: 'blue lighten-3'
                },
                {
                    name: 'sms',
                    icon: 'mdi-message-processing',
                    color: 'grey darken-4'
                },
                {
                    name: 'telegram',
                    icon: 'mdi-send-outline',
                    color: 'blue lighten-2'
                },
                {
                    name: 'twitter',
                    icon: 'mdi-twitter',
                    color: 'blue lighten-3'
                },
                {
                    name: 'whatsapp',
                    icon: 'mdi-whatsapp',
                    color: 'green'
                },
                {
                    name: 'wordpress',
                    icon: 'mdi-wordpress',
                    color: 'teal'
                }
            ],
            title: '',
            description: '',
            name: '',
            link: ''
        }
    },
    methods: {
        showModal(data) {
            if (data.title) {
                this.title = data.title;
            } else {
                this.title = 'GrowLab.AI';
            }
            if (data.description) {
                this.description = data.description;
            } else {
                this.description = 'Check out '+data.name+' from '+data.link+'.';
            }
            this.link = data.link;
            this.dialog = true;
        },
        copyLink() {
            this.$refs.link.focus();
            document.execCommand('copy');
            this.copied = true;
        }
    },
    created() {
        this.$EventBus.$on('showSocialShareModal', (data) => this.showModal(data))
    }
}
</script>

<style>
    .share-chip {
        text-decoration: none;
    }
    .share-chip .v-chip:hover {
        cursor: pointer;
    }
</style>