<template>
    <div>
        <v-data-iterator
            v-model="imagesSelected"
            :items="images[type]"
            item-key="_id"
            class="elevation-0"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            :search="search"
            hide-default-footer
            sort-by="createdAt"
            :sort-desc="true"
            :loading="this.$store.getters['images/Loading']"
            :options.sync="options"
            :server-items-length="pagination ? pagination.totalDocs : null">
            <template v-slot:no-data>
                <data-no-results>
                    <template v-slot:text>
                        No images found
                    </template>
                </data-no-results>
            </template>
            <template v-slot:loading>
                <card-loader>
                </card-loader>
            </template>
            <template v-slot:default="{items, isSelected, select}">
                <v-row v-if="profileSelect">
                    <v-col
                        v-for="(item, key) in items"
                        :key="key"
                        cols="12"
                        sm="3">
                        <v-card outlined>
                            <v-card-text>
                                <a
                                    @click="selectProfileImage(storeKey ? storeKey : type, associatedId, item._id)">
                                    <single-image
                                        :caption="caption"
                                        captionSmall
                                        :image="item">
                                    </single-image>
                                </a>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <template v-else>
<!--                    <v-row-->
<!--                        v-if="canEdit(item.userId._id)"-->
<!--                        align="center">-->
<!--                        <v-col cols="auto">-->
<!--                            <v-checkbox-->
<!--                                v-model="selectAll"-->
<!--                                class="ma-0 pa-0"-->
<!--                                :label="`(${imagesSelected.length}) Select All`"-->
<!--                                hide-details-->
<!--                                @click="setSelected(selectAll)">-->
<!--                            </v-checkbox>-->
<!--                        </v-col>-->
<!--                        <v-col>-->
<!--                            <v-menu offset-y>-->
<!--                                <template v-slot:activator="{ on, attrs }">-->
<!--                                    <v-btn-->
<!--                                        color="primary"-->
<!--                                        v-bind="attrs"-->
<!--                                        v-on="on"-->
<!--                                        :disabled="!imagesSelected.length">-->
<!--                                        Bulk Action-->
<!--                                        <v-icon dark>-->
<!--                                            mdi-chevron-down-->
<!--                                        </v-icon>-->
<!--                                    </v-btn>-->
<!--                                </template>-->
<!--                                <v-list>-->
<!--                                    <v-list-item-->
<!--                                        v-if="!item.albumId"-->
<!--                                        @click="dialog = true">-->
<!--                                        <v-list-item-title>-->
<!--                                            Add to album-->
<!--                                        </v-list-item-title>-->
<!--                                    </v-list-item>-->
<!--                                    <v-list-item-->
<!--                                        v-else-->
<!--                                        @click="removeImageFromAlbum(imagesSelected, type)">-->
<!--                                        <v-list-item-title>-->
<!--                                            Remove from album-->
<!--                                        </v-list-item-title>-->
<!--                                    </v-list-item>-->
<!--                                </v-list>-->
<!--                            </v-menu>-->
<!--                        </v-col>-->
<!--                    </v-row>-->
                    <v-row>
                        <v-col
                            v-for="(image, key) in items"
                            :key="key"
                            cols="12"
                            sm="3">
                            <v-card
                                outlined>
                                <v-card-title>
                                    <v-row
                                        dense
                                        align="center">
<!--                                        <v-col-->
<!--                                            v-if="canEdit(image.userId._id)"-->
<!--                                            cols="auto">-->
<!--                                            <v-checkbox-->
<!--                                                class="ma-0 pa-0"-->
<!--                                                :input-value="isSelected(image)"-->
<!--                                                hide-details-->
<!--                                                @click="select(image,!isSelected(image))">-->
<!--                                            </v-checkbox>-->
<!--                                        </v-col>-->
                                        <v-col cols="auto">
                                            <p class="caption mb-0">
                                                {{ image.createdAt | moment('MM/DD/YYYY') }}
                                            </p>
                                        </v-col>
                                        <v-col>
                                            <album-label
                                                v-if="image.albumId"
                                                :album-id="image.albumId">
                                            </album-label>
                                        </v-col>
                                        <v-col cols="auto">
                                            <images-menu
                                                v-if="image"
                                                :image="image"
                                                :item="item"
                                                :type="type"
                                                :album-id="image.albumId && image.albumId._id">
                                            </images-menu>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                                <v-card-text>
                                    <router-link
                                        class="text-decoration-none"
                                        :to="getImageLink(image._id)">
                                        <single-image
                                            :caption="caption"
                                            captionSmall
                                            :image="image"
                                            :size="size"
                                            theme="primary">
                                        </single-image>
                                    </router-link>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </template>
            </template>
        </v-data-iterator>
        <pagination
            v-if="images[type] && images[type].length"
            class="mt-5"
            store-key="images/GetPagination"
            :show-per-page="showPerPage"
            :items-per-page-array="itemsPerPageArray"
            @nextPage="nextPage"
            @formerPage="formerPage"
            @updateItem="updateItemsPerPage">
        </pagination>
        <album-select-modal
            :dialog="dialog"
            :item="item"
            :type="type"
            :selected-images="imagesSelected"
            @close="dialog = false">
        </album-select-modal>
    </div>
</template>

<script>
    import DataNoResults from '../Application/DataNoResults'
    import CardLoader from '../Application/CardLoader'
    import Pagination from "../Pagination/PaginationServer.vue";
    import SingleImage from "./SingleImage.vue";
    import ImagesMenu from './ImagesMenu'
    import AlbumSelectModal from "../Albums/AlbumSelectModal";
    import AlbumLabel from "../Albums/AlbumLabel";
    import ImageLabel from "../Chips/ImageChip.vue";

    export default {
        data() {
            return {
                dialog: false,
                search: '',
                options: {},
                imagesSelected: [],
                selectAll: false,
                itemsPerPage: 8,
                page: 1,
                itemsPerPageArray: [8, 16, 32, 64]
            }
        },
        props: {
            item: {
                required: true,
                type: Object
            },
            type: {
                required: true,
                type: String
            },
            profileSelect: {
                required: false,
                type: Boolean
            },
            associatedId: {
                required: false,
                type: String
            },
            showPerPage: {
                required: false,
                type: Boolean,
                default: true
            },
            storeKey: {
                required: false,
                type: String
            },
            size: {
                required: false,
                type: Number,
                default: 350
            },
            caption: {
                required: false,
                type: Boolean,
                default: true
            }
        },
        computed: {
            pagination() {
                return this.$store.getters['images/GetPagination'];
            }
        },
        watch: {
            options: {
                handler () {
                    this.getData()
                },
                deep: true,
            },
        },
        components: {
            ImageLabel,
            AlbumLabel,
            DataNoResults,
            CardLoader,
            Pagination,
            SingleImage,
            ImagesMenu,
            AlbumSelectModal
        },
        methods: {
            async getData() {
                const { page, itemsPerPage } = this.options
                let data = {
                    associatedId: this.item._id,
                    type: this.type,
                    page: page,
                    itemsPerPage: itemsPerPage,
                    onlyMe: this.profileSelect
                }
                await this.$store.dispatch('images/Get', data)
            },
            nextPage() {
                if (this.page + 1 <= this.pagination.totalPages) this.page += 1
            },
            formerPage() {
                if (this.page - 1 >= 1) this.page -= 1
            },
            updateItemsPerPage(number) {
                this.itemsPerPage = number
            },
            setSelected(trigger) {
                if (trigger) {
                    this.imagesSelected = this.images[this.type];
                } else {
                    this.imagesSelected = [];
                }
            }
        },
        created() {
            this.$EventBus.$on('refreshImages', () => this.getData())
        },
        mounted() {
            this.getAlbums(this.item._id, this.type);
        },
        beforeDestroy() {
            this.clearAlbums(this.type)
            this.clearImages(this.type)
        }
    }
</script>