import axios from 'axios'

import api from "./api";


export const UsersService = {
    verifyUser() {
        return api.get('/users/verify-user');
    },
    checkName(name) {
        return api.get(`/users/check-username/${name}`);
    },
    logout() {
        return api.get('/users/logout')
    },
    login(user) {
        return api.post('/users/login', user);
    },
    updateEmail(data) {
        return api.post('/users/update-email', data);
    },
    updatePassword(data) {
        return api.post('/users/update-password', data);
    },
    resetPassword(data) {
        return api.post('/users/reset-password', data);
    },
    verifyEmail(data) {
        return api.post('/users/confirm-email', data);
    },
    create(data) {
        return api.post('/users/create', data);
    },
    update(id, data) {
        return api.put('/users/update', data);
    },
    forgotPassword(data) {
        return api.post('/users/forgot-password', data);
    },
    resetPasswordCode(data) {
        return api.post('/users/reset-password-code', data);
    },
    requestNewPassword(data) {
        return api.post('/users/request-new-password', data);
    },
    resendConfirmationCode(data) {
        return api.post('/users/resend-confirmation-code', data);
    },
    googleAuth(data) {
        return api.post('/users/google-auth', data);
    }
}
