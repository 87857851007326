<template>
    <v-select
        :style="inlineStyle"
        dense
        outlined
        hide-details="auto"
        clearable
        v-model="selectedAlbum"
        :items="albums"
        item-text="title"
        item-value="_id"
        label="Select Album"
        :menu-props="{ offsetY: true }"
        :loading="loading">
        <template v-slot:no-data>
            <v-list>
                <v-list-item>
                    <p class="caption mb-0">
                        No albums found for {{$getConst('typeKeys')[type]}}
                    </p>
                </v-list-item>
            </v-list>
        </template>
        <template v-slot:append-item>
            <v-divider>
            </v-divider>
            <v-list-item class="text-center">
                <add-album-button
                    class="mx-auto mt-2"
                    :item="item"
                    :type="$getConst('typeKeys')[type]"
                    small>
                </add-album-button>
            </v-list-item>
        </template>
        <template v-slot:selection="{ item }">
            <v-chip small>
                <span>
                    {{ item.title }}
                </span>
            </v-chip>
        </template>
    </v-select>
</template>

<script>
    import AddAlbumButton from "./AddAlbumButton";

    export default {
        name: 'AlbumSelect',
        data() {
            return {
                loading: true,
                albums: [],
                selectedAlbum: null
            }
        },
        watch: {
            selectedAlbum(val) {
                this.update(val)
            }
        },
        props: {
            item: {
                required: true,
                type: Object
            },
            type: {
                required: true,
                type: String
            },
            inlineStyle: {
                required: false,
                type: String,
                default: ''
            }
        },
        components: {
            AddAlbumButton
        },
        methods: {
            update(value) {
                this.$emit('updateAlbum', value)
            },
            async getAlbumsSelect() {
                this.loading = true
                const albums = await this.getAlbums(this.item._id, this.type, true)
                this.albums = albums
                this.loading = false
            }
        },
        created() {
            this.$EventBus.$on('refreshAlbums', () => this.getAlbumsSelect())
        },
        mounted() {
            this.getAlbumsSelect()
        }
    }
</script>