<template>
    <v-dialog
        persistent
        max-width="1100"
        v-model="dialog"
        :fullscreen="isMobilePort">
        <v-card>
            <v-card-title>
                <v-row align="center">
                    <v-col>
                        Report Content
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            icon
                            small
                            @click="close()">
                            <v-icon>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-divider>
            </v-divider>
            <v-card-text class="pt-5">
                <notification-modular
                    v-if="type"
                    :notification-type="type"
                    :message="message">
                </notification-modular>
                <v-row>
                    <v-col>
                        <p>
                            Thank you for taking the time to report this content. Your submission will be reviewed by our team of administrators to ensure it aligns with our community standards. To help us better understand the issue, please provide a brief description of why you are reporting this content in the form below.
                        </p>
                    </v-col>
                </v-row>
                <v-form ref="reportContentForm">
                    <v-row>
                        <v-col>
                            <v-textarea
                                v-model="report.feedback"
                                outlined
                                label="Reason why your reporting this content?">
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer>
                </v-spacer>
                <v-btn
                    text
                    rounded
                    @click="close()">
                    {{type === 'success' ? 'Close' : 'Cancel'}}
                </v-btn>
                <v-btn
                    v-if="type !== 'success'"
                    rounded
                    color="success"
                    @click="reportContent()"
                    :loading="loading"
                    :disabled="loading">
                    Send
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import NotificationModular from "../Application/NotificationModular.vue";

    import {ReportedContentService} from "@/services/reported_content.service";

    export default {
        data() {
            return {
                dialog: false,
                loading: false,
                report: {
                    itemId: '',
                    userId: '',
                    feedback: '',
                    type: null
                },
                type: null,
                message: ''
            }
        },
        components: {
            NotificationModular
        },
        methods: {
            showReportContentModal(data = {}) {
                this.report.itemId = data.id
                this.report.userId = data.userId
                this.report.type = data.type
                this.dialog = true
            },
            defaultReport() {
                this.$set(this, 'report', {
                    itemId: '',
                    userId: '',
                    feedback: '',
                    type: null
                })
            },
            async reportContent() {
                this.loading = true
                try {
                    await ReportedContentService.post(this.report)
                    this.type = 'success'
                    this.message = 'This content has been reported.  You may now close this window.'
                } catch (error) {
                    this.loading = false
                    this.type = 'error'
                    this.message = error.toString()
                }
            },
            close() {
                this.dialog = false
                const _this = this
                setTimeout(function() {
                    _this.defaultReport()
                    _this.loading = false
                    _this.type = null
                    _this.message = ''
                }, this.$getConst('modalClearTimeout'))
            }
        },
        created() {
            this.$EventBus.$on('showReportContentModal', (data) => this.showReportContentModal(data))
        }
    }
</script>