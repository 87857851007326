<template>
    <v-dialog
        max-width="400"
        v-model="dialog"
        persistent>
        <v-card>
            <v-card-text class="py-8">
                <p class="text-center mb-0">
                    You will be redirected to Three Rabbit Holes Inc.'s account portal in <span class="red--text font-weight-bold">{{countDown}}</span> seconds. Some account features are still managed on this website. If you do not get redirected immediately, please click the "Go" button below to access all account features.
                </p>
            </v-card-text>
            <v-card-actions class="text-center">
                <v-spacer>
                </v-spacer>
                <v-btn
                    rounded
                    color="error"
                    @click="cancel()">
                    Cancel
                </v-btn>
                <v-btn
                    rounded
                    color="success"
                    @click="go()">
                    Go
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        data() {
            return {
                dialog: false,
                count: 10,
                url: ''
            }
        },
        computed: {
            countDown() {
                return this.count
            }
        },
        methods: {
            showModal(url) {
                this.url = url
                this.dialog = true
                this.timer = setInterval(() => {
                    this.count--
                    if (this.count === 0) {
                        clearInterval(this.timer)
                        this.go()
                    }
                }, 1000)
            },
            cancel() {
                clearInterval(this.timer)
                this.dialog = false
                this.url = ''
                this.count = 10
            },
            go() {
                window.open(this.url, '_blank')
                this.cancel()
            }
        },
        created() {
            this.$EventBus.$on('showRedirectModal', (url) => this.showModal(url))
        }
    }
</script>