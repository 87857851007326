import { FeedingSchedulesService } from '../services/feedingSchedules.service'
import {ErrorService} from "../services/error.service";

const feedingSchedulesGetterTypes = {
    error: 'GetError',
    feedingSchedules: 'FeedingSchedules',
    loading: 'Loading',
}

const feedingSchedulesMutationTypes = {
    setLoading: 'SetLoading',
    setSuccess: 'SetSuccess',
    setError: 'SetError',
}

const feedingSchedulesActionTypes = {
    get: 'GetAll',
    add: 'Add',
    update: 'Update',
    delete: 'Delete',
}

const state = () => ({
    error: null,
    records: undefined,
    loading: false,
})

const getters = {
    [feedingSchedulesGetterTypes.error]: (state) => state.error,
    [feedingSchedulesGetterTypes.feedingSchedules]: (state) => state.records,
    [feedingSchedulesGetterTypes.loading]: (state) => state.loading,
}

const mutations = {
    [feedingSchedulesMutationTypes.setLoading](state) {
        state.loading = true
    },

    [feedingSchedulesMutationTypes.setSuccess](state, data) {
        state.records = data
        state.loading = false
    },

    [feedingSchedulesMutationTypes.setError](state, message) {
        state.error = message;
        state.loading = false
    },
}

const actions = {
    async [feedingSchedulesActionTypes.get]({ commit }) {
        commit(feedingSchedulesMutationTypes.setLoading)

        try {
            const response = await FeedingSchedulesService.get(null, true)
            commit(feedingSchedulesMutationTypes.setSuccess, response.data.feedingSchedules)
        } catch ({error, response}) {
            commit(feedingSchedulesMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [feedingSchedulesActionTypes.add]({ dispatch, commit }, feedingScheduleData) {
        commit(feedingSchedulesMutationTypes.setLoading)

        try {
            await FeedingSchedulesService.post(feedingScheduleData)
            dispatch(feedingSchedulesActionTypes.get, true)
        } catch ({error, response}) {
            commit(feedingSchedulesMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [feedingSchedulesActionTypes.update]({ dispatch, commit }, payload) {
        try {
            await FeedingSchedulesService.put(payload.id, payload)
            await dispatch(feedingSchedulesActionTypes.get, true)
        } catch ({error, response}) {
            commit(feedingSchedulesMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [feedingSchedulesActionTypes.delete]({ dispatch, commit }, id) {
        try {
            await FeedingSchedulesService.delete(id)
            await dispatch(feedingSchedulesActionTypes.get, true)
        } catch ({error, response}) {
            commit(feedingSchedulesMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },
}

export const FeedingScheduleStoreModule = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}