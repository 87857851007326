const lookUp = [
    'users/GetError',
    'plants/GetError',
    'genetics/GetError',
    'spaces/GetError',
    'images/GetError',
    'feedingschedules/GetError',
    'albums/Error'
]

export default {
    computed: {
        errors() {
            let _this = this;
            let errors = [];
            _.forEach(lookUp, (value) => {
                let error = _this.$store.getters[value];
                if (error) {
                    errors.push(`${value} - ${error.message ? error.message : error}`);
                }
            })
            return errors;
        }
    }
}