<template>
    <v-text-field
        v-model="color"
        v-mask="mask"
        hide-details
        class="ma-0 pa-0"
        outlined
        label="Color">
        <template v-slot:append>
            <v-menu
                v-model="menu"
                top
                nudge-bottom="105"
                nudge-left="16"
                :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                    <div
                        :style="swatchStyle"
                        v-on="on">
                    </div>
                </template>
                <v-card>
                    <v-card-text class="pa-0">
                        <v-color-picker
                            v-model="color"
                            show-swatches
                            flat>
                        </v-color-picker>
                    </v-card-text>
                </v-card>
            </v-menu>
        </template>
    </v-text-field>
</template>

<script>
    import {mask} from 'vue-the-mask'

    export default {
        data() {
            return {
                color: this.$getConst('defaultColor'),
                menu: false,
                mask: '!#XXXXXX',
            }
        },
        props: {
            defaultColor: {
                required: false,
                type: String
            }
        },
        computed: {
            swatchStyle() {
                const {color, menu} = this;
                return {
                    edit: false,
                    backgroundColor: color,
                    cursor: 'pointer',
                    height: '24px',
                    width: '30px',
                    borderRadius: menu ? '50%' : '4px',
                    transition: 'border-radius 200ms ease-in-out'
                }
            }
        },
        watch: {
            color(val) {
                this.updateColor(val)
            }
        },
        directives: {mask},
        methods: {
            updateColor(value) {
                this.$emit('updateColor', value)
            }
        },
        mounted() {
            if (this.defaultColor) {
                this.color = this.defaultColor;
            }
        }
    }
</script>