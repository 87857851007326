import { AlbumsService } from "../services/albums.service";
import { ErrorService } from "../services/error.service";

const itemsGetterTypes = {
    error: 'Error',
    records: 'Records',
    loading: 'Loading'
}

const itemsMutationTypes = {
    setLoading: 'SetLoading',
    setSuccess: 'SetSuccess',
    setError: 'SetError',
    updateItem: 'UpdateItem',
    clearItem: 'ClearItem'
}

const itemsActionTypes = {
    get: 'Get',
    add: 'Add',
    update: 'Update',
    delete: 'Delete',
}

const state = () => ({
    error: null,
    records: {
        spaces: [],
        genetics: [],
        plants: []
    },
    loading: false,
})

const getters = {
    [itemsGetterTypes.error]: (state) => state.error,
    [itemsGetterTypes.records]: (state) => state.records,
    [itemsGetterTypes.loading]: (state) => state.loading,
}

const mutations = {
    [itemsMutationTypes.setLoading](state) {
        state.loading = true
    },

    [itemsMutationTypes.setSuccess](state, data) {
        state.records[data.type] = data.results
        state.loading = false
    },

    [itemsMutationTypes.setError](state, message) {
        state.error = message
        state.loading = false
    },

    [itemsMutationTypes.updateItem](state, data) {
        const records = state.records[data.type]
        const index = _.findIndex(records, {_id: data.results._id})
        records.splice(index, 1, data.results);
    },

    [itemsMutationTypes.clearItem](state, data) {
        if (data.id) {
            const records = state.records[data.type]
            const index = _.findIndex(records, {_id: data.id})
            records.splice(index, 1);
        } else {
            state.records[data.type] = []
        }
    },
}

const actions = {
    async [itemsActionTypes.get]({ state, commit }, data) {
        commit(itemsMutationTypes.setLoading)

        try {
            let response
            if (data) {
                response = await AlbumsService.getByAssociation(data)
            } else {
                response = await AlbumsService.get()
            }
            commit(itemsMutationTypes.setSuccess, {results: response.data.results, type: data.type})
        } catch ({error, response}) {
            commit(itemsMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [itemsActionTypes.add]({ dispatch, commit }, data) {
        try {
            await AlbumsService.post(data.item)

            dispatch(itemsActionTypes.get, data)
        } catch ({error, response}) {
            commit(itemsMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [itemsActionTypes.update]({ dispatch, commit }, data) {
        try {
            const response = await AlbumsService.put(data.item._id, data.item)
            commit(itemsMutationTypes.updateItem, {results: response.data.results, type: data.type})
        } catch ({error, response}) {
            commit(itemsMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [itemsActionTypes.delete]({ dispatch, commit }, data) {
        try {
            await AlbumsService.delete(data.id)
            commit(itemsMutationTypes.clearItem, data)
            await window.vm.$store.dispatch('images/GetUsage')
        } catch ({error, response}) {
            commit(itemsMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },
}

export const AlbumsStoreModule = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}