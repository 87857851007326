<template>
    <div>
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on">
                    <v-icon>
                        mdi-dots-vertical
                    </v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item :to="`/app/mona-ai/${image._id}`">
                    <v-list-item-title>
                        Ask Mona AI
                    </v-list-item-title>
                </v-list-item>
                <v-list-item
                    :to="getImageLink(image._id)"
                    rel="nofollow noopener noreferrer">
                    <v-list-item-title>
                        View
                    </v-list-item-title>
                </v-list-item>
                <template v-if="canEdit(image.userId._id)">
                    <v-list-item @click="editImage(image)">
                        <v-list-item-title>
                            Edit
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteImage(image)">
                        <v-list-item-title>
                            Delete
                        </v-list-item-title>
                    </v-list-item>
                </template>
                <template v-else>
                    <v-list-item
                        @click="showReportContentModal({
                            id: image._id,
                            userId: image.userId._id,
                            type: $getConst('typeKeys').images
                        })">
                        <v-list-item-title>
                            Report
                        </v-list-item-title>
                    </v-list-item>
                </template>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialog: false
            }
        },
        props: {
            image: {
                required: true,
                type: Object
            },
            type: {
                required: true,
                type: String
            }
        }
    }
</script>