<template>
    <div>
        <v-menu
            v-model="menu"
            top
            offset-y
            nudge-bottom="-10"
            min-width="230px"
            transition="slide-x-transition">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    small
                    dark
                    fixed
                    bottom
                    right
                    color="success"
                    @click="menu= !menu">
                    <v-icon
                        dark>
                        {{menu === true ? 'mdi-close-circle' : 'mdi-plus-circle'}}
                    </v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                    v-for="(item, index) in buttons"
                    :key="index"
                    @click="!user ? $EventBus.$emit('showAccountRequired') : showModal(item.action, item.options)">
                    <v-list-item-icon class="mr-2">
                        <v-icon>
                            {{item.icon}}
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ item.title }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <add-plant-modal>
        </add-plant-modal>
        <add-genetics-modal>
        </add-genetics-modal>
        <add-space-modal>
        </add-space-modal>
    </div>
</template>

<script>
    import AddPlantModal from "../../components/Plants/AddPlantModal.vue";
    import AddGeneticsModal from "../../components/Genetics/AddGeneticsModal.vue";
    import AddSpaceModal from "../../components/Spaces/AddSpaceModal.vue";

    export default {
        data() {
            return {
                menu: false,
                buttons: [
                    {
                        icon: 'mdi-camera-outline',
                        action: 'showAddImageModal',
                        title: 'Add Image'
                    },
                    {
                        icon: 'mdi-note-multiple-outline',
                        action: 'showAddNoteModal',
                        title: 'Add Note'
                    },
                    {
                        icon: 'mdi-format-list-checkbox',
                        action: 'showTodoListModal',
                        title: 'Add Todo List'
                    },
                    {
                        icon: 'mdi-sprout-outline',
                        action: 'showPlantModal',
                        options: {redirectAfter: true},
                        title: 'Add Plant'
                    },
                    {
                        icon: 'mdi-dna',
                        action: 'showGeneticsModal',
                        options: {redirectAfter: true},
                        title: 'Add Genetic'
                    },
                    {
                        icon: 'mdi-grid-large',
                        action: 'showSpaceModal',
                        options: {redirectAfter: true},
                        title: 'Add Space'
                    }
                ]
            }
        },
        components: {
            AddPlantModal,
            AddGeneticsModal,
            AddSpaceModal
        },
        methods: {
            showModal(action, options = []) {
                const type = this.getTypeFromURL()
                const id = this.$route.params.id
                if (type && id) {
                    options.type = type
                    options.associatedId = id
                }
                this.$EventBus.$emit(action, options)
            }
        }
    }
</script>