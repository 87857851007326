<template>
    <v-dialog
        persistent
        max-width="800"
        v-model="dialog"
        :fullscreen="isMobilePort">
        <v-card>
            <v-card-title>
                <v-row align="center">
                    <v-col>
                        Select Album
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            icon
                            small
                            @click="close()">
                            <v-icon>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-divider>
            </v-divider>
            <v-card-text class="pt-5">
                <v-row>
                    <v-col>
                        <p>
                            Which album would you like to associate these images to?
                        </p>
                        <album-select
                            :item="item"
                            :type="type"
                            @updateAlbum="(id) => this.albumId = id">
                        </album-select>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer>
                </v-spacer>
                <v-btn
                    text
                    rounded
                    @click="close()">
                    Cancel
                </v-btn>
                <v-btn
                    rounded
                    color="success"
                    @click="add()">
                    Add
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import AlbumSelect from "./AlbumSelect";

    export default {
        data() {
            return {
                albumId: null
            }
        },
        props: {
            dialog: {
                required: true,
                type: Boolean
            },
            item: {
                required: true,
                type: Object
            },
            type: {
                required: true,
                type: String
            },
            selectedImages: {
                required: true,
                type: Array
            }
        },
        components: {
            AlbumSelect
        },
        methods: {
            async add() {
                let _this = this;
                let payload = {
                    albumId: this.albumId
                }
                _.forEach(this.selectedImages, (value) => {
                    let data = {
                        id: value._id,
                        payload: payload,
                        type: _this.type
                    }
                    this.$store.dispatch('images/Update', data);
                })
                this.$nextTick(() => {
                    this.albumId = null;
                    this.$emit('close')
                })
            },
            close() {
                this.$emit('close')
            }
        }
    }
</script>