import api from "./api";

export const ImageService = {
    get() {
        return api.get('/images');
    },
    getById(data) {
        const { id, type = null, associatedId = null} = data
        return api.get(`/images/image/${id}/type/${type}/associated-id/${associatedId}`);
    },
    getByAssociation(data) {
        const { associatedId, type, page = 1, itemsPerPage = 8, onlyMe = false} = data
        return api.get(`/images/${associatedId}/type/${type}/page/${page}/per-page/${itemsPerPage}/only-me/${onlyMe}`)
    },
    upload(formData, onUploadProgress) {
        return api.post("/images/multiple-upload", formData);
    },
    put(data) {
        return api.put(`/images/id/${data.id}`, data.payload)
    },
    delete(data) {
        return api.delete(`/images/id/${data.id}/type/${data.type}`)
    },
    view(id) {
        return api.get(`/images/view/${id}`)
    },
}