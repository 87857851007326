<template>
    <div>
        <card-loader v-if="questionsLoading"></card-loader>
        <template v-else>
            <v-card
                v-for="(item, key) in sampleQuestions"
                :key="key"
                outlined
                max-width="800px"
                class="my-2 mx-auto"
                @click="setSampleQuestion(item)">
                <v-card-text class="pa-2">
                    <v-row align="center">
                        <v-col style="max-width: 90px;" v-if="item.image">
                            <single-image :image="item.image" :size="120"></single-image>
                        </v-col>
                        <v-col>
                            <p class="mb-0">{{ item.question }}</p>
                        </v-col>
                        <v-col cols="auto">
                            <v-icon color="success" x-large>mdi-chevron-right</v-icon>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </template>
        <v-card
            v-if="activeSampleQuestion"
            outlined
            max-width="800px"
            class="mx-auto mt-6">
            <div
                id="responseCard"
                class="px-2"
                style="overflow-y: auto; overflow-x: hidden; height: 400px;">
                <a-i-question-answer
                    :item="activeSampleQuestion"
                    hide-menu
                    use-text>
                </a-i-question-answer>
            </div>
        </v-card>
    </div>
</template>

<script>
import { AiService } from "@/services/ai.service";
import AIQuestionAnswer from "@/components/Ai/QuestionAnswer.vue";
import CardLoader from "@/components/Application/CardLoader.vue";
import SingleImage from "@/components/Images/SingleImage.vue";

export default {
    name: 'AiSampleQuestions',
    components: {SingleImage, CardLoader, AIQuestionAnswer},
    data() {
        return {
            questionsLoading: true,
            sampleQuestions: [],
            activeSampleQuestion: null,
            typingInterval: null,
        }
    },
    methods: {
        async getSampleQuestions() {
            this.questionsLoading = true;
            try {
                const response = await AiService.getSampleQuestions();
                this.sampleQuestions = response.data;
            } catch (error) {
                console.error(error);
            } finally {
                this.questionsLoading = false;
            }
        },
        simulateTypingEffect(responseText) {
            let typedText = '';
            let charIndex = 0;

            if (this.typingInterval) {
                clearInterval(this.typingInterval);
            }

            this.typingInterval = setInterval(() => {
                if (charIndex < responseText.length) {
                    typedText += responseText[charIndex++];
                    this.$set(this.activeSampleQuestion, 'response', typedText);
                    this.scrollToBottom();
                } else {
                    clearInterval(this.typingInterval);
                }
            }, 10); // Adjust the speed as necessary
        },
        setSampleQuestion(question) {
            this.activeSampleQuestion = null;
            this.activeSampleQuestion = {...question, response: ''};
            this.$nextTick(() => {
                this.$scrollTo('#responseCard', 500, {offset: -100});
                this.simulateTypingEffect(question.response);
            })
        },
        scrollToBottom() {
            const objDiv = document.getElementById("responseCard");
            objDiv.scrollTop = objDiv.scrollHeight;
        },
    },
    mounted() {
        this.getSampleQuestions();
    }
}
</script>
