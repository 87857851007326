import api from "./api";
import qs from "qs";

export const FeedingSchedulesService = {
    get(id = null, mine = false) {
        if (id) {
            return api.get(`/feeding-schedules/id/${id}`)
        } else if (mine) {
            return api.get('/feeding-schedules/mine')
        }
    },
    getResults(data) {
        const queryString = qs.stringify(data)
        return api.get(`/feeding-schedules?${queryString}`)
    },
    post(data) {
        return api.post('/feeding-schedules', data)
    },
    put(id, data) {
        return api.put(`/feeding-schedules/id/${id}`, data)
    },
    delete(id) {
        return api.delete(`/feeding-schedules/id/${id}`)
    },
}
