import api from "./api";
import qs from "qs";

export const AiService = {
    post(data) {
        return api.post('ai', data, {
            responseType: 'stream', // set the responseType to 'stream'
            onDownloadProgress: ({ data }) => { // handle download progress events
                console.log(data) // stream data to the console or handle it in some other way
            }
        });
    },
    submitAiQa(data) {
        return api.post('ai/submit-qa', data)
    },
    getQaCategories() {
        return api.get('ai/qa-categories')
    },
    getHistory() {
        return api.get('ai/history')
    },
    getQa(id) {
        return api.get(`ai/qa/id/${id}`)
    },
    getCommunityQa(data) {
        const queryString = qs.stringify(data)
        return api.get(`ai/qa-community?${queryString}`)
    },
    viewCommunityQa(id) {
        return api.get(`ai/qa-community-view/id/${id}`)
    },
    getTokens() {
        return api.get('ai/tokens')
    },
    redeemTokens() {
        return api.get('ai/tokens/redeem')
    },
    getSampleQuestions() {
        return api.get('ai/sample-questions')
    }
};