<template>
    <v-dialog
        persistent
        max-width="800"
        v-model="confirmDialog">
        <v-card>
            <v-card-title>
                Delete {{ topic }}
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <p>
                            Are you sure you want to delete this item?
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer>
                </v-spacer>
                <v-btn
                    rounded
                    text
                    color="error"
                    @click="close()">
                    No
                </v-btn>
                <v-btn
                    rounded
                    color="success"
                    @click="confirmDelete()"
                    :disabled="loading"
                    :loading="loading">
                    Yes
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import router from "@/router";

    export default {
        data() {
            return {
                confirmDialog: false,
                loading: false,
                data: {},
                storeAction: null,
                topic: null,
                redirect: null
            }
        },
        methods: {
            showModal(data) {
                this.data = data.item;
                this.storeAction = data.storeAction;
                this.topic = data.topic
                this.redirect = data.redirect
                this.confirmDialog = true;
            },
            close() {
                this.data = {};
                this.storeAction = null;
                this.topic = null
                this.loading = false
                this.confirmDialog = false;
                if (this.redirect) {
                    router.push({name: this.redirect});
                }
            },
            async confirmDelete() {
                this.loading = true
                await this.$store.dispatch(this.storeAction, this.data._id)
                this.$EventBus.$emit('refreshData')
                this.close()
            }
        },
        created() {
            this.$EventBus.$on('showConfirmDelete', (data) => this.showModal(data));
        }
    }
</script>