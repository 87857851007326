import api from "./api";
import qs from "qs";

export const GeneticsService = {
    get(id = null, mine = false) {
        if (id) {
            return api.get(`/genetics/id/${id}`)
        } else if (mine) {
            return api.get('/genetics/mine')
        } else {
            return api.get('/genetics');
        }
    },
    getResults(data) {
        const queryString = qs.stringify(data)
        return api.get(`/genetics?${queryString}`)
    },
    post(data) {
        return api.post('/genetics', data)
    },
    put(id, data) {
        return api.put(`/genetics/id/${id}`, data)
    },
    delete(id) {
        return api.delete(`/genetics/id/${id}`)
    }
}
