<template>
    <v-img
        :src="imageURL && imageURL"
        aspect-ratio="1"
        :contain="contain"
        class="mx-auto rounded-lg"
        style="height: 100%;">
        <v-card-title
            v-if="caption && image.caption"
            v-text="image.caption"
            class="white--text"
            :class="captionSmall ? 'caption pa-2' : ''"
            style="background: #000000b5;">
        </v-card-title>
        <template v-slot:placeholder>
            <v-sheet
                class="fill-height ma-0 rounded-lg text-center"
                :color="bgColor">
                <v-avatar
                    rounded
                    height="100%">
                    <v-progress-circular
                        v-if="loading"
                        :size="20"
                        :color="theme ? theme : 'white'"
                        indeterminate>
                    </v-progress-circular>
                    <v-icon
                        v-else
                        :size="iconSize ? iconSize : 20"
                        color="white">
                        {{getIcon()}}
                    </v-icon>
                </v-avatar>
            </v-sheet>
        </template>
    </v-img>
</template>

<script>
    import api from "@/services/api";

    export default {
        name: 'SingleImage',
        data() {
            return {
                imageURL: null,
                loading: false
            }
        },
        computed: {
            file() {
                const size = '120'
                return this.imageURL.replace('.jpg', `_${size}.jpg`)
            }
        },
        props: {
            caption: {
                required: false,
                type: Boolean,
                default: false
            },
            captionSmall: {
                required: false,
                type: Boolean,
                default: false
            },
            image: {
                required: false,
                type: Object
            },
            height: {
                required: false,
                type: String,
                default: '100%'
            },
            contain: {
                required: false,
                type: Boolean,
                default: true
            },
            size: {
                required: false,
                type: Number,
                default: 350
            },
            theme: {
                required: false,
                type: String,
                default: null
            },
            bgColor: {
                required: false,
                type: String
            },
            type: {
                required: false,
                type: String
            },
            iconSize: {
                required: false,
                type: Number
            }
        },
        methods: {
            getIcon() {
                let icon;
                switch (this.type) {
                    case this.$getConst('typeKeys').spaces:
                        icon = 'mdi-grid-large'
                        break;
                    case this.$getConst('typeKeys').genetics:
                        icon = 'mdi-dna'
                        break;
                    case this.$getConst('typeKeys').plants:
                        icon = 'mdi-sprout-outline'
                        break;
                    case this.$getConst('typeKeys').users:
                        icon = 'mdi-account'
                        break;
                }
                return icon;
            }
        },
        mounted() {
            this.loading = true
            if (this.image) {
                let image = this.image
                api.get(`/images/view/${image._id}${this.size ? '/'+this.size : ''}`, {
                    responseType: 'arraybuffer'
                })
                    .then(response => {
                        const base64 = btoa(
                            new Uint8Array(response.data)
                                .reduce((data, byte) => data + String.fromCharCode(byte), '')
                        );

                        this.imageURL = 'data:' + response.headers['content-type'] + ';base64,' + base64;
                    })
                    .catch(error => {
                        this.imageURL = null
                    })
                    .finally(() => {
                        this.loading = false
                    })
            } else {
                this.loading = false
            }
        }
    }
</script>