import api from "./api";
import qs from "qs";

export const PlantsService = {
    get(id = null, mine = false) {
        if (mine) {
            return api.get('/plants/mine')
        } else {
            return api.get('/plants');
        }
    },
    getResults(data) {
        const queryString = qs.stringify(data)
        return api.get(`/plants?${queryString}`)
    },
    getById(id) {
        return api.get(`/plants/id/${id}`)
    },
    getAssociated(id, type) {
        return api.get(`/plants/type/${type}/id/${id}`)
    },
    post(data) {
        return api.post('/plants', data)
    },
    put(id, data) {
        return api.put(`/plants/id/${id}`, data)
    },
    delete(id) {
        return api.delete(`/plants/id/${id}`)
    },
}
