<template>
    <v-chip
        :small="small"
        v-if="!this.$_.isEmpty(item)"
        :to="getLink()"
        :class="{'pl-2': small}">
        <profile-image
            :size="20"
            :item-id="item._id"
            :type="type"
            :image="item.profileImage"
            :item-color="item.color"
            :left="true">
        </profile-image>
        {{item.name}}
    </v-chip>
</template>

<script>
    import ProfileImage from "../Images/ProfileImage.vue";

    export default {
        name: 'ImageChip',
        props: {
            item: {
                required: true,
                type: Object
            },
            type: {
                required: true,
                type: String
            },
            small: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        components: {
            ProfileImage
        },
        methods: {
            getLink() {
                let url;
                switch (this.type) {
                    case this.$getConst('typeKeys').spaces:
                        url = '/spaces/view/'+this.item._id
                        break;
                    case this.$getConst('typeKeys').genetics:
                        url = '/genetics/view/'+this.item._id
                        break;
                    case this.$getConst('typeKeys').plants:
                        url = '/plants/view/'+this.item._id
                        break;
                }
                return url;
            }
        }
    }
</script>