<template>
    <v-dialog
        persistent
        max-width="800"
        v-model="confirmDialog">
        <v-card>
            <v-card-title>
                Delete Image
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <p>
                            Are you sure you want to delete this image? <b>This action will permanently remove it from any related item(s).</b>
                        </p>
                        <p class="mb-0">
                            If you only want to remove the image from a specific item, you can simply edit this image and remove the associated space, genetic, plant or album.
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer>
                </v-spacer>
                <v-btn
                    rounded
                    text
                    @click="close()">
                    No
                </v-btn>
                <v-btn
                    rounded
                    color="success"
                    :disabled="loading"
                    :loading="loading"
                    @click="confirmDelete()">
                    Yes
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        data() {
            return {
                confirmDialog: false,
                loading: false,
                data: {}
            }
        },
        methods: {
            showModal(data) {
                this.data = {
                    id: data.image._id,
                    type: data.type,
                    getData: {
                        associatedId: data.image[this.getFieldColumn(data.type)]?._id,
                        type: data.type
                    }
                };
                this.confirmDialog = true
            },
            close(closeImage = false) {
                this.data = {}
                this.confirmDialog = false
                this.loading = false
                if (closeImage) {
                    this.$EventBus.$emit('hideImageViewModal')
                }
            },
            async confirmDelete() {
                this.loading = true
                await this.$store.dispatch('images/Delete', this.data)
                this.scrollPosition = window.scrollY
                this.$EventBus.$emit('refreshImages')
                this.close(true)
            }
        },
        created() {
            this.$EventBus.$on('showImageConfirmDelete', (data) => this.showModal(data))
        }
    }
</script>