<template>
    <div>
        <v-row align="center">
            <v-col>
                <h3
                    v-if="title"
                    :class="additionalClass">
                    {{ title }}
                </h3>
            </v-col>
            <v-col cols="auto">
                <slot name="button">
                </slot>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                v-if="loading">
                <card-loader>
                </card-loader>
            </v-col>
            <v-col
                v-else-if="!items.length">
                <data-no-results class="mt-3">
                    <template v-slot:text>
                        No {{type}} found.  Try adding one.
                    </template>
                </data-no-results>
            </v-col>
            <v-col
                v-else
                v-for="(item, key) in items"
                :key="key"
                cols="6"
                :sm="smCols">
                <router-link
                    class="text-decoration-none"
                    :to="'/'+type+'/view/'+item._id">
                    <v-card
                        class="fill-height"
                        outlined>
                        <v-card-text>
                            <single-image
                                :class="isMobilePort ? 'mb-2' : 'mb-6'"
                                :image="item.profileImage"
                                :bg-color="item.color"
                                :size="350"
                                :type="type">
                            </single-image>
                            <h2
                                class="success--text"
                                :class="{'subtitle-1': isMobilePort}">
                                {{item.name}}
                            </h2>
                            <p
                                v-if="item.userId && !hideGrower"
                                class="mb-0 mt-3 font-weight-medium">
                                Grower: <span class="grey--text">{{item.userId.displayName}}</span>
                            </p>
                            <created-on-text
                                :created-at="item.createdAt"
                                min>
                            </created-on-text>
                        </v-card-text>
                    </v-card>
                </router-link>
            </v-col>
        </v-row>
        <slot
            v-if="items && items.length"
            name="button:bottom">
        </slot>
    </div>
</template>

<script>
    import CardLoader from "./CardLoader.vue";
    import {SpacesService} from "../../services/spaces.service";
    import {PlantsService} from "../../services/plants.service";
    import SingleImage from "../Images/SingleImage.vue";
    import CreatedOnText from "../Text/CreatedOnText.vue";
    import {GeneticsService} from "../../services/genetics.service";
    import DataNoResults from "../Application/DataNoResults.vue";

    export default {
        name: 'ItemCards',
        components: {DataNoResults, CreatedOnText, SingleImage, CardLoader},
        data() {
            return {
                loading: true,
                items: []
            }
        },
        props: {
            title: {
                required: false,
                type: String
            },
            additionalClass: {
                required: false,
                type: String
            },
            type: {
                required: true,
                type: String
            },
            limit: {
                required: false,
                type: Number,
                default: 4
            },
            onlyMe: {
                required: false,
                type: Boolean,
                default: true
            },
            hideGrower: {
                required: false,
                type: Boolean,
                default: false
            },
            smCols: {
                required: false,
                type: Number,
                default: 3
            },
            hasProfile: {
                required: false,
                type: Boolean,
                default: false
            },
            thumbSize: {
                required: false,
                type: Number,
                default: 360
            },
        },
        methods: {
            async getData() {
                let results
                let key
                const filters = {
                    sortBy: 'recently_updated',
                    currentPage: 1,
                    resultsPerPage: this.limit,
                    onlyMe: this.onlyMe,
                    hasProfileImage: this.hasProfile
                }
                switch (this.type) {
                    case this.$getConst('typeKeys').spaces:
                        results = await SpacesService.getResults(filters)
                        break;
                    case this.$getConst('typeKeys').genetics:
                        results = await GeneticsService.getResults(filters)
                        break;
                    case this.$getConst('typeKeys').plants:
                        results = await PlantsService.getResults(filters)
                        break;
                }
                this.items = results.data.results
                this.loading = false
            }
        },
        created() {
            this.$EventBus.$on('refreshData', () => this.getData())
        },
        mounted() {
            this.getData()
        }
    }
</script>