import axios from "axios";
import router from '../router/index';
import {store} from '../store/index';

const instance = axios.create({
    baseURL: process.env.VUE_APP_NODEJS_URI,
    withCredentials: true
})

instance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response && error.response.status === 404) {
            return router.push({ name: 'NotFound' });
        }
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            this.user = null
            this.isDashboard = false
            return router.push({name: 'SignIn'});
        }
        return Promise.reject(error);
    }
);

export default instance;