<template>
    <div
        :class="{'px-3': isMobilePort}"
        style="position: absolute; width: 100%; bottom: 0;">
        <div class="text-center">
            <v-btn
                v-for="(item, key) in socialMedia"
                :key="key"
                icon
                small
                color="grey darken-2"
                :href="item.to"
                target="_blank">
                <v-icon small>
                    {{item.icon}}
                </v-icon>
            </v-btn>
            <p
                class="mb-0 mt-1 grey--text"
                style="font-size: 11px;">
                &copy;{{ new Date().getFullYear() }}
                <strong>
                    <a
                        class="grey--text text-decoration-none"
                        href="https://threerabbitholes.com"
                        target="_blank">
                        Three Rabbit Holes Inc
                    </a>
                </strong>
            </p>
            <p
                class="grey--text mb-1"
                style="font-size: 11px;">
                <strong>
                    <template v-for="(item, key) in footerLinks">
                        <a
                            class="grey--text text-decoration-none"
                            :href="item.href"
                            :key="key"
                            :target="item.target">
                            {{item.title}}
                        </a>
                        <span v-if="key !== footerLinks.length - 1">&nbsp;|&nbsp;</span>
                    </template>
                </strong>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'GlobalFooter',
        data() {
            return {
                socialMedia: [
                    {
                        icon: 'fa-brands fa-facebook',
                        to: 'https://www.facebook.com/growlabai/'
                    },
                    {
                        icon: 'fa-brands fa-discord',
                        to: 'https://discord.gg/D8GNNpy2dT'
                    }
                ],
                footerLinks: [
                    {
                        href: 'https://threerabbitholes.com/contact/',
                        title: 'Contact',
                        target: '_blank'
                    },
                    {
                        href: '/privacy-policy',
                        title: 'Privacy Policy'
                    },
                    {
                        href: '/cancellation-policy',
                        title: 'Cancellation Policy'
                    },
                    {
                        href: '/terms-of-use',
                        title: 'Terms of Use'
                    },
                    {
                        href: '/ai-policy',
                        title: 'AI Policy'
                    }
                ]
            }
        }
    }
</script>