import {AlbumsService} from "@/services/albums.service";
import {ErrorService} from "@/services/error.service";

export default {
    data() {
        return {
        }
    },
    computed: {
        // albums() {
        //     if (this.$store.getters['albums/Records']) {
        //         return this.$store.getters['albums/Records'];
        //     }
        //     return [];
        // }
    },
    methods: {
        showAddAlbumModal(data, item = null) {
            if (item) {
                data.item = item;
            }
            this.$EventBus.$emit('showAddAlbumModal', data)
        },
        showDeleteAlbumModal(type, item) {
            const data = {
                id: item._id,
                type: type
            }

            this.$EventBus.$emit('showDeleteAlbumModal', data)
        },
        async setAlbumCover(image, type, reset = false) {
            const data = {
                item: {
                    _id: image.albumId._id,
                    cover: reset ? null : image
                },
                type: type
            }

            await this.$store.dispatch('albums/Update', data)
            return this.$EventBus.$emit('refreshAlbums')
        },
        removeImageFromAlbum(images, type) {
            let payload = {
                albumId: null
            }
            _.forEach(images, (value) => {
                if (value.albumId?.cover === value._id) {
                    this.setAlbumCover(value, type, true)
                }
                let data = {
                    id: value._id,
                    payload: payload,
                    type: type,
                    remove: true
                }
                this.$store.dispatch('images/Update', data);
            })
        },
        async getAlbums(associatedId, type) {
            const data = {
                associatedId: associatedId,
                type: type
            }

            try {
                let response
                if (data) {
                    response = await AlbumsService.getByAssociation(data)
                } else {
                    response = await AlbumsService.get()
                }
                return response.data.results
            } catch ({error, response}) {
                this.$store.commit('albums/SetError', ErrorService.parseError(error, response))
            }
        },
        clearAlbums(type) {
            this.$store.commit('albums/ClearItem', {type: type})
        }
    }
}