export default {
    computed: {
        isMobilePort() {
            return this.$vuetify.breakpoint.xsOnly;
        },
        isMobileAndTablet() {
            return this.$vuetify.breakpoint.smAndDown;
        },
        isMediumAndUp() {
            return this.$vuetify.breakpoint.mdAndUp;
        },
        isMediumAndDown() {
            return this.$vuetify.breakpoint.mdAndDown;
        },
        isLargeAndUp() {
            return this.$vuetify.breakpoint.lgAndUp;
        },
        isExtraLarge() {
            return this.$vuetify.breakpoint.xlOnly;
        }
    }
}