<template>
    <v-dialog
        persistent
        max-width="850"
        v-model="dialog"
        :fullscreen="isMobilePort">
        <v-card>
            <v-card-title>
                <v-row align="center">
                    <v-col>
                        {{edit ? 'Edit Image' : 'Add Images'}}
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            icon
                            small
                            @click="close()">
                            <v-icon>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <card-loader
                v-if="modalLoading"
                class="mx-3"
                flat>
            </card-loader>
            <v-card-text v-else>
                <notification-modular
                    v-if="type && message"
                    :notification-type="type"
                    :message="message">
                </notification-modular>
                <active-chips
                    class="mb-2"
                    :items="activeGardenAssociation"
                    @update="(data) => removeItems(data)">
                </active-chips>
                <v-form>
                    <v-row v-if="!edit">
                        <v-col>
                            <p class="caption mb-2">
                                *Max 10 images at a time
                            </p>
                            <v-file-input
                                v-model="fileInput"
                                hide-details="auto"
                                outlined
                                label="Select images to add"
                                prepend-inner-icon="mdi-camera"
                                prepend-icon=""
                                accept="image/*"
                                multiple
                                small-chips
                                truncate-length="15"
                                @change="selectFiles">
                            </v-file-input>
                        </v-col>
                    </v-row>
                    <template v-else>
                        <v-row class="mt-8">
                            <v-col>
                                <v-textarea
                                    v-model="image.caption"
                                    outlined
                                    class="mb-5"
                                    label="Caption"
                                    height="75px"
                                    hide-details>
                                </v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <p class="caption mb-1">
                                    Description:
                                </p>
                                <w-y-s-i-w-y-g-editor
                                    :default-description="image.description"
                                    @updateDescription="(data) => image.description = data">
                                </w-y-s-i-w-y-g-editor>
                            </v-col>
                        </v-row>
                    </template>
                    <v-row>
                        <v-col>
                            <v-card outlined>
                                <v-card-title
                                    @click="details = !details"
                                    style="cursor: pointer;">
                                    <h5>
                                        Additional Details
                                    </h5>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-icon>
                                        {{details ? 'mdi-chevron-up' : 'mdi-chevron-down'}}
                                    </v-icon>
                                </v-card-title>
                                <v-expand-transition>
                                    <div v-show="details">
                                        <v-card-text class="pt-0">
                                            <p class="mb-2">
                                                Choose to associate your entry with a specific space, genetic, or plant.
                                            </p>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    sm="6">
                                                    <spaces-select
                                                        multiple
                                                        :selected="image.spaceId"
                                                        @updateSpacesId="(data) => image.spaceId = data">
                                                    </spaces-select>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6">
                                                    <genetics-select
                                                        multiple
                                                        :selected="image.geneticsId"
                                                        @updateGeneticsId="(data) => image.geneticsId = data">
                                                    </genetics-select>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6">
                                                    <plants-select
                                                        multiple
                                                        :selected="image.plantId"
                                                        @updatePlantsId="(data) => image.plantId = data">
                                                    </plants-select>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </div>
                                </v-expand-transition>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer>
                </v-spacer>
                <v-btn
                    rounded
                    text
                    @click="close()">
                    Cancel
                </v-btn>
                <v-btn
                    rounded
                    color="success"
                    @click="addEditImage()"
                    :disabled="loading"
                    :loading="loading">
                    {{ edit ? 'Save' : 'Add' }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import SpacesSelect from "../Spaces/SpacesSelect.vue";
    import GeneticsSelect from "../Genetics/GeneticsSelect.vue";
    import PlantsSelect from "../Plants/PlantsSelect.vue";
    import {ImageService} from "../../services/image.service";
    import {PlantsService} from "../../services/plants.service";
    import WYSIWYGEditor from "../Forms/WYSIWYGEditor.vue";
    import ActiveChips from "@/components/Application/ActiveChips.vue";
    import {SpacesService} from "@/services/spaces.service";
    import {GeneticsService} from "@/services/genetics.service";
    import CardLoader from "@/components/Application/CardLoader.vue";
    import NotificationModular from "@/components/Application/NotificationModular.vue";

    export default {
        name: 'AddImageModal',
        data() {
            return {
                dialog: false,
                modalLoading: false,
                loading: false,
                edit: false,
                image: {
                    spaceId: null,
                    geneticsId: null,
                    plantId: null,
                },
                fileInput: [],
                selectedFiles: [],
                details: false,
                type: null,
                message: null
            }
        },
        components: {
            NotificationModular,
            CardLoader,
            ActiveChips,
            WYSIWYGEditor,
            PlantsSelect,
            GeneticsSelect,
            SpacesSelect
        },
        computed: {
            activeGardenAssociation() {
                return {
                    [this.$getConst('typeKeys').spaces]: this.image.spaceId ? this.image.spaceId : [],
                    [this.$getConst('typeKeys').genetics]: this.image.geneticsId ? this.image.geneticsId : [],
                    [this.$getConst('typeKeys').plants]: this.image.plantId ? this.image.plantId : [],
                }
            }
        },
        methods: {
            removeItems(data) {
                switch (data.type) {
                    case this.$getConst('typeKeys').spaces:
                        this.image.spaceId = data.items
                        break;
                    case this.$getConst('typeKeys').genetics:
                        this.image.geneticsId = data.items
                        break;
                    case this.$getConst('typeKeys').plants:
                        this.image.plantId = data.items
                        break;
                }
            },
            selectFiles(files) {
                this.selectedFiles = files;
            },
            getSelected(ids) {
                if (typeof ids === 'string' || ids instanceof String) {
                    return ids
                }
                return  ids ? ids.map(obj => obj._id) : ids
            },
            async showModal(data = null) {
                this.type = null
                this.message = null
                this.defaultImage()
                this.dialog = true
                this.modalLoading = true
                if (data && data.image) {
                    this.edit = true
                    this.image = Object.assign({}, data.image)
                } else {
                    if (data && data.associatedId && data.type) {
                        let response
                        switch (data.type) {
                            case this.$getConst('typeKeys').spaces:
                                response = await SpacesService.get(data.associatedId)
                                this.image.spaceId = [response.data.space]
                                break;
                            case this.$getConst('typeKeys').genetics:
                                response = await GeneticsService.get(data.associatedId)
                                this.image.geneticsId = [response.data.results]
                                break;
                            case this.$getConst('typeKeys').plants:
                                response = await PlantsService.getById(data.associatedId)
                                this.image.plantId = [response.data.plant]
                                break;
                        }
                    }
                }
                this.modalLoading = false
            },
            defaultImage() {
                this.$set(this, 'image', {
                    spaceId: null,
                    geneticsId: null,
                    plantId: null
                })
            },
            async addEditImage() {
                try {
                    this.type = null
                    this.message = null
                    this.loading = true
                    let imageData = Object.assign({}, this.image)
                    const loop = [
                        'spaceId',
                        'geneticsId',
                        'plantId'
                    ]
                    loop.forEach(obj => {
                        if (imageData[obj]) {
                            imageData[obj] = this.getSelected(imageData[obj])
                        }
                    })
                    if (this.edit) {
                        let data = {
                            id: this.image._id,
                            payload: imageData,
                        }
                        await ImageService.put(data)
                    } else {
                        if (this.$_.size(this.selectedFiles) === 0) {
                            this.message = 'Please select at least 1 file to upload.'
                            this.type = 'warning'
                            return
                        }

                        const date = Date.now()
                        let formData = new FormData()
                        for (let i = 0; i < this.selectedFiles.length; i++) {
                            if (i === 10) {
                                this.message = 'Uploaded the first 10 images.  Stopped at image number 10.'
                                this.type = 'warning'
                                break
                            }
                            const originalFile = this.selectedFiles[i]
                            const filePathArray = originalFile.name.split('.')
                            const newFileName = date+'_'+i+'.'+filePathArray[filePathArray.length - 1]
                            const file = new File([originalFile], newFileName, {
                                type: originalFile.type,
                                lastModified: originalFile.lastModified,
                            });
                            formData.append("files", file)
                        }

                        _.forEach(imageData, (value, key) => {
                            formData.append(key, value);
                        })

                        await ImageService.upload(formData)
                    }
                    await this.$nextTick(() => {
                        if (this.edit) {
                            this.scrollPosition = window.scrollY
                        }
                        this.$EventBus.$emit('refreshImages')
                        this.close()
                    })
                } catch ({response}) {
                    this.type = 'error'
                    this.message = response.data.message
                } finally {
                    this.loading = false
                }
            },
            close() {
                this.defaultImage();
                this.edit = false
                this.loading = false
                this.dialog = false
                this.fileInput = [],
                this.selectedFiles = []
            }
        },
        created() {
            this.$EventBus.$on('showAddImageModal', (data) => this.showModal(data))
        }
    }
</script>