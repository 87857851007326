import Vue from 'vue'
import Vuex from 'vuex'
import {modalStoreModule} from './modal.store'
import {usersStoreModule} from './users.store'
import {FeedingScheduleStoreModule} from './feedingschedules.store'
import {SpacesStoreModule} from './spaces.store'
import {GeneticsStoreModule} from "./genetics.store"
import {PlantsStoreModule} from "./plants.store"
import {ImagesStoreModule} from "./images.store"
import {AlbumsStoreModule} from "./albums.store"
import {ApplicationStoreModule} from "./application.store"
import {MembershipModule} from "./membership.store"

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        error: null
    },
    getters: {
        loading(_state, _getters, rootState) {
            return Object.keys(rootState).some((stateName) => {
                return rootState[stateName].loading
            })
        },
        error(_state) {
            return _state.error;
        }
    },
    mutations: {
        setError(state, value) {
            state.error = value;
        }
    },
    actions: {},
    modules: {
        users: {...usersStoreModule},
        modal: {...modalStoreModule},
        feedingschedules: {...FeedingScheduleStoreModule},
        spaces: {...SpacesStoreModule},
        genetics: {...GeneticsStoreModule},
        plants: {...PlantsStoreModule},
        images: {...ImagesStoreModule},
        albums: {...AlbumsStoreModule},
        application: {...ApplicationStoreModule},
        membership: {...MembershipModule}
    },
})
