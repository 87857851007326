import api from "./api";

export const AlbumsService = {
    get() {
        return api.get('/albums');
    },
    getByAssociation(data) {
        const { associatedId, type, onlyMe = false } = data
        return api.get(`/albums/association/${associatedId}/type/${type}/onlyMe/${onlyMe}`)
    },
    post(data) {
        return api.post('/albums', data)
    },
    put(id, data) {
        return api.put(`/albums/id/${id}`, data)
    },
    delete(id) {
        return api.delete(`/albums/id/${id}`)
    }
}
