import {SpacesService} from "@/services/spaces.service";
import {GeneticsService} from "@/services/genetics.service";
import {PlantsService} from "@/services/plants.service";

export default {
    computed: {
        images() {
            if (this.$store.getters['images/Images']) {
                return this.$store.getters['images/Images'];
            }
        }
    },
    methods: {
        editImage(image) {
            let data = {
                image: image,
                associatedId: this.associatedId,
                type: this.type
            }
            this.$EventBus.$emit('showAddImageModal', data)
        },
        showImageUpload() {
            this.$EventBus.$emit('showImageUpload')
        },
        async selectProfileImage(type, itemId, imageId = null) {
            let query = {
                profileImage: imageId ? imageId : null
            }

            let response
            let refresh
            switch (type) {
                case this.$getConst('typeKeys').spaces:
                    response = await SpacesService.put(itemId, query)
                    refresh = {
                        spaceId: itemId
                    }
                    break;
                case this.$getConst('typeKeys').genetics:
                    response = await GeneticsService.put(itemId, query)
                    refresh = {
                        geneticsId: itemId
                    }
                    break;
                case this.$getConst('typeKeys').plants:
                    response = await PlantsService.put(itemId, query)
                    refresh = {
                        plantId: itemId
                    }
                    break;
            }
            if (response.data) {
                this.$EventBus.$emit('refreshData', refresh)
            }
            this.$EventBus.$emit('hideProfileSelectModal')
        },
        deleteImage(image) {
            const data = {
                image: image,
                type: this.getTypeFromURL()
            }
            this.$EventBus.$emit('showImageConfirmDelete', data)
        },
        clearImages(type) {
            this.$store.commit('images/ClearItem', {type: type})
        },
        getImageLink(imageId) {
            let basePath = this.$route.path.split('/')[1]
            let id = this.$route.params.id

            let image = {}
            switch (basePath) {
                case this.$getConst('typeKeys').spaces:
                    image.name = 'SpacesViewImage'
                    break
                case this.$getConst('typeKeys').genetics:
                    image.name = 'GeneticsViewImage'
                    break
                case this.$getConst('typeKeys').plants:
                    image.name = 'PlantsViewImage'
                    break
                default:
                    image.name = 'ImagesImage'
            }
            image.params = { id: id, imageId: imageId }
            return image
        }
    }
}