<template>
    <v-select
        ref="select"
        outlined
        hide-details="auto"
        v-model="spaceId"
        :items="resultsSpaces"
        item-text="name"
        :label="label ? label : `Space${multiple ? 's' : ''}`"
        @change="$emit('updateSpacesId', multiple ? spaceId : [spaceId])"
        :menu-props="{
            offsetY: true,
            closeOnClick: true ,
            top: true,
            nudgeTop: 10
        }"
        :multiple="multiple"
        :small-chips="multiple"
        :loading="loading"
        :dense="dense"
        :rules="rules"
        return-object>
        <template v-slot:selection="data">
            <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="remove(data.item)">
                <profile-image
                    :size="20"
                    :item-id="data.item._id"
                    :type="$getConst('typeKeys').spaces"
                    :image="data.item.profileImage"
                    :item-color="data.item.color"
                    :left="true">
                </profile-image>
                {{ data.item.name }}
            </v-chip>
        </template>
        <template v-slot:prepend-item>
            <v-list-item class="pb-2">
                <v-row align="center">
                    <v-col>
                        <v-btn
                            small
                            rounded
                            color="success"
                            @click="showSpaceModal()">
                            Add Space
                        </v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            icon
                            @click="closeSelect">
                            <v-icon>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-list-item>
            <v-divider>
            </v-divider>
        </template>
        <template v-slot:item="data">
            <v-list-item-avatar :size="35">
                <profile-image
                    :item-id="data.item._id"
                    :type="$getConst('typeKeys').spaces"
                    :image="data.item.profileImage"
                    :item-color="data.item.color"
                    :size="35">
                </profile-image>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title v-html="data.item.name">
                </v-list-item-title>
                <v-list-item-subtitle>
                    Updated: {{ data.item.updatedAt | moment('MM/DD/YYYY') }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </v-select>
</template>

<script>
    import {SpacesService} from "@/services/spaces.service";
    import ProfileImage from "../Images/ProfileImage.vue";

    export default {
        name: 'SpacesSelect',
        components: {ProfileImage},
        data() {
            return {
                loading: true,
                spaceId: null,
                resultsSpaces: []
            }
        },
        props: {
            selected: {
                required: false,
                type: [String,Array,Object],
                default: null
            },
            multiple: {
                required: false,
                type: Boolean,
                default: false
            },
            dense: {
                required: false,
                type: Boolean,
                default: false
            },
            label: {
                required: false,
                type: String
            },
            rules: {
                required: false,
                type: Array,
                default: () => []
            }
        },
        methods: {
            remove(item) {
                if (Array.isArray(this.spaceId)) {
                    this.spaceId = this.spaceId.filter(i => i._id !== item._id)
                } else {
                    this.spaceId = null
                    this.$emit('updateSpacesId', null)
                }
            },
            async getSpaces(options) {
                this.resultsSpaces = []
                this.loading = true
                try {
                    const resultsSpaces = await SpacesService.get(null, true)
                    this.resultsSpaces = resultsSpaces.data.spaces

                    if (options && options.spaceId) {
                        this.spaceId.push(options.spaceId)
                        this.$emit('updateSpacesId', this.spaceId)
                    }

                    this.loading = false
                } catch (error) {
                    this.loading = false
                    this.type = 'error'
                    this.message = error
                }
            },
            closeSelect() {
                this.$refs.select.blur();
            },
        },
        created() {
            this.$EventBus.$on('refreshData', (options) => this.getSpaces(options))
            this.$EventBus.$on('removeSpaceSelect', (item) => this.remove(item))
        },
        mounted() {
            if (this.selected && this.multiple) {
                this.spaceId = Array.isArray(this.selected) ? this.selected : [this.selected]
            } else {
                this.spaceId = Array.isArray(this.selected) ? this.selected[0] : this.selected
            }
            this.getSpaces()
        }
    }
</script>