import { ErrorService } from "../services/error.service";
import {UsageService} from "../services/usage.service";

const itemsGetterTypes = {
    error: 'Error',
    records: 'Records',
    scrollPosition: 'ScrollPosition',
    isDashboard: 'IsDashboard'
}

const itemsMutationTypes = {
    setSuccess: 'SetSuccess',
    setError: 'SetError',
    setScrollPosition: 'SetScrollPosition',
    setDashboard: 'SetDashboard'
}

const itemsActionTypes = {
    get: 'Get'
}

const state = () => ({
    error: null,
    records: {
        levels: [],
    },
    scrollPosition: null,
    isDashboard: false
})

const getters = {
    [itemsGetterTypes.error]: (state) => state.error,
    [itemsGetterTypes.records]: (state) => state.records,
    [itemsGetterTypes.scrollPosition]: (state) => state.scrollPosition,
    [itemsGetterTypes.isDashboard]: (state) => state.isDashboard
}

const mutations = {
    [itemsMutationTypes.setSuccess](state, data) {
        state.records[data.type] = data.results
        state.loading = false
    },
    [itemsMutationTypes.setError](state, message) {
        state.error = message
        state.loading = false
    },
    [itemsMutationTypes.setScrollPosition](state, data) {
        state.scrollPosition = data
    },
    [itemsMutationTypes.setDashboard](state, data) {
        state.isDashboard = data
    }
}

const actions = {
    async [itemsActionTypes.get]({ state, commit }, data) {
        try {
            const response = await UsageService.getLevels()
            commit(itemsMutationTypes.setSuccess, {results: response.data.results, type: data.type})
        } catch ({error, response}) {
            commit(itemsMutationTypes.setError, ErrorService.parseError(error, response))
        }
    }
}

export const ApplicationStoreModule = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}