import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Overview.vue'

import {store} from '../store/index';
import {UsersService} from "../services/users.service";

const SpacesView = () =>
    import(
        /* webpackChunkName: "spaces-view" */ '../views/Spaces/SpaceView.vue'
        )
const GeneticsView = () =>
    import(
        /* webpackChunkName: "genetics-view" */ '../views/Genetics/GeneticsView.vue'
        )
const PlantsView = () =>
    import(
        /* webpackChunkName: "plants-view" */ '../views/Plants/PlantView.vue'
        )
const Notes = () =>
    import(
        /* webpackChunkName: "notes" */ '../views/Notes/Notes.vue'
        )
const Images = () =>
    import(
        /* webpackChunkName: "images" */ '../views/Images/Images.vue'
        )
const ViewNotesModal = () =>
    import(
        /* webpackChunkName: "note-view-modal" */ '../components/Notes/ViewNoteModal.vue'
        )
const ViewImageModal = () =>
    import(
        /* webpackChunkName: "image-view-modal" */ '../components/Images/ImageView.vue'
        )

const views = [
    {
        path: '/spaces/view/:id',
        name: 'SpacesView',
        chunkName: 'space-view',
        image: true,
        component: SpacesView
    },
    {
        path: '/genetics/view/:id',
        name: 'GeneticsView',
        chunkName: 'genetics-view',
        image: true,
        component: GeneticsView
    },
    {
        path: '/plants/view/:id',
        name: 'PlantsView',
        chunkName: 'plants-view',
        image: true,
        component: PlantsView
    },
    {
        path: '/app/notes',
        name: 'Notes',
        chunkName: 'notes',
        component: Notes
    },
    {
        path: '/app/images',
        name: 'Images',
        chunkName: 'images',
        image: true,
        component: Images
    }
]

Vue.use(VueRouter)

const newRoutes = views.map(view => ({
    path: view.path,
    name: view.name,
    component: view.component,
    children: getChildren(view),
}))

function getChildren(view){
    if(view.image){
        return [
            {
                path: 'note/:noteId',
                name: `${view.name}Note`,
                props: true,
                component: ViewNotesModal
            },
            {
                path: 'image/:imageId',
                name: `${view.name}Image`,
                props: true,
                component: ViewImageModal
            }
        ]
    } else {
        return [
            {
                path: 'note/:noteId',
                name: `${view.name}Note`,
                props: true,
                component: ViewNotesModal
            }
        ]
    }
}

const routes = [
    {
        path: '/',
        name: 'Overview',
        component: Home,
    },
    {
        path: '/about',
        name: 'About',
        meta: {
            title: 'About GrowLab AI'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/StaticPages/About.vue')
    },
    {
        path: '/about/referrals',
        name: 'AboutReferrals',
        meta: {
            title: 'About GrowLab AI\s Referral Program'
        },
        component: () => import(/* webpackChunkName: "about-referrals" */ '../views/StaticPages/Marketing/Referrals.vue')
    },
    {
        path: '/pricing',
        name: 'Pricing',
        component: () => import(/* webpackChunkName: "pricing" */ '../views/StaticPages/Pricing.vue')
    },
    {
        path: '/about-mona-ai',
        name: 'AboutMonaAI',
        meta: {
            title: 'About Mona AI'
        },
        component: () => import(/* webpackChunkName: "about-mona-ai" */ '../views/StaticPages/Marketing/MonaAI.vue')
    },
    {
        path: '/about-spaces',
        name: 'AboutSpaces',
        meta: {
            title: 'About Spaces'
        },
        component: () => import(/* webpackChunkName: "about-spaces" */ '../views/StaticPages/Marketing/Spaces.vue')
    },
    {
        path: '/about-plants',
        name: 'AboutPlants',
        meta: {
            title: 'About Plants'
        },
        component: () => import(/* webpackChunkName: "about-plants" */ '../views/StaticPages/Marketing/Plants.vue')
    },
    {
        path: '/about-genetics',
        name: 'AboutGenetics',
        meta: {
            title: 'About Genetics'
        },
        component: () => import(/* webpackChunkName: "about-plants" */ '../views/StaticPages/Marketing/Genetics.vue')
    },
    {
        path: '/register',
        name: 'Register',
        beforeEnter: checkLogin,
        meta: {
            title: 'Register'
        },
        component: () => import(/* webpackChunkName: "register" */ '../views/User/Register.vue')
    },
    {
        path: '/sign-in/:subscriptionId?',
        name: 'SignIn',
        beforeEnter: checkLogin,
        meta: {
            title: 'Sign In'
        },
        component: () => import(/* webpackChunkName: "sign-in" */ '../views/User/SignIn.vue')
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        meta: {
            title: 'Reset Password',
            description: 'Reset your password'
        },
        component: () => import(/* webpackChunkName: "reset-password" */ '../views/User/ResetPassword.vue')
    },
    {
        path: '/confirm-email',
        name: 'confirm-email',
        meta: {
            title: 'Confirm Email',
            description: 'Confirm your Email'
        },
        component: () => import(/* webpackChunkName: "confirm-email" */ '../views/User/VerifyEmail.vue'),
        props: (route) => ({
            email: route.query.email
        }),
    },
    {
        path: '/app/update-password',
        name: 'update-password',
        meta: {
            title: 'Update Password',
            description: 'Update your password'
        },
        component: () => import(/* webpackChunkName: "update-password" */ '../views/User/UpdatePassword.vue')
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        meta: {
            title: 'Forgot Password',
            description: 'Forgot your password'
        },
        component: () => import(/* webpackChunkName: "forgot-password" */ '../views/User/ForgotPassword.vue')
    },
    {
        path: '/reset-password-code',
        name: 'reset-password-code',
        meta: {
            title: 'Reset Password Code',
            description: 'Reset your password'
        },
        component: () => import(/* webpackChunkName: "reset-password-code" */ '../views/User/ResetPasswordCode.vue'),
        props: (route) => ({
            email: route.query.email
        }),
    },
    {
        path: '/app/dashboard',
        name: 'Dashboard',
        component: () =>
            import(
                /* webpackChunkName: "dashboard" */ '../views/Dashboard'
                ),
    },
    {
        path: '/app/mona-ai/:imageId?',
        name: 'MonaAI',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "mona-ai" */ '../views/AI/MonaAi.vue'
                ),
    },
    // {
    //     path: '/app/profile/:id',
    //     name: 'Profile',
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "profile" */ '../views/User/Profile'
    //             ),
    // },
    {
        path: '/app/account',
        name: 'General Settings',
        component: () =>
            import(
                /* webpackChunkName: "account" */ '../views/User/Account.vue'
                ),
    },
    {
        path: '/app/todo-lists',
        name: 'TodoLists',
        component: () =>
            import(
                /* webpackChunkName: "todo-list" */ '../views/Todo/TodoList.vue'
                ),
    },
    {
        path: '/app/feeding-schedules',
        name: 'FeedingSchedules',
        component: () =>
            import(
                /* webpackChunkName: "feeding-schedules" */ '../views/FeedingSchedule/FeedingSchedules'
                ),
    },
    {
        path: '/app/feeding-schedules/add',
        name: 'FeedingScheduleAdd',
        component: () =>
            import(
                /* webpackChunkName: "feeding-schedule-add" */ '../views/FeedingSchedule/FeedingScheduleAdd'
            ),
    },
    {
        path: '/app/feeding-schedules/edit/:id',
        name: 'FeedingScheduleEdit',
        component: () =>
            import(
                /* webpackChunkName: "feeding-schedule-add" */ '../views/FeedingSchedule/FeedingScheduleAdd'
                ),
    },
    {
        path: '/feeding-schedules/view/:id',
        name: 'FeedingScheduleView',
        component: () =>
            import(
                /* webpackChunkName: "feeding-schedule-view" */ '../views/FeedingSchedule/FeedingScheduleView'
                ),
    },
    {
        path: '/feeding-schedules/print/:id',
        name: 'FeedingSchedulePrint',
        component: () =>
            import(
                /* webpackChunkName: "feeding-schedule-print" */ '../views/FeedingSchedule/FeedingSchedulePrint'
                ),
    },
    {
        path: '/app/plants',
        name: 'DashboardPlants',
        component: () =>
            import(
                /* webpackChunkName: "dashboard-plants" */ '../views/Plants/Plants'
                ),
    },
    {
        path: '/app/spaces',
        name: 'DashboardSpaces',
        component: () =>
            import(
                /* webpackChunkName: "dashboard-spaces" */ '../views/Spaces/Spaces'
                ),
    },
    {
        path: '/app/genetics',
        name: 'DashboardGenetics',
        component: () =>
            import(
                /* webpackChunkName: "dashboard-genetics" */ '../views/Genetics/Genetics'
                ),
    },
    {
        path: '/app/referrals/dashboard',
        name: 'Referrals',
        component: () =>
            import(
                /* webpackChunkName: "referrals" */ '../views/Referrals/ReferralDashboard'
                ),
    },
    {
        path: '/app/referrals/history',
        name: 'ReferralHistory',
        component: () =>
            import(
                /* webpackChunkName: "referral-history" */ '../views/Referrals/ReferralHistory'
                ),
    },
    {
        path: '/app/referrals/payouts',
        name: 'ReferralPayouts',
        component: () =>
            import(
                /* webpackChunkName: "referral-payouts" */ '../views/Referrals/ReferralPayouts'
                ),
    },
    {
        path: '/community/:communityType/:pageSlug?/:page?',
        name: 'Community',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "community-genetics" */ '../views/Community.vue'
                ),
    },
    {
        path: '/mona-qa/:page?',
        name: 'MonaQa',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "mona-qa" */ '../views/AI/MonaQa.vue'
                ),
    },
    {
        path: '/mona-qa-view/:qaId',
        name: 'MonaQaView',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "mona-qa-view" */ '../views/AI/MonaQaView.vue'
                ),
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: () =>
            import(
                /* webpackChunkName: "checkout" */ '../views/Checkout/CheckoutTokens.vue'
                ),
    },
    {
        path: '/subscription/checkout/:subscriptionId',
        name: 'SubscriptionCheckout',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "subscription-checkout" */ '../views/Checkout/CheckoutSubscription.vue'
                ),
    },
    {
        path: '/admin/dashboard',
        name: 'Admin Dashboard',
        component: () =>
            import(
                /* webpackChunkName: "admin-dashboard" */ '../views/Admin/AdminDashboard'
                ),
    },
    {
        path: '/admin/subscriptions',
        name: 'Admin Subscriptions',
        component: () =>
            import(
                /* webpackChunkName: "admin-subscription" */ '../views/Admin/AdminSubscriptions'
                ),
    },
    {
        path: '/admin/ai/qa-categories',
        name: 'AdminAIQACategories',
        component: () =>
            import(
                /* webpackChunkName: "admin-ai-qa-categories" */ '../views/Admin/AdminQaCategories.vue'
                ),
    },
    {
        path: '/admin/ai/qa-list',
        name: 'AdminAIQAList',
        component: () =>
            import(
                /* webpackChunkName: "admin-ai-qa-list" */ '../views/Admin/AdminQaList.vue'
                ),
    },
    {
        path: '/admin/reported-content',
        name: 'Admin Reported Content',
        component: () =>
            import(
                /* webpackChunkName: "admin-reported-content" */ '../views/Admin/AdminReportedContent'
                ),
    },
    {
        path: '/admin/note-actions',
        name: 'Admin Note Actions',
        component: () =>
            import(
                /* webpackChunkName: "admin-note-actions" */ '../views/Admin/AdminNoteTopics.vue'
                ),
    },
    {
        path: '/admin/devices',
        name: 'Admin Devices',
        component: () =>
            import(
                /* webpackChunkName: "admin-devices" */ '../views/Admin/AdminDevice.vue'
                ),
    },
    {
        path: '/admin/device-types',
        name: 'Admin Device Types',
        component: () =>
            import(
                /* webpackChunkName: "admin-device-types" */ '../views/Admin/AdminDeviceTypes.vue'
                ),
    },
    {
        path: '/admin/payout-types',
        name: 'AdminPayoutTypes',
        component: () =>
            import(
                /* webpackChunkName: "admin-payout-types" */ '../views/Admin/AdminReferralPayoutTypes.vue'
                ),
    },
    {
        path: '/admin/payouts',
        name: 'AdminPayouts',
        component: () =>
            import(
                /* webpackChunkName: "admin-payouts" */ '../views/Admin/AdminReferralPayouts.vue'
                ),
    },
    {
        path: '/admin/beta-testers',
        name: 'Admin Alpha Testers',
        component: () =>
            import(
                /* webpackChunkName: "admin-beta-testers" */ '../views/Admin/AdminAlphaTesters.vue'
                ),
    },
    {
        path: '/admin/application-settings',
        name: 'AdminApplicationSettings',
        component: () =>
            import(
                /* webpackChunkName: "admin-application-settings" */ '../views/Admin/AdminApplicationSettings.vue'
                ),
    },
    {
        path: '/app/devices',
        name: 'Devices',
        beforeEnter: checkAlphaRequired,
        component: () =>
            import(
                /* webpackChunkName: "devices" */ '../views/Automation/Devices/Devices.vue'
                ),
    },
    {
        path: '/app/devices/add',
        name: 'Add Devices',
        beforeEnter: checkAlphaRequired,
        component: () =>
            import(
                /* webpackChunkName: "devices" */ '../views/Automation/Devices/DeviceAdd.vue'
                ),
    },
    {
        path: '/documentation',
        name: 'Documentation',
        component: () =>
            import(
                /* webpackChunkName: "documentation" */ '../views/Documentation/Documentation.vue'
                ),
    },
    {
        path: '/privacy-policy',
        name: 'Privacy Policy',
        component: () =>
            import(
                /* webpackChunkName: "privacy-policy" */ '../views/StaticPages/PrivacyPolicy.vue'
                ),
    },
    {
        path: '/cancellation-policy',
        name: 'CancellationPolicy',
        component: () =>
            import(
                /* webpackChunkName: "cancellation-policy" */ '../views/StaticPages/CancellationPolicy.vue'
                ),
    },
    {
        path: '/ai-policy',
        name: 'AI Policy',
        component: () =>
            import(
                /* webpackChunkName: "ai-policy" */ '../views/StaticPages/AIPolicy.vue'
                ),
    },
    {
        path: '/terms-of-use',
        name: 'Terms of Use',
        component: () =>
            import(
                /* webpackChunkName: "terms-of-use" */ '../views/StaticPages/TermsOfUse.vue'
                ),
    },
    {
        path: '/app/beta-required',
        name: 'AlphaRequired',
        component: () =>
            import(
                /* webpackChunkName: "beta-required" */ '../views/StaticPages/BetaRequired.vue'
                ),
    },
    // {
    //     path: '/404',
    //     name: '404NotFound',
    //     component: () =>
    //     import(
    //         /* webpackChunkName: "not-found" */ '../views/NotFound.vue'
    //         )
    // }
]

Array.prototype.push.apply(routes, newRoutes);

const notFoundRoute = {
    path: '*',
    name: 'NotFound',
    component: () =>
        import(
            /* webpackChunkName: "not-found" */ '../views/NotFound.vue'
            )
};
routes.push(notFoundRoute);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router

function checkLogin(to, from, next) {
    if (store.getters['users/User']) {
        return router.push({name: 'Dashboard'})
    } else {
        next()
    }
}

function checkAlphaRequired(to, from, next) {
    if (store.getters['users/User'].alphaTester) {
        next()
    } else {
        return router.push({name: 'AlphaRequired'})
    }
}