<template>
    <v-btn
        color="primary"
        :x-large="xLarge"
        :large="large"
        rounded
        to="/app/mona-ai">
        <v-icon left>
            mdi-creation
        </v-icon>
        Ask Mona
    </v-btn>
</template>

<script>
    export default {
        name: 'AskMonaButton',
        props: {
            xLarge: {
                required: false,
                type: Boolean
            },
            large: {
                required: false,
                type: Boolean
            }
        }
    }
</script>