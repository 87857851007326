<template>
    <p
        v-if="createdAt"
        class="mb-0 caption">
        <template v-if="min">
            <span class="font-weight-bold">
                {{createdAt | moment('MM/DD/YYYY')}}
            </span>
        </template>
        <template v-else>
            {{createdAt | moment('MM/DD/YYYY h:mm:ss A')}}
        </template>
    </p>
</template>

<script>
    export default {
        name: 'CreatedOnText',
        props: {
            createdAt: {
                required: false,
                type: String
            },
            min: {
                required: false,
                type: Boolean,
                default: false
            }
        }
    }
</script>