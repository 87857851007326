const Constants = {
    discordLink: 'https://discord.gg/D8GNNpy2dT',
    iftttLink: 'https://ifttt.com/',
    spaceStatuses: [
        {
            value: 0,
            name: 'Inactive',
            color: 'red'
        },
        {
            value: 1,
            name: 'Germination',
            color: 'light-blue'
        },
        {
            value: 2,
            name: 'Vegetation',
            color: 'blue'
        },
        {
            value: 3,
            name: 'Flowering',
            color: 'purple'
        },
        {
            value: 4,
            name: 'Drying',
            color: 'green'
        }
    ],
    growthStatus: [
        {
            value: 0,
            name: 'Seedling'
        },
        {
            value: 1,
            name: 'Clone'
        },
        {
            value: 2,
            name: 'Teenager'
        },
        {
            value: 3,
            name: 'Adult'
        },
        {
            value: 4,
            name: 'Harvested'
        },
        {
            value: 5,
            name: 'Archived'
        }
    ],
    defaultColor: '#48b84d',
    spaceKey: 'spaces',
    geneticsKey: 'genetics',
    typeKeys: {
        spaces: 'spaces',
        genetics: 'genetics',
        plants: 'plants',
        albums: 'albums',
        images: 'images',
        notes: 'notes',
        users: 'users',
        feedingSchedules: 'feedingSchedules'
    },
    modalClearTimeout: 1000,
    sortOptions: [
        {
            key: 'recently_updated',
            value: 'Recently Updated'
        },
        {
            key: 'recently_added',
            value: 'Recently Added'
        }
    ],
    qaStatus: [
        {
            key: 1,
            title: 'Accepted'
        },
        {
            key: 2,
            title: 'Denied'
        },
        {
            key: 3,
            title: 'Pending'
        }
    ],
    defaultMetaTitle: 'GrowLab AI'
}

Constants.install = function (Vue) {
    Vue.prototype.$getConst = (key) => {
        return Constants[key];
    }
};

export default Constants;