<template>
    <div>
        <v-row>
            <v-col>
                <v-card
                    outlined>
                    <v-card-text
                        ref="scrollText"
                        style="max-height: 400px; overflow-y: scroll;">
                        <div
                            v-for="(item, key) in aiData"
                            :key="key">
                            <v-row
                                class="my-0"
                                v-if="item.question"
                                justify="end">
                                <v-col cols="auto">
                                    <v-chip
                                        class="py-3 ml-5 black--text"
                                        style="height: auto; white-space: normal;"
                                        label
                                        color="blue lighten-4"
                                        v-html="item.question">
                                    </v-chip>
                                </v-col>
                            </v-row>
                            <v-row
                                class="my-0"
                                v-if="item.response">
                                <v-col>
                                    <v-chip
                                        class="py-3 mr-5 black--text"
                                        style="height: auto; white-space: normal;"
                                        label
                                        v-html="item.response"
                                        color="lightGreen">
                                    </v-chip>
                                </v-col>
                            </v-row>
                            <!--                        <code-block-->
                            <!--                            :code="item.response"-->
                            <!--                            language="javascript"-->
                            <!--                            :line-numbers="true">-->
                            <!--                        </code-block>-->
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row
            align="center">
            <v-col>
                <v-text-field
                    outlined
                    hide-details
                    @keydown="handleKeyPress"
                    v-model="search"
                    clearable>
                </v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="auto">
                <v-btn
                    rounded
                    :block="isMobilePort"
                    color="success"
                    @click="getAi"
                    :disabled="loading"
                    :loading="loading">
                    Ask
                </v-btn>
            </v-col>
        </v-row>
        <v-dialog
            max-width="500px"
            v-model="dialog">
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <p class="mt-5 text-center mb-0">
                                We only allow up to 5 questions for non signed in user.  <br/>Please login to ask Mona more questions.
                            </p>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-row justify="center">
                        <v-col cols="auto">
                            <v-btn
                                class="mx-1"
                                rounded
                                color="primary"
                                to="/pricing">
                                Register
                            </v-btn>
                            <v-btn
                                class="mx-1"
                                rounded
                                color="success"
                                to="sign-in">
                                Sign In
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Prism from 'prismjs';
    import 'prismjs/themes/prism-okaidia.css';
    import 'prismjs/components/prism-python.min.js';
    import 'prismjs/plugins/line-numbers/prism-line-numbers.min.js';

    import { AiService } from '../../services/ai.service';

    import CodeBlock from "../../components/Application/CodeBlock";

    export default {
        data() {
            return {
                dialog: false,
                search: '',
                aiData: [
                    {
                        response: `Hi, I'm Mona how can I help you?\n\n`
                    }
                ],
                loading: false,
                usageCount: 0,
                limit: 5
            }
        },
        components: {
            CodeBlock
        },
        computed: {
            formattedResults() {
                if (this.aiData.response) {}
                return Prism.highlight(this.aiData.response, Prism.languages.python, 'python');
            },
        },
        methods: {
            async getAi() {
                if (!this.user &&
                    (this.usageCount > this.limit ||
                    this.usageCount === this.limit)) {
                    this.dialog = true;
                    return;
                }
                this.loading = true;
                let setData = {
                    question: this.search
                }
                this.$nextTick(() => {
                    const outputEl = this.$refs.scrollText;
                    outputEl.scrollTop = outputEl.scrollHeight;
                });
                const data = {
                    search: this.search,
                };
                this.search = '';
                try {
                    const response = await AiService.post(data);
                    setData.response = response.data;
                    this.aiData.push(setData)
                    this.$nextTick(() => {
                        if (response) {
                            const outputEl = this.$refs.scrollText;
                            outputEl.scrollTop = outputEl.scrollHeight;
                            this.usageCount = this.usageCount+1
                            this.$session.set('aiUseCount', this.usageCount)
                        }
                        this.loading = false
                    });
                } catch (error) {
                    this.aiData.push({response: error})
                    this.loading = false;
                }

            },
            handleKeyPress(e) {
                if (e.key === 'Enter' && !(e.key === 'Enter' && e.shiftKey)) {
                    this.getAi()
                }
            }
        },
        mounted() {
            if (this.$session.has('aiUseCount')) {
                this.usageCount = this.$session.get('aiUseCount')
            }
        }
    }
</script>