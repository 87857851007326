export default {
    data() {
        return {
            whatCanMonaDo: [
                {
                    icon: 'mdi-flower-outline',
                    title: 'Identify Plants',
                    description: 'Discover any plant species through image recognition.'
                },
                {
                    icon: 'mdi-bug-check-outline',
                    title: 'Diagnose Plant Problems',
                    description: 'Detect pests, diseases, or other issues affecting your plants.'
                },
                {
                    icon: 'mdi-watering-can-outline',
                    title: 'Provide Care Recommendations',
                    description: 'Receive customized watering schedules, pruning advice, and fertilizer suggestions.'
                },
                {
                    icon: 'mdi-help-circle-outline',
                    title: 'Answer Questions About Plants',
                    description: 'Get answers to your questions about plants and horticulture.'
                },
                {
                    icon: 'mdi-trending-up',
                    title: 'Stay Up-To-Date with Plant Trends',
                    description: 'Remain informed on the latest discoveries and trends in plant care and growing.'
                },
                {
                    icon: 'mdi-cellphone-wireless',
                    title: 'Accessible from Anywhere',
                    description: 'Access Mona AI from anywhere and at any time —it\'s there when you need it.'
                }
            ],
        }
    }
}