import api from "./api";

export const TodoService = {
    getTodoLists() {
        return api.get('/todo');
    },
    getTodoListsByAssociation(data) {
        const { associatedId, type } = data
        return api.get(`/todo/${associatedId}/${type}`);
    },
    postTodoList(data) {
        return api.post('/todo', data)
    },
    deleteTodoList(id) {
        return api.delete(`/todo/id/${id}`)
    },
    updateTodoList(id, data) {
        return api.put(`/todo/id/${id}`, data)
    },
    postTodoListItem(listId, data) {
        return api.post(`/todo/item/list-id/${listId}`, data)
    },
    deleteTodoListItem(itemId, id) {
        return api.delete(`/todo/item/${itemId}/id/${id}`)
    },
    updateTodoListItem(id, data) {
        return api.put(`/todo/item/id/${id}`, data)
    },
}