import {PlantsService} from "../services/plants.service";
import {ErrorService} from "../services/error.service";

const plantsGetterTypes = {
    error: 'GetError',
    plants: 'Plants',
    singlePlant: 'SinglePlant',
    loading: 'Loading'
}

const plantsMutationTypes = {
    setLoading: 'SetLoading',
    setSuccess: 'SetSuccess',
    setSingle: 'SetSingle',
    setError: 'SetError',
}

const plantsActionTypes = {
    get: 'GetAll',
    getSingle: 'GetSingle',
    getAssociated: 'GetAssociated',
    add: 'Add',
    update: 'Update',
    delete: 'Delete',
    clearSingle: 'ClearSingle',
    clearAll: 'ClearAll'
}

const state = () => ({
    error: null,
    records: undefined,
    singleRecord: null,
    loading: false,
})

const getters = {
    [plantsGetterTypes.error]: (state) => state.error,
    [plantsGetterTypes.plants]: (state) => state.records,
    [plantsGetterTypes.singlePlant]: (state) => state.singleRecord,
    [plantsGetterTypes.loading]: (state) => state.loading
}

const mutations = {
    [plantsMutationTypes.setLoading](state) {
        state.loading = true
    },

    [plantsMutationTypes.setSuccess](state, data) {
        state.records = data
        state.loading = false
    },

    [plantsMutationTypes.setSingle](state, data) {
        state.singleRecord = data
        state.loading = false
    },

    [plantsMutationTypes.setError](state, message) {
        state.error = message
        state.loading = false
    },
}

const actions = {
    async [plantsActionTypes.get]({ commit }, onlyMe) {
        commit(plantsMutationTypes.setLoading)
        try {
            const response = await PlantsService.get()
            commit(plantsMutationTypes.setSuccess, response.data.results)
        } catch ({error, response}) {
            commit(plantsMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [plantsActionTypes.getAssociated]({ state, commit }, data) {
        commit(plantsMutationTypes.setLoading)

        try {
            const response = await PlantsService.getAssociated(data.associatedId, data.type)
            commit(plantsMutationTypes.setSuccess, response.data.data)
        } catch ({error, response}) {
            commit(plantsMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [plantsActionTypes.getSingle]({ state, commit }, id) {
        commit(plantsMutationTypes.setLoading)
        try {
            const response = await PlantsService.getById(id)
            commit(plantsMutationTypes.setSingle, response.data.plant)
        } catch ({error, response}) {
            commit(plantsMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [plantsActionTypes.add]({ dispatch, commit }, data) {
        try {
            const plant = await PlantsService.post(data)
            return plant.data.plants._id
        } catch ({error, response}) {
            if (response.data.allowanceLimit) {
                window.vm.$EventBus.$emit('showUpdateSubscriptionModal', response.data.message)
            } else {
                commit(plantsMutationTypes.setError, ErrorService.parseError(error, response))
            }
        }
    },

    async [plantsActionTypes.update]({ dispatch, commit }, payload) {
        try {
            await PlantsService.put(payload.id, payload)
            dispatch(plantsActionTypes.get, true)
            dispatch(plantsActionTypes.getSingle, payload.id)
        } catch ({error, response}) {
            commit(plantsMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [plantsActionTypes.delete]({ dispatch, commit }, id) {
        try {
            await PlantsService.delete(id)
            await window.vm.$store.dispatch('images/GetUsage')
            await dispatch(plantsActionTypes.get, true)
        } catch ({error, response}) {
            commit(plantsMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [plantsActionTypes.clearSingle]({ state, commit }) {
        try {
            commit(plantsMutationTypes.setSingle, undefined)
        } catch (error) {
            commit(plantsMutationTypes.setError, ErrorService.parseError(error))
        }
    },

    async [plantsActionTypes.clearAll]({ state, commit }) {
        try {
            commit(plantsMutationTypes.setSuccess, undefined)
        } catch (error) {
            commit(plantsMutationTypes.setError, ErrorService.parseError(error))
        }
    },
}

export const PlantsStoreModule = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}