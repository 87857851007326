<template>
    <div style="position: relative;">
        <v-btn
            v-if="showEdit && idHash && canEdit(idHash)"
            fab
            style="position: absolute; z-index: 1; right: 10px;"
            color="primary"
            small
            @click="openProfileSelectModal">
            <v-icon>
                mdi-pencil
            </v-icon>
        </v-btn>
        <v-avatar
            :left="left"
            :color="getItemColor()"
            :size="size">
            <v-progress-circular
                color="white"
                indeterminate
                v-if="loading">
            </v-progress-circular>
            <template v-else>
                <single-image
                    v-if="image && image.fileName"
                    :size="thumbSize"
                    :image="image">
                </single-image>
                <v-icon
                    v-else
                    :size="size"
                    color="white"
                    class="lighten-2">
                    {{getIcon()}}
                </v-icon>
            </template>
        </v-avatar>
    </div>
</template>

<script>
    import SingleImage from "../Images/SingleImage.vue";

    export default {
        props: {
            item: {
                required: false,
                type: Object
            },
            showEdit: {
                required: false,
                type: Boolean,
                default: false
            },
            size: {
                required: false,
                type: Number,
                default: 50
            },
            xLargeIcon: {
                required: false,
                type: Boolean
            },
            image: {
                required: false,
                type: Object,
                default: null
            },
            itemId: {
                required: true,
                type: String
            },
            type: {
                required: true,
                type: String
            },
            itemColor: {
                required: false,
                type: String
            },
            left: {
                required: false,
                type: Boolean,
                default: false
            },
            thumbSize: {
                required: false,
                type: Number,
                default: 120
            },
            idHash: {
                required: false,
                type: String
            },
            loading: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        components: {
            SingleImage
        },
        methods: {
            getItemColor() {
                if (this.itemColor) {
                    return this.itemColor;
                }
                return this.$getConst('defaultColor');
            },
            openProfileSelectModal() {
                let data = {
                    hasProfile: !!this.profileImage,
                    item: this.item,
                    type: this.type
                }
                this.$EventBus.$emit('showProfileSelectModal', data);
            },
            getIcon() {
                let icon;
                switch (this.type) {
                    case this.$getConst('typeKeys').spaces:
                        icon = 'mdi-grid-large'
                        break;
                    case this.$getConst('typeKeys').genetics:
                        icon = 'mdi-dna'
                        break;
                    case this.$getConst('typeKeys').plants:
                        icon = 'mdi-sprout-outline'
                        break;
                    case this.$getConst('typeKeys').users:
                        icon = 'mdi-account'
                        break;
                }
                return icon;
            }
        }
    }
</script>