<template>
    <v-alert
        border="left"
        v-if="message"
        :type="notificationType"
        elevation="0"
        style="z-index: 201">
        <p
            class="mb-0 caption"
            v-html="`${message}`">
        </p>
    </v-alert>
</template>

<script>
    export default {
        props: {
            notificationType: {
                type: String,
                required: false,
                value: 'success'
            },
            message: {
                type: String|Error,
                required: false,
                value: ''
            }
        }
    }
</script>