export default {
    computed: {
        plants() {
            if (this.$store.getters['plants/Plants']) {
                return this.$store.getters['plants/Plants'];
            }
            return [];
        },
        singlePlant: {
            get() {
                return this.$store.getters['plants/SinglePlant'];
            },
            set(newValue) {
                return this.$store.dispatch("plants/setSingle", newValue);
            }
        },
        plantImages() {
            return this.$store.getters.getPlantImages;
        }
    },
    methods: {
        getGrowthStatus(id) {
            const status = this.$_.find(this.$getConst('growthStatus'), ['value', id])
            if (status) {
                return status.name
            } else {
                return 'Not Set'
            }
        }
    }
}