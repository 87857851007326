import { MembershipService } from "../services/membership.service";
import { ErrorService } from "../services/error.service";

const itemsGetterTypes = {
    error: 'Error',
    records: 'Records',
    loading: 'Loading'
}

const itemsMutationTypes = {
    setLoading: 'SetLoading',
    setSuccess: 'SetSuccess',
    setError: 'SetError',
    clearItem: 'ClearItem'
}

const itemsActionTypes = {
    getInvoices: 'GetInvoices',
    add: 'Add',
}

const state = () => ({
    error: null,
    records: {
        invoices: []
    },
    loading: false,
})

const getters = {
    [itemsGetterTypes.error]: (state) => state.error,
    [itemsGetterTypes.records]: (state) => state.records,
    [itemsGetterTypes.loading]: (state) => state.loading,
}

const mutations = {
    [itemsMutationTypes.setLoading](state) {
        state.loading = true
    },

    [itemsMutationTypes.setSuccess](state, data) {
        state.records[data.type] = data.results
        state.loading = false
    },

    [itemsMutationTypes.setError](state, message) {
        state.error = message
        state.loading = false
    },

    [itemsMutationTypes.clearItem](state, data) {
        if (data.id) {
            const records = state.records[data.type]
            const index = _.findIndex(records, {_id: data.id})
            records.splice(index, 1);
        } else {
            state.records[data.type] = []
        }
    },
}

const actions = {
    async [itemsActionTypes.getInvoices]({ commit }) {
        commit(itemsMutationTypes.setLoading)

        try {
            const response = await MembershipService.getInvoices()
            commit(itemsMutationTypes.setSuccess, {results: response.data.results, type: 'invoices'})
        } catch ({error, response}) {
            commit(itemsMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    // async [itemsActionTypes.add]({ dispatch, commit }, data) {
    //     try {
    //         await AlbumsService.post(data.item)
    //
    //         dispatch(itemsActionTypes.get, data)
    //     } catch ({error, response}) {
    //         commit(itemsMutationTypes.setError, ErrorService.parseError(error, response))
    //     }
    // },
    //
    // async [itemsActionTypes.update]({ dispatch, commit }, data) {
    //     try {
    //         const response = await AlbumsService.put(data.item._id, data.item)
    //         commit(itemsMutationTypes.updateItem, {results: response.data.results, type: data.type})
    //     } catch ({error, response}) {
    //         commit(itemsMutationTypes.setError, ErrorService.parseError(error, response))
    //     }
    // },
}

export const MembershipModule = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}