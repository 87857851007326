export default {
    data() {
        return {
            mainNavigation: [
                {
                    title: 'About',
                    subLinks: [
                        {
                            title: 'About Us',
                            to: '/about',
                            icon: 'mdi-information-outline'
                        },
                        {
                            title: 'Refer a Friend',
                            to: '/about/referrals',
                            icon: 'mdi-account-multiple-outline'
                        }
                    ]
                },
                {
                    title: 'Pricing',
                    to: '/pricing'
                },
                {
                    title: 'Features',
                    subLinks: [
                        {
                            title: 'Mona AI',
                            to: '/about-mona-ai',
                            icon: 'mdi-creation-outline'
                        },
                        // {
                        //     title: 'Devices & Automation',
                        //     to: '/about-devices-automation',
                        //     icon: 'mdi-cloud-sync-outline'
                        // },
                        {
                            title: 'Spaces',
                            to: '/about-spaces',
                            icon: 'mdi-grid-large'
                        },
                        {
                            title: 'Genetics',
                            to: '/about-genetics',
                            icon: 'mdi-dna'
                        },
                        {
                            title: 'Plants',
                            to: '/about-plants',
                            icon: 'mdi-sprout-outline'
                        },
                        // {
                        //     title: 'Feeding Schedules',
                        //     to: '/about-feeding-schedules',
                        //     icon: 'mdi-calendar-clock'
                        // },
                        // {
                        //     title: 'Notes & Images',
                        //     to: '/about-notes-images',
                        //     icon: 'mdi-note-multiple-outline'
                        // }
                    ]
                },
                // {
                //     title: 'Community Grows',
                //     subLinks: [
                //         {
                //             title: 'Spaces',
                //             to: `/community/${this.$getConst('typeKeys').spaces}`,
                //             icon: 'mdi-grid-large'
                //         },
                //         {
                //             title: 'Genetics',
                //             to: `/community/${this.$getConst('typeKeys').genetics}`,
                //             icon: 'mdi-dna'
                //         },
                //         {
                //             title: 'Plants',
                //             to: `/community/${this.$getConst('typeKeys').plants}`,
                //             icon: 'mdi-sprout-outline'
                //         },
                //         {
                //             title: 'Feeding Schedules',
                //             to: `/community/${this.$getConst('typeKeys').feedingSchedules.toLowerCase()}`,
                //             icon: 'mdi-calendar-clock'
                //         },
                //     ]
                // },
                {
                    title: 'Mona Q&A',
                    to: '/mona-qa'
                },
                // {
                //     title: 'Pricing',
                //     to: '/pricing'
                // }
            ]
        }
    },
    computed: {
        levels() {
            if (this.$store.getters['application/Records']) {
                return _.orderBy(this.$store.getters['application/Records'].levels, ['subscriptionId'], ['asc']);
            }
        },
        scrollPosition: {
            get() {
                return this.$store.getters['application/ScrollPosition'];
            },
            set(value) {
                return this.$store.commit("application/SetScrollPosition", value);
            }
        },
        isDashboard: {
            get() {
                return this.$store.getters['application/IsDashboard'];
            },
            set(value) {
                return this.$store.commit("application/SetDashboard", value);
            }
        },
        isDashboardLink() {
            const dashboardRoutes = ['/app', '/spaces', '/plants', '/genetics', '/feeding-schedules']
            const currentPath = this.$router.currentRoute.path
            return this.user && dashboardRoutes.some(route => currentPath.startsWith(route))
        }
    },
    methods: {
        showReportContentModal(data) {
            this.$EventBus.$emit('showReportContentModal', data)
        }
    }
}