<template>
    <v-dialog
        persistent
        max-width="1020"
        v-model="dialog"
        :fullscreen="isMobilePort"
        style="z-index: 210">
        <v-card>
            <v-card-title>
                <v-row align="center">
                    <v-col>
                        {{edit ? 'Edit' : 'Add'}} Plant
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            icon
                            small
                            @click="close()">
                            <v-icon>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <card-loader
                v-if="modalLoading"
                class="mx-3"
                flat>
            </card-loader>
            <v-card-text
                v-else
                class="pt-2">
                <notification-modular
                    v-if="message && type"
                    :message="message"
                    :notification-type="type">
                </notification-modular>
                <active-chips
                    class="mb-2"
                    :items="activeGardenAssociation">
                </active-chips>
                <v-form
                    ref="addPlantForm"
                    class="mt-1">
                    <v-row>
                        <v-col
                            cols="12"
                            sm="6">
                            <v-text-field
                                :rules="nameRules"
                                outlined
                                hide-details="auto"
                                v-model="plant.name"
                                label="Name">
                                <template v-slot:append-outer>
                                    <v-icon
                                        @click="refreshName()">
                                        mdi-refresh
                                    </v-icon>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <p class="caption mb-1">
                                Description:
                            </p>
                            <w-y-s-i-w-y-g-editor
                                v-if="dialog"
                                :default-description="plant.description"
                                @updateDescription="(data) => plant.description = data">
                            </w-y-s-i-w-y-g-editor>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card outlined>
                                <v-card-title
                                    @click="details = !details"
                                    style="cursor: pointer;">
                                    <h5>
                                        Additional Details
                                    </h5>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-icon>
                                        {{details ? 'mdi-chevron-up' : 'mdi-chevron-down'}}
                                    </v-icon>
                                </v-card-title>
                                <v-expand-transition>
                                    <div v-show="details">
                                        <v-card-text>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    sm="5">
                                                    <spaces-select
                                                        :selected="plant.spaceId"
                                                        @updateSpacesId="(data) => plant.spaceId = data">
                                                    </spaces-select>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="5">
                                                    <genetics-select
                                                        :selected="plant.geneticsId"
                                                        @updateGeneticsId="(data) => plant.geneticsId = data">
                                                    </genetics-select>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    sm="4">
                                                    <color-picker
                                                        :default-color="plant.color"
                                                        @updateColor="(color) => plant.color = color">
                                                    </color-picker>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="4">
                                                    <v-select
                                                        outlined
                                                        hide-details="auto"
                                                        clearable
                                                        v-model="plant.growth"
                                                        :items="$getConst('growthStatus')"
                                                        item-text="name"
                                                        item-value="value"
                                                        label="Growth Status"
                                                        :menu-props="{ offsetY: true }">
                                                    </v-select>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </div>
                                </v-expand-transition>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer>
                </v-spacer>
                <v-btn
                    text
                    rounded
                    @click="close()">
                    Cancel
                </v-btn>
                <v-btn
                    rounded
                    color="success"
                    @click="addEditPlant()"
                    :disabled="loading"
                    :loading="loading">
                    {{ edit ? 'Save' : 'Add' }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import RandomNames from "../../mixins/RandomNames";

    import ProfileImage from "../Images/ProfileImage";
    import ColorPicker from "../Forms/ColorPicker";
    import WYSIWYGEditor from "../Forms/WYSIWYGEditor";
    import AddEditPanel from "../TabPanels/AddEditPanel.vue";
    import GeneticsSelect from "../Genetics/GeneticsSelect.vue";
    import SpacesSelect from "../Spaces/SpacesSelect.vue";
    import ActiveChips from "@/components/Application/ActiveChips.vue";
    import {SpacesService} from "@/services/spaces.service";
    import {GeneticsService} from "@/services/genetics.service";
    import {PlantsService} from "@/services/plants.service";
    import CardLoader from "@/components/Application/CardLoader.vue";
    import NotificationModular from "@/components/Application/NotificationModular.vue";

    let loop = [
        'spaceId',
        'geneticsId',
    ]

    export default {
        data() {
            return {
                dialog: false,
                loading: false,
                edit: false,
                plant: {
                    name: '',
                    spaceId: [],
                    geneticsId: [],
                    growth: 3,
                    description: '',
                    color: this.$getConst('defaultColor')
                },
                redirectAfter: false,
                details: false,
                modalLoading: false,
                type: null,
                message: null,
            }
        },
        mixins: [
            RandomNames
        ],
        computed: {
            activeGardenAssociation() {
                return {
                    [this.$getConst('typeKeys').spaces]: this.plant.spaceId,
                    [this.$getConst('typeKeys').genetics]: this.plant.geneticsId,
                }
            }
        },
        components: {
            NotificationModular,
            CardLoader,
            ActiveChips,
            SpacesSelect,
            GeneticsSelect,
            AddEditPanel,
            ColorPicker,
            ProfileImage,
            WYSIWYGEditor
        },
        methods: {
            getSelected(ids) {
                if (typeof ids === 'string' || ids instanceof String) {
                    return ids
                }
                return  ids ? ids.map(obj => obj._id) : ids
            },
            async initPlantModal(data = {}) {
                this.type = null
                this.message = null
                this.modalLoading = true
                this.dialog = true
                if (data && data._id) {
                    try {
                        const response = await PlantsService.getById(data._id)
                        let plant = response.data.plant
                        loop.forEach(obj => {
                            if (plant[obj]) {
                                plant[obj] = [plant[obj]]
                            }
                        })
                        this.plant = plant
                    } catch ({response}) {
                        this.type = 'error'
                        this.message = response.data.message
                        this.modalLoading = false
                        return false
                    }
                    this.edit = true
                } else {
                    this.defaultPlant()
                    if (data && data.associatedId && data.type) {
                        let response
                        switch (data.type) {
                            case this.$getConst('typeKeys').spaces:
                                response = await SpacesService.get(data.associatedId)
                                this.plant.spaceId = [response.data.space]
                                break;
                            case this.$getConst('typeKeys').genetics:
                                response = await GeneticsService.get(data.associatedId)
                                this.plant.geneticsId = [response.data.results]
                                break;
                        }
                    }
                    this.refreshName()
                    if (data.redirectAfter) {
                        this.redirectAfter = true
                    }
                }
                this.modalLoading = false
            },
            defaultPlant() {
                this.$set(this, 'plant', {
                    name: '',
                    spaceId: [],
                    geneticsId: [],
                    growth: 3,
                    description: '',
                    color: this.$getConst('defaultColor')
                })
            },
            refreshName() {
                this.plant.name = this.$_.sample(this.randomNames);
            },
            async addEditPlant() {
                if (this.plant.name === '') {
                    this.$refs.addPlantForm.validate()
                    return false;
                }
                this.type = null
                this.message = null
                this.loading = true
                let data = Object.assign({}, this.plant)
                loop.forEach(obj => {
                    if (data[obj] && data[obj].length) {
                        data[obj] = this.getSelected(data[obj])
                    } else {
                        data[obj] = null
                    }
                })
                let responseData

                try {
                    if (this.edit) {
                        responseData = await PlantsService.put(data._id, data)
                    } else {
                        responseData = await PlantsService.post(data)
                    }
                    const id = responseData.data.results._id
                    if (id && this.redirectAfter) {
                        this.$router.push({name: 'PlantsView', params: {id: id}})
                    }
                    this.$EventBus.$emit('refreshData', {plantId: id})
                    this.close()
                } catch ({response}) {
                    this.type = 'error'
                    this.message = response.data.message
                    this.loading = false
                }
            },
            close() {
                this.dialog = false
                this.redirectAfter = false
                const _this = this
                setTimeout(function() {
                    _this.defaultPlant()
                    _this.edit = false
                    _this.loading = false
                }, this.$getConst('modalClearTimeout'))
            }
        },
        created() {
            this.$EventBus.$on('showPlantModal', (data) => this.initPlantModal(data))
        }
    }
</script>