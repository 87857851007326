import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import vuetify from './plugins/vuetify'
import {store} from './store'
import {createSimpleTransition} from 'vuetify/lib/components/transitions/createTransition'
import VueSocialSharing from 'vue-social-sharing'
import VueGtag from "vue-gtag";
import VueSession from 'vue-session';
import Hotjar from 'vue-hotjar'
import '@fortawesome/fontawesome-free/css/all.css'
let VueScrollTo = require('vue-scrollto');
import {ReferralService} from "@/services/referral.service";

import Constants from './constants/index'

Vue.use(Constants);
Vue.use(require('vue-moment'));
Vue.use(VueSocialSharing);
Vue.use(VueSession, {persist: true});
Vue.use(VueScrollTo)
Vue.use(require('vue-cookies'))

if (process.env.VUE_APP_ENABLE_HOTJAR === '1') {
    Vue.use (Hotjar, {
        id: '3496063'
    })
}

Vue.use(TiptapVuetifyPlugin, {
    vuetify,
    iconsGroup: 'mdi'
})
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
})

import Notifications from "./mixins/Notifications";
import Utilities from "./mixins/utilities.mixin";
import User from "./mixins/user.mixin";
import Mobile from "./mixins/Mobile";
import Strains from './mixins/Genetics';
import Spaces from './mixins/Spaces';
import Plants from './mixins/plants.mixin';
import Notes from './mixins/notes.mixin.global';
import Images from "./mixins/images.mixin";
import Error from './mixins/error.mixin'
import Albums from './mixins/albums.mixin'
import Application from './mixins/application.mixin'
import ValidationMixin from "@/mixins/validation.mixin";
import SecurityMixin from "@/mixins/security.mixin";

Vue.mixin(Notifications);
Vue.mixin(Utilities);
Vue.mixin(User);
Vue.mixin(Mobile);
Vue.mixin(Strains);
Vue.mixin(Spaces);
Vue.mixin(Plants);
Vue.mixin(Notes);
Vue.mixin(Images);
Vue.mixin(Error);
Vue.mixin(Albums)
Vue.mixin(Application)
Vue.mixin(ValidationMixin)
Vue.mixin(SecurityMixin)

const bounceTransition = createSimpleTransition('bounce-transition')

Vue.component('bounce-transition', bounceTransition)

Vue.config.productionTip = false

Vue.prototype.$EventBus = new Vue();
Vue.prototype.$http = Axios;

import _ from 'lodash'
import Axios from "axios";
import {FeedingSchedulesService} from "./services/feedingSchedules.service";
import {UsageService} from "@/services/usage.service";

Object.defineProperty(Vue.prototype, '$_', {value: _})

Vue.use(VueGtag, {
    config: {
        id: process.env.VUE_APP_G_TAG,
    }
}, router);

window.vm = new Vue({
    router,
    vuetify,
    store,
    watch:{
        async $route (to, from) {
            this.checkReferral();
            const currentPath = this.$router.currentRoute.path
            const dashboardRoutes = ['/app', '/spaces', '/plants', '/genetics', '/feeding-schedules']
            if (dashboardRoutes.some(route => currentPath.startsWith(route))) {
                if (!this.user) {
                    // Todo:  Need to find a better solution for this.
                    // this.$router.push('/sign-in')
                } else {
                    this.isDashboard = true
                }
            }
            window.scrollTo(0,0)
            // Hack for now
            this.$store.commit('setError', null)
            this.$store.commit('plants/SetError', null)
            this.$store.commit('spaces/SetError', null)
            this.$store.commit('images/SetError', null)
            this.$store.commit('albums/SetError', null)
            this.$store.commit('application/SetError', null)
            this.$store.commit('membership/SetError', null)
        }
    },
    methods: {
        async checkReferral() {
            const referralCode = this.$route.query.ref;
            if (referralCode && !this.$cookies.isKey('referral')) {
                try {
                    const response = await ReferralService.addReferral(referralCode);
                    this.$cookies.set('referral', response.data.referralId, { expires: 365 });
                    this.$router.replace({ query: {} });
                } catch (e) {
                    console.error(e);
                }
            }
        }
    },
    mounted() {
        this.checkReferral();
    },
    render: (h) => h(App),
}).$mount('#app')
