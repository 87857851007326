import api from "./api";

export const UsageService = {
    get() {
        return api.get('/usage');
    },
    getLevels() {
        return api.get('/usage/levels');
    },
    update(data) {
        return api.put('/usage/update', data);
    }
}
