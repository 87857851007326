import api from "./api";

export const ReferralService = {
    addReferral(referralCode) {
        return api.post('/referrals/add', {referralCode: referralCode})
    },
    getReferralCodes() {
        return api.get('/referrals/codes')
    },
    getReferrals() {
        return api.get('/referrals')
    },
    getReferralStats() {
        return api.get('/referrals/stats')
    },
    getReferralPayouts() {
        return api.get('/referrals/payouts')
    },
    getReferralPayoutTypes() {
        return api.get('/referrals/payout-types')
    },
    requestPayout(data) {
        return api.post('/referrals/request-payout', data)
    }
}