export default {
    methods: {
        sanitizeHTML(input, allowedTags = ['b', 'i', 'em', 'strong', 'a', 'ul', 'ol', 'li', 'p', 'br']) {
            const allowedAttributes = ['href', 'alt', 'title', 'class', 'style']
            const srcRegex = /src=(["'])(?:(?=(\\?))\2.)*?\1/gi;
            const tagRegex = new RegExp(`<(${allowedTags.join('|')})([^>]*?)>`, 'gi');

            const output = input.replace(tagRegex, (match, tag, attributes) => {
                const attrs = attributes.replace(srcRegex, (match) => {
                    try {
                        const url = new URL(match.substr(5).slice(0, -1));
                        if (!['http:', 'https:'].includes(url.protocol)) {
                            return '';
                        }
                    } catch (e) {
                        return '';
                    }
                    return match;
                });
                const cleanAttrs = attrs.split(/\s+/).filter(attribute => {
                    const [key, value] = attribute.split('=');
                    return allowedAttributes.includes(key.toLowerCase()) && value !== '';
                }).join(' ');

                return `<${tag} ${cleanAttrs}>`;
            })

            return output;
        }
    }
}