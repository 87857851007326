<template>
    <div>
        <v-app-bar
            app
            flat
            outlined
            style="border-top: 0; border-left: 0; border-right: 0;">
            <v-btn
                v-if="isDashboard || isMobilePort"
                rounded
                class="px-3"
                @click="$emit('toggle-drawer')"
                style="min-width: unset;">
                <v-icon>mdi-menu</v-icon>
            </v-btn>
            <router-link
                v-else
                to="/"
                class="text-decoration-none">
                <logo-branding class="mx-2">
                </logo-branding>
            </router-link>
            <v-spacer>
            </v-spacer>
            <template v-if="!isDashboard && !isMobilePort">
                <template
                    v-for="(item, key) in mainNavigation">
                    <v-menu
                        v-if="item.subLinks"
                        :key="key"
                        offset-y
                        min-width="300px"
                        z-index="300"
                        nudge-bottom="8px"
                        content-class="rounded-xl"
                        nudge-left="1">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="px-6"
                                rounded
                                v-bind="attrs"
                                v-on="on">
                                {{item.title}}
                            </v-btn>
                        </template>
                        <v-list
                            class="py-0">
                            <v-list-item-content
                                v-for="(item, key) in item.subLinks"
                                :key="key"
                                class="py-0">
                                <v-list-item :to="item.to">
                                    <v-list-item-icon class="mr-4">
                                        <v-icon>
                                            {{item.icon}}
                                        </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        {{item.title}}
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-content>
                        </v-list>
                    </v-menu>
                    <v-btn
                        v-else
                        :key="key"
                        class="px-6"
                        rounded
                        :to="item.to">
                        {{item.title}}
                    </v-btn>
                </template>
                <template v-if="!user">
                    <v-btn
                        class="px-6"
                        rounded
                        to="/sign-in">
                        Login
                    </v-btn>
                    <v-btn
                        rounded
                        class="ml-4 mr-2"
                        color="success"
                        to="/pricing">
                        <v-icon left>
                            mdi-gift-outline
                        </v-icon>
                        Try for Free
                    </v-btn>
                </template>
                <v-btn
                    v-else
                    rounded
                    class="ml-4 mr-2"
                    color="primary"
                    to="/app/dashboard">
                    <v-icon left>
                        mdi-view-dashboard-outline
                    </v-icon>
                    Dashboard
                </v-btn>
            </template>
            <v-btn
                v-if="!user"
                rounded
                class="mx-2 px-3"
                @click="setTheme()"
                style="min-width: unset">
                <v-icon small>
                    {{
                        $vuetify.theme.dark
                            ? 'mdi-weather-sunny'
                            : 'mdi-weather-night'
                    }}
                </v-icon>
            </v-btn>
            <v-menu
                v-else
                offset-y
                nudge-bottom="6px"
                content-class="rounded-xl"
                min-width="260px"
                z-index="300">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        rounded
                        v-bind="attrs"
                        v-on="on">
                        <v-icon
                            :left="isDashboard">
                            mdi-account-outline
                        </v-icon>
                        <template v-if="(isDashboard || isMobilePort) && user.displayName">
                            {{user.displayName}}
                        </template>
                    </v-btn>
                </template>
                <v-list class="py-0">
<!--                    <v-list-item-->
<!--                        :to="`/app/profile/${user._id}`">-->
<!--                        <v-list-item-icon class="mr-2">-->
<!--                            <v-icon>-->
<!--                                mdi-account-outline-->
<!--                            </v-icon>-->
<!--                        </v-list-item-icon>-->
<!--                        <v-list-item-title>-->
<!--                            My Profile-->
<!--                        </v-list-item-title>-->
<!--                    </v-list-item>-->
                    <v-list-item to="/app/account">
                        <v-list-item-icon class="mr-2">
                            <v-icon>
                                mdi-card-account-details-outline
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            Account
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/app/referrals/dashboard">
                        <v-list-item-icon class="mr-2">
                            <v-icon>
                                mdi-account-multiple-outline
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            Referrals
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        @click="setTheme()">
                        <v-list-item-icon class="mr-2">
                            <v-icon>
                                {{
                                    $vuetify.theme.dark
                                        ? 'mdi-weather-sunny'
                                        : 'mdi-weather-night'
                                }}
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            {{$vuetify.theme.dark ? 'Light' : 'Dark'}}
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider>
                    </v-divider>
                    <v-list-item
                        v-if="isAdmin"
                        to="/admin/dashboard">
                        <v-list-item-title>
                            Admin Dashboard
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        href="https://threerabbitholes.com/contact/"
                        target="_blank">
                        <v-list-item-title>
                            Support
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logOutUser()">
                        <v-list-item-title>
                            Sign Out
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
    </div>
</template>

<script>
    import LogoBranding from '../../components/Application/LogoBranding';
    import ProfileImage from "../Images/ProfileImage";
    import {UsersService} from "../../services/users.service"

    export default {
        name: 'AppBar',
        components: {
            LogoBranding,
            ProfileImage
        },
        methods: {
            async logOutUser() {
                try {
                    await UsersService.logout()
                    this.user = null
                    this.isDashboard = false
                    await this.$router.push({name: 'SignIn'})
                } catch (e) {
                    console.log(e)
                }
            },
            setTheme() {
                let theme = !this.$vuetify.theme.dark
                this.$vuetify.theme.dark = theme;
                this.$session.set('darkTheme', theme)
            }
        },
        mounted() {
            if (this.$session.has('darkTheme')) {
                this.$vuetify.theme.dark = this.$session.get('darkTheme');
            }
        }
    }
</script>
