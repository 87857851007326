<template>
    <v-card
        :outlined="!flat"
        :flat="flat">
        <v-card-text>
            <v-row>
                <v-col>
                    <p class="text-center">
                        Loading
                    </p>
                    <v-progress-linear
                        color="lightGreen"
                        indeterminate>
                    </v-progress-linear>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: 'CardLoader',
        props: {
            flat: {
                type: Boolean,
                default: false
            }
        }
    }
</script>