import api from "./api";

export const NotesService = {
    get() {
        return api.get('/notes');
    },
    viewNote(id) {
        return api.get(`/notes/view/id/${id}`)
    },
    getTopics() {
        return api.get('/notes/topics')
    },
    getByAssociation(data) {
        const { associatedId, type, onlyMe = false } = data
        return api.get(`/notes/association/${associatedId}/type/${type}/onlyMe/${onlyMe}`)
    },
    post(data) {
        return api.post('/notes', data)
    },
    put(id, data) {
        return api.put(`/notes/id/${id}`, data)
    },
    delete(id) {
        return api.delete(`/notes/id/${id}`)
    }
}
