<template>
    <v-row
        v-if="pagination"
        align="center"
        justify="center">
        <v-col
            v-if="showPerPage"
            cols="12"
            sm="auto"
            :class="{'text-center': isMobilePort}">
            <span class="grey--text caption">Items per page</span>
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        outlined
                        text
                        color="primary"
                        class="ml-2"
                        v-bind="attrs"
                        v-on="on">
                        {{ pagination.limit }}
                        <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                        v-for="(number, index) in itemsPerPageArray"
                        :key="index"
                        @click="updateItemsPerPage(number)">
                        <v-list-item-title>{{ number }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-col>
        <v-spacer v-if="!isMobilePort">
        </v-spacer>
        <v-col
            cols="12"
            sm="auto"
            :class="{'text-center': isMobilePort}">
                <span
                    class="mr-4 grey--text caption">
                    Page {{ pagination.page }} of {{ pagination.totalPages }}
                </span>
            <v-btn
                icon
                color="primary"
                class="mr-1"
                @click="formerPage"
                :disabled="!pagination.hasPrevPage">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
                icon
                color="primary"
                class="ml-1"
                @click="nextPage"
                :disabled="!pagination.hasNextPage">
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        props: {
            storeKey: {
                required: true,
                type: String
            },
            itemsPerPageArray: {
                required: true,
                type: Array
            },
            showPerPage: {
                required: false,
                type: Boolean,
                default: true
            }
        },
        computed: {
            pagination() {
                return this.$store.getters[this.storeKey];
            }
        },
        methods: {
            nextPage() {
                this.$emit('nextPage')
            },
            formerPage() {
                this.$emit('formerPage')
            },
            updateItemsPerPage(number) {
                this.$emit('updateItem', number)
            },
        }
    }
</script>