<template>
    <div>
        <div class="grey lighten-4 custom-tabs">
            <v-tabs
                v-model="tab">
                <v-tab
                    v-for="(item, key) in tabContent"
                    :key="key">
                    <v-icon class="mr-1">
                        {{item.icon}}
                    </v-icon>
                    {{ item.text }}
                </v-tab>
            </v-tabs>
        </div>
        <v-divider>
        </v-divider>
        <v-card-text class="pt-8">
            <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="(item, key) in tabContent"
                    :key="key"
                    :value="item.value">
                    <div
                        v-show="tab === 0"
                        class="mt-1">
                        <slot name="tab0">
                        </slot>
                    </div>
                    <div
                        v-show="tab === 1"
                        class="mt-1">
                        <slot name="tab1">
                        </slot>
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
    </div>
</template>

<script>
    export default {
        name: 'AddEditPanel',
        data() {
            return {
                tab: 0,
                tabContent: [
                    {
                        text: 'General',
                        icon: 'mdi-cog',
                        value: 0
                    },
                    {
                        text: 'Privacy',
                        icon: 'mdi-eye',
                        value: 1
                    }
                ],
            }
        },
        methods: {
            setTab(value) {
                this.tab = value
            }
        },
        mounted() {
            this.$EventBus.$on('updateTabAddEditPanel', (data) => this.setTab(data))
        }
    }
</script>