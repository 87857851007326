<template>
    <div v-if="hasItems">
        <v-row>
            <v-col>
                <p class="mb-0">
                    Associated with:
                </p>
                <template v-for="(types, type) in items">
                    <v-chip
                        v-if="types"
                        class="mr-1 mt-1"
                        v-for="item in types"
                        :key="item._id"
                        close
                        @click:close="removeChip(type, item)">
                        <profile-image
                            :size="20"
                            :item-id="item._id"
                            :type="type"
                            :image="item.profileImage"
                            :item-color="item.color"
                            :left="true">
                        </profile-image>
                        {{ item.name }}
                    </v-chip>
                </template>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ProfileImage from "../../components/Images/ProfileImage.vue";

    export default {
        name: 'ActiveChips',
        components: {ProfileImage},
        props: {
            items: {
                type: Object,
                required: false
            }
        },
        computed: {
            hasItems() {
                return this.hasValue(this.$getConst('typeKeys').spaces)
                    || this.hasValue(this.$getConst('typeKeys').genetics)
                    || this.hasValue(this.$getConst('typeKeys').plants)
            }
        },
        methods: {
            hasValue(type) {
                return this.items[type] && this.items[type].length > 0
            },
            removeChip(type, item) {
                const items = this.items[type].filter(i => i._id !== item._id)
                switch (type) {
                    case this.$getConst('typeKeys').spaces:
                        this.$EventBus.$emit('removeSpaceSelect', item)
                        break;
                    case this.$getConst('typeKeys').genetics:
                        this.$EventBus.$emit('removeGeneticsSelect', item)
                        break;
                    case this.$getConst('typeKeys').plants:
                        this.$EventBus.$emit('removePlantSelect', item)
                        break;
                }
                this.$emit('update', {items, type})
            }
        }
    }
</script>