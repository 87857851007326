import api from "./api";

export const ReportedContentService = {
    get() {
        return api.get('/reported-content');
    },
    post(data) {
        return api.post('/reported-content', data)
    }
}
