<template>
    <tiptap-vuetify
        v-model="description"
        :extensions="wysiwygExtensions"
        :card-props="{ flat: true, outlined: true, minHeight: '200px' }"
        :toolbar-attributes="{ color: $vuetify.theme.dark ? 'dark-grey' : 'grey lighten-3' }">
    </tiptap-vuetify>
</template>

<script>
    import {
        TiptapVuetify,
        Heading,
        Bold,
        Italic,
        Strike,
        Underline,
        Code,
        Paragraph,
        BulletList,
        OrderedList,
        ListItem,
        Link,
        Blockquote,
        HardBreak,
        HorizontalRule,
        History
    } from 'tiptap-vuetify';

    export default {
        name: 'WYSIWYGEditor',
        data() {
            return {
                description: '',
                wysiwygExtensions: [
                    History,
                    Blockquote,
                    Link,
                    Underline,
                    Strike,
                    Italic,
                    ListItem,
                    BulletList,
                    OrderedList,
                    [Heading, {
                        options: {
                            levels: [1, 2, 3]
                        }
                    }],
                    Bold,
                    Code,
                    HorizontalRule,
                    Paragraph,
                    HardBreak
                ],
            }
        },
        props: {
            defaultDescription: {
                required: false,
                type: String
            }
        },
        watch: {
            description(value) {
                this.$emit('updateDescription', value)
            }
        },
        components: {
            TiptapVuetify
        },
        mounted() {
            if (this.defaultDescription) {
                this.description = this.defaultDescription;
            }
        },
        beforeDestroy() {
            this.description = ''
        }
    }
</script>