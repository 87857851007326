<template>
    <div v-if="canEdit(item.userId._id)">
        <v-btn
            rounded
            :small="small"
            color="success"
            :block="isMobilePort"
            @click="add()">
            Add Album
        </v-btn>
    </div>
</template>

<script>
    export default {
        props: {
            item: {
                required: true,
                type: Object
            },
            type: {
                required: true,
                type: String,
            },
            small: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        methods: {
            add() {
                let data = {
                    associatedId: this.item._id,
                    type: this.type
                }
                this.showAddAlbumModal(data);
            }
        }
    }
</script>