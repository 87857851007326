export default {
    data() {
        return {
            nameRules: [
                v => !!v || 'Name is required'
            ],
            selectRules: [
                v => !!v || 'Please make a selection'
            ]
        }
    },
    methods: {
        decodeJSON(data) {
            if (data) {
                return JSON.parse(data);
            }
        },
        getFieldColumn(type) {
            switch (type) {
                case this.$getConst('typeKeys').spaces:
                    return 'spaceId'
                case this.$getConst('typeKeys').genetics:
                    return 'geneticsId'
                case this.$getConst('typeKeys').plants:
                    return 'plantId'
            }
        },
        calculateAllowance(number) {
            let identifier = 'MB'
            number = number/1000000
            if (number > 999) {
                number = number/1000
                identifier = 'G'
            }
            return number+identifier
        },
        socialShare(name, link, title = null, description = null) {
            this.$EventBus.$emit('showSocialShareModal', {
                name: name,
                title: title,
                description: description,
                link: window.location.protocol+'//'+window.location.hostname+link
            })
        },
        getTypeFromURL() {
            const type = this.$route.path.split('/')[1]
            if (type) {
                return type
            }
            return {}
        },
        stripPathFromUrl(lastIndex = null) {
            if (lastIndex) {
                let { path } = this.$route
                let newPath = path.substring(0, path.lastIndexOf(lastIndex))
                if (path !== newPath) {
                    return this.$router.push(newPath)
                }
            }
        },
        getScrollClass() {
            return this.$vuetify.theme.dark ? 'custom-scroll-dark' : 'custom-scroll-light'
        }
    }
}