import { ImageService } from '../services/image.service'
import {ErrorService} from "../services/error.service";
import {UsageService} from "../services/usage.service";

const imagesGetterTypes = {
    error: 'GetError',
    images: 'Images',
    usage: 'Usage',
    pagination: 'GetPagination',
    loading: 'Loading',
    uploading: 'Uploading'
}

const imagesMutationTypes = {
    setLoading: 'SetLoading',
    setUploading: 'SetUploading',
    setSuccess: 'SetSuccess',
    setUsage: 'SetUsage',
    setError: 'SetError',
    updateItem: 'UpdateItem',
    clearItem: 'ClearItem'
}

const imagesActionTypes = {
    get: 'Get',
    getUsage: 'GetUsage',
    add: 'Add',
    update: 'Update',
    delete: 'Delete',
    clearImages: 'ClearImages'
}

const state = () => ({
    error: null,
    pagination: {},
    records: {
        all: [],
        spaces: [],
        genetics: [],
        plants: [],
        albums: []
    },
    loading: false,
    uploading: false,
    usage: 0
})

const getters = {
    [imagesGetterTypes.error]: (state) => state.error,
    [imagesGetterTypes.images]: (state) => state.records,
    [imagesGetterTypes.usage]: (state) => state.usage,
    [imagesGetterTypes.pagination]: (state) => state.pagination,
    [imagesGetterTypes.loading]: (state) => state.loading,
    [imagesGetterTypes.uploading]: (state) => state.uploading,
}

const mutations = {
    [imagesMutationTypes.setLoading](state) {
        state.loading = true
    },

    [imagesMutationTypes.setUploading](state, data) {
        state.uploading = data
    },

    [imagesMutationTypes.setSuccess](state, data) {
        state.error = null
        if (data && data.results) {
            state.records[data.type] = data.results.docs
            delete data.results.docs
            state.pagination = data.results
        } else {
            state.records[data.type] = undefined
            state.pagination = {}
        }

        state.loading = false
    },

    [imagesMutationTypes.setError](state, message) {
        state.error = message
        state.loading = false
        state.uploading = false
    },

    [imagesMutationTypes.updateItem](state, data) {
        const records = state.records[data.type]
        const index = _.findIndex(records, {_id: data.results._id})
        records.splice(index, 1, data.results);
    },

    [imagesMutationTypes.clearItem](state, data) {
        if (data.id) {
            const records = state.records[data.type]
            const index = _.findIndex(records, {_id: data.id})
            records.splice(index, 1);
        } else {
            state.records[data.type] = []
        }
    },

    [imagesMutationTypes.setUsage](state, data) {
        state.usage = data
    },
}

const actions = {
    async [imagesActionTypes.get]({ state, commit, dispatch }, data) {
        commit(imagesMutationTypes.setLoading)
        try {
            let response
            if (data && data.associatedId) {
                state.records[data.type] = []
                response = await ImageService.getByAssociation(data)
            } else {
                response = await ImageService.get()
            }
            commit(imagesMutationTypes.setSuccess, {results: response.data.results, type: data.type})
        } catch ({error, response}) {
            commit(imagesMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [imagesActionTypes.getUsage]({ commit }) {

        try {
            const response = await UsageService.get()
            commit(imagesMutationTypes.setUsage, response.data.results)
        } catch ({error, response}) {
            commit(imagesMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [imagesActionTypes.add]({ dispatch, commit }, data) {
        commit(imagesMutationTypes.setUploading, true)

        try {
            await ImageService.upload(data.uploadData)
            dispatch(imagesActionTypes.get, data.getData)
            commit(imagesMutationTypes.setUploading, false)
            await dispatch(imagesActionTypes.getUsage)
        } catch ({error, response}) {
            if (response.data.allowanceLimit) {
                window.vm.$EventBus.$emit('showUpdateSubscriptionModal', response.data.message)
                commit(imagesMutationTypes.setUploading, false)
            } else {
                commit(imagesMutationTypes.setError, ErrorService.parseError(error, response))
            }
        }
    },

    async [imagesActionTypes.update]({ dispatch, commit }, data) {
        try {
            const response = await ImageService.put(data)
            if (data.remove) {
                commit(imagesMutationTypes.clearItem, {id: response.data.results._id, type: data.type})
            } else {
                commit(imagesMutationTypes.updateItem, {results: response.data.results, type: data.type})
            }
        } catch ({error, response}) {
            commit(imagesMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [imagesActionTypes.delete]({ dispatch, commit }, data) {
        try {
            await ImageService.delete(data)
            // commit(imagesMutationTypes.clearItem, {id: data.id, type: data.type})
            dispatch(imagesActionTypes.get, data.getData)
            await dispatch(imagesActionTypes.getUsage)
        } catch ({error, response}) {
            commit(imagesMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [imagesActionTypes.clearImages]({ commit }, data) {
        try {
            commit(imagesMutationTypes.setSuccess, data)
        } catch (error) {
            commit(imagesMutationTypes.setError, ErrorService.parseError(error))
        }
    },
}

export const ImagesStoreModule = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}