<template>
    <v-dialog
        persistent
        max-width="1020"
        v-model="dialog"
        :fullscreen="isMobilePort">
        <v-card>
            <v-card-title>
                <v-row align="center">
                    <v-col>
                        {{edit ? 'Edit' : 'Add'}} Todo List
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            icon
                            small
                            @click="close()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <card-loader
                v-if="modalLoading"
                class="mx-3"
                flat>
            </card-loader>
            <v-card-text
                v-else
                class="pt-2">
                <notification-modular
                    v-if="message && type"
                    :message="message"
                    :notification-type="type">
                </notification-modular>
                <active-chips
                    class="mb-2"
                    :items="activeGardenAssociation"
                    @update="(data) => removeItems(data)">
                </active-chips>
                <v-form>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="6">
                            <v-text-field
                                v-model="todoItem.title"
                                label="Title"
                                outlined
                                required>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <p class="caption mb-1">
                        Description:
                    </p>
                    <w-y-s-i-w-y-g-editor
                        v-if="dialog"
                        class="mb-6"
                        :default-description="todoItem.description"
                        @updateDescription="(data) => todoItem.description = data">
                    </w-y-s-i-w-y-g-editor>
                    <v-row>
                        <v-col>
                            <v-card outlined>
                                <v-card-title
                                    @click="details = !details"
                                    style="cursor: pointer;">
                                    <h5>
                                        Additional Details
                                    </h5>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-icon>
                                        {{details ? 'mdi-chevron-up' : 'mdi-chevron-down'}}
                                    </v-icon>
                                </v-card-title>
                                <v-expand-transition>
                                    <div v-show="details">
                                        <v-card-text class="pt-0">
                                            <p class="mb-2">
                                                Select the spaces, genetics, and plants associated with this note
                                            </p>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    sm="6">
                                                    <spaces-select
                                                        multiple
                                                        :selected="todoItem.spaceId"
                                                        @updateSpacesId="(data) => todoItem.spaceId = data">
                                                    </spaces-select>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6">
                                                    <genetics-select
                                                        multiple
                                                        :selected="todoItem.geneticsId"
                                                        @updateGeneticsId="(data) => todoItem.geneticsId = data">
                                                    </genetics-select>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6">
                                                    <plants-select
                                                        multiple
                                                        :selected="todoItem.plantId"
                                                        @updatePlantsId="(data) => todoItem.plantId = data">
                                                    </plants-select>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </div>
                                </v-expand-transition>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer>
                </v-spacer>
                <v-btn
                    rounded
                    text
                    @click="close()">
                    Cancel
                </v-btn>
                <v-btn
                    rounded
                    color="success"
                    @click="saveModal()"
                    :disabled="loading || modalLoading"
                    :loading="loading">
                    {{ edit ? 'Save' : 'Add' }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import CardLoader from "@/components/Application/CardLoader.vue";
    import NotificationModular from "@/components/Application/NotificationModular.vue";
    import ActiveChips from "@/components/Application/ActiveChips.vue";
    import WYSIWYGEditor from "@/components/Forms/WYSIWYGEditor.vue";
    import SpacesSelect from "@/components/Spaces/SpacesSelect.vue";
    import PlantsSelect from "@/components/Plants/PlantsSelect.vue";
    import GeneticsSelect from "@/components/Genetics/GeneticsSelect.vue";
    import {TodoService} from "@/services/todo.service";
    import {SpacesService} from "@/services/spaces.service";
    import {GeneticsService} from "@/services/genetics.service";
    import {PlantsService} from "@/services/plants.service";

    export default {
        name: 'AddTodoListModal',
        components: {
            GeneticsSelect,
            PlantsSelect,
            SpacesSelect,
            WYSIWYGEditor, ActiveChips, NotificationModular, CardLoader},
        data() {
            return {
                dialog: false,
                edit: false,
                loading: false,
                modalLoading: false,
                message: '',
                type: '',
                details: false,
                todoItem: {
                    title: '',
                    description: '',
                    spaceId: [],
                    geneticsId: [],
                    plantId: [],
                },
            }
        },
        computed: {
            activeGardenAssociation() {
                return {
                    [this.$getConst('typeKeys').spaces]: this.todoItem.spaceId ? this.todoItem.spaceId : [],
                    [this.$getConst('typeKeys').genetics]: this.todoItem.geneticsId ? this.todoItem.geneticsId : [],
                    [this.$getConst('typeKeys').plants]: this.todoItem.plantId ? this.todoItem.plantId : [],
                }
            }
        },
        methods: {
            async showModal(data) {
                this.dialog = true
                this.modalLoading = true
                if (data && data.todoItem) {
                    this.edit = true
                    this.todoItem = Object.assign({}, data.todoItem)
                } else {
                    if (data && data.associatedId && data.type) {
                        let response
                        switch (data.type) {
                            case this.$getConst('typeKeys').spaces:
                                response = await SpacesService.get(data.associatedId)
                                this.todoItem.spaceId = [response.data.space]
                                break;
                            case this.$getConst('typeKeys').genetics:
                                response = await GeneticsService.get(data.associatedId)
                                this.todoItem.geneticsId = [response.data.results]
                                break;
                            case this.$getConst('typeKeys').plants:
                                response = await PlantsService.getById(data.associatedId)
                                this.todoItem.plantId = [response.data.plant]
                                break;
                        }
                    }
                }
                this.modalLoading = false
            },
            removeItems(data) {
                switch (data.type) {
                    case this.$getConst('typeKeys').spaces:
                        this.todoItem.spaceId = data.items
                        break;
                    case this.$getConst('typeKeys').genetics:
                        this.todoItem.geneticsId = data.items
                        break;
                    case this.$getConst('typeKeys').plants:
                        this.todoItem.plantId = data.items
                        break;
                }
            },
            async saveModal() {
                this.loading = true
                this.message = ''
                this.type = ''
                try {
                    if (this.edit) {
                        await TodoService.updateTodoList(this.todoItem._id, this.todoItem)
                    } else {
                        await TodoService.postTodoList(this.todoItem)
                    }
                    this.$EventBus.$emit('refreshTodoList')
                    this.close()
                } catch ({response}) {
                    this.message = response.data.message
                    this.type = 'error'
                } finally {
                    this.loading = false
                }
            },
            close() {
                this.dialog = false
                this.edit = false
                this.loading = false
                this.modalLoading = false
                this.message = ''
                this.type = ''
                this.details = false
                this.todoItem = {
                    title: '',
                    description: '',
                    spaceId: [],
                    geneticsId: [],
                    plantId: [],
                }
            },
        },
        created() {
            this.$EventBus.$on('showTodoListModal', (data) => this.showModal(data))
        }
    }
</script>