<template>
    <v-chip
        class="mt-n1"
        rounded
        small
        v-if="albumId"
        label>
        {{albumId.title}}
    </v-chip>
</template>

<script>
    export default {
        props: {
            albumId: {
                required: false,
                type: Object
            }
        }
    }
</script>