<template>
    <v-select
        ref="select"
        outlined
        hide-details="auto"
        v-model="geneticsId"
        :items="resultsGenetics"
        item-text="name"
        label="Genetics"
        @change="$emit('updateGeneticsId', multiple ? geneticsId : [geneticsId])"
        :menu-props="{
            offsetY: true,
            closeOnClick: true ,
            top: true,
            nudgeTop: 10
        }"
        :multiple="multiple"
        :small-chips="multiple"
        :loading="loading"
        return-object>
        <template v-slot:selection="data">
            <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="remove(data.item)">
                <profile-image
                    :size="20"
                    :item-id="data.item._id"
                    :type="$getConst('typeKeys').genetics"
                    :image="data.item.profileImage"
                    :item-color="data.item.color"
                    :left="true">
                </profile-image>
                {{ data.item.name }}
            </v-chip>
        </template>
        <template v-slot:prepend-item>
            <v-list-item class="pb-2">
                <v-row align="center">
                    <v-col>
                        <v-btn
                            small
                            rounded
                            color="success"
                            @click="showGeneticsModal()">
                            Add Genetic
                        </v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            icon
                            @click="closeSelect">
                            <v-icon>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-list-item>
            <v-divider>
            </v-divider>
        </template>
        <template v-slot:item="data">
            <v-list-item-avatar size="35">
                <profile-image
                    :item-id="data.item._id"
                    :type="$getConst('typeKeys').genetics"
                    :image="data.item.profileImage"
                    :item-color="data.item.color"
                    :size="35">
                </profile-image>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title v-html="data.item.name">
                </v-list-item-title>
                <v-list-item-subtitle>
                    Updated: {{ data.item.updatedAt | moment('MM/DD/YYYY') }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </v-select>
</template>

<script>
    import {GeneticsService} from "../../services/genetics.service"
    import ProfileImage from "../Images/ProfileImage.vue";

    export default {
        name: 'GeneticsSelect',
        components: {ProfileImage},
        data() {
            return {
                loading: true,
                geneticsId: null,
                resultsGenetics: []
            }
        },
        props: {
            selected: {
                required: false,
                type: [String,Array,Object],
                default: null
            },
            multiple: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        methods: {
            remove(item) {
                if (Array.isArray(this.geneticsId)) {
                    this.geneticsId = this.geneticsId.filter(genetics => genetics._id !== item._id)
                } else {
                    this.geneticsId = null
                    this.$emit('updateGeneticsId', [])
                }
            },
            async getGenetics(options) {
                this.resultsGenetics = []
                this.loading = true
                try {
                    const resultsGenetics = await GeneticsService.get(null, true)
                    this.resultsGenetics = resultsGenetics.data.genetics

                    if (options && options.geneticsId) {
                        this.geneticsId.push(options.geneticsId)
                        this.$emit('updateGeneticsId', this.geneticsId)
                    }

                    this.loading = false
                } catch (error) {
                    this.loading = false
                    this.type = 'error'
                    this.message = error
                }
            },
            closeSelect() {
                this.$refs.select.blur();
            }
        },
        created() {
            this.$EventBus.$on('refreshData', (options) => this.getGenetics(options))
            this.$EventBus.$on('removeGeneticsSelect', (item) => this.remove(item))
        },
        mounted() {
            if (this.selected && this.multiple) {
                this.geneticsId = Array.isArray(this.selected) ? this.selected : [this.selected]
            } else {
                this.geneticsId = Array.isArray(this.selected) ? this.selected[0] : this.selected
            }
            this.getGenetics()
        }
    }
</script>

<style scoped>
    .result-info {
        position: fixed;
        width: 100%;
        bottom: 65px;
        margin-bottom: 10px;
    }
</style>