<template>
    <v-dialog
        persistent
        max-width="1020"
        v-model="dialog"
        :fullscreen="isMobilePort">
        <v-card>
            <v-card-title>
                <v-row align="center">
                    <v-col>
                        {{edit ? 'Edit' : 'Add'}} Note
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            icon
                            small
                            @click="close()">
                            <v-icon>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <card-loader
                v-if="modalLoading"
                class="mx-3"
                flat>
            </card-loader>
            <v-card-text
                v-else
                class="pt-2">
                <notification-modular
                    v-if="message && type"
                    :message="message"
                    :notification-type="type">
                </notification-modular>
                <active-chips
                    class="mb-2"
                    :items="activeGardenAssociation"
                    @update="(data) => removeItems(data)">
                </active-chips>
                <v-form>
                    <v-row align="center">
                        <v-col
                            cols="12"
                            sm="3">
                            <v-select
                                ref="select"
                                v-model="note.topicId"
                                outlined
                                clearable
                                label="Topic"
                                hide-details="auto"
                                :items="noteTopics"
                                item-text="title"
                                item-value="_id"
                                :loading="noteTopicsLoading"
                                :disabled="noteTopicsLoading"
                                :menu-props="{ bottom: true, offsetY: true }">
                                <template v-slot:prepend-item>
                                    <v-list-item class="pb-2">
                                        <v-row align="center">
                                            <v-col>
                                                <v-btn
                                                    small
                                                    rounded
                                                    color="success"
                                                    href="https://threerabbitholes.com/contact/"
                                                    target="_blank">
                                                    Request Topic
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn
                                                    icon
                                                    @click="closeSelect">
                                                    <v-icon>
                                                        mdi-close
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-list-item>
                                    <v-divider>
                                    </v-divider>
                                </template>
                                <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                        <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                        <v-chip
                                            light
                                            :color="item.color"
                                            class="cp">
                                            <span>
                                                {{ item.title }}
                                            </span>
                                        </v-chip>
                                    </template>
                                </template>
                                <template v-slot:selection="{ item }">
                                    <v-chip
                                        dark
                                        :color="item.color"
                                        class="cp">
                                        <span>
                                            {{ item.title }}
                                        </span>
                                    </v-chip>
                                </template>
                                <template v-slot:item="{ item }">
                                    <v-chip
                                        dark
                                        :color="item.color">
                                        {{item.title}}
                                    </v-chip>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="auto">
                            <p class="mb-0 subtitle-2">
                                or
                            </p>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="3">
                            <v-text-field
                                outlined
                                clearable
                                hide-details="auto"
                                v-model="note.title"
                                label="Custom Topic">
                            </v-text-field>
                        </v-col>
                        <v-spacer>
                        </v-spacer>
                        <v-col
                            cols="12"
                            sm="4">
                            <v-menu
                                max-height="80%"
                                v-model="menu"
                                transition="slide-y-transition"
                                offset-y
                                :close-on-content-click="false">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        outlined
                                        label="Date"
                                        hide-details
                                        v-bind:value="formattedDate"
                                        readonly
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-card>
                                    <v-card-text>
                                        <v-row>
                                            <v-col>
                                                <v-date-picker
                                                    v-model="customDate"
                                                    label="Date"
                                                    required
                                                    format="YYYY-MM-DDTHH:mm:ssZ">
                                                </v-date-picker>
                                            </v-col>
                                            <v-col>
                                                <v-time-picker
                                                    v-model="customTime"
                                                    label="Time"
                                                    format="ampm">
                                                </v-time-picker>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="end">
                                            <v-col cols="auto">
                                                <v-btn
                                                    rounded
                                                    color="primary"
                                                    @click="menu = false">
                                                    Ok
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <p class="caption mb-1">
                                Description:
                            </p>
                            <w-y-s-i-w-y-g-editor
                                v-if="dialog"
                                :default-description="note.description"
                                @updateDescription="(data) => note.description = data">
                            </w-y-s-i-w-y-g-editor>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card outlined>
                                <v-card-title
                                    @click="details = !details"
                                    style="cursor: pointer;">
                                    <h5>
                                        Additional Details
                                    </h5>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-icon>
                                        {{details ? 'mdi-chevron-up' : 'mdi-chevron-down'}}
                                    </v-icon>
                                </v-card-title>
                                <v-expand-transition>
                                    <div v-show="details">
                                        <v-card-text class="pt-0">
                                            <p class="mb-2">
                                                Select the spaces, genetics, and plants associated with this note
                                            </p>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    sm="6">
                                                    <spaces-select
                                                        multiple
                                                        :selected="note.spaceId"
                                                        @updateSpacesId="(data) => note.spaceId = data">
                                                    </spaces-select>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6">
                                                    <genetics-select
                                                        multiple
                                                        :selected="note.geneticsId"
                                                        @updateGeneticsId="(data) => note.geneticsId = data">
                                                    </genetics-select>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6">
                                                    <plants-select
                                                        multiple
                                                        :selected="note.plantId"
                                                        @updatePlantsId="(data) => note.plantId = data">
                                                    </plants-select>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </div>
                                </v-expand-transition>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer>
                </v-spacer>
                <v-btn
                    rounded
                    text
                    @click="close()">
                    Cancel
                </v-btn>
                <v-btn
                    rounded
                    color="success"
                    @click="addEditNote()"
                    :disabled="loading || modalLoading"
                    :loading="loading">
                    {{ edit ? 'Save' : 'Add' }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import moment from 'moment'
    import WYSIWYGEditor from "../Forms/WYSIWYGEditor";
    import {NotesService} from "@/services/notes.service";
    import SpacesSelect from "../Spaces/SpacesSelect.vue";
    import GeneticsSelect from "../Genetics/GeneticsSelect.vue";
    import PlantsSelect from "../Plants/PlantsSelect.vue";
    import {PlantsService} from "@/services/plants.service";
    import {GeneticsService} from "@/services/genetics.service";
    import {SpacesService} from "@/services/spaces.service";
    import ActiveChips from "@/components/Application/ActiveChips.vue";
    import CardLoader from "@/components/Application/CardLoader.vue";
    import NotificationModular from "@/components/Application/NotificationModular.vue";

    export default {
        name: 'AddNoteModal',
        data() {
            return {
                dialog: false,
                modalLoading: true,
                loading: false,
                edit: false,
                note: {
                    spaceId: null,
                    geneticId: null,
                    plantId: null,
                    topicId: null,
                    title: '',
                    description: '',
                    customDate: new Date().toISOString()
                },
                noteTopics: [],
                noteTopicsLoading: true,
                menu: false,
                customDate: moment().format('YYYY-MM-DD'),
                customTime: moment().format('HH:mm'),
                details: false,
                type: null,
                message: null,
            }
        },
        computed: {
            formattedDate() {
                return moment(this.note.customDate ? this.note.customDate : this.note.createdAt).format('MM/DD/YYYY hh:mm:ss A');
            },
            activeGardenAssociation() {
                return {
                    [this.$getConst('typeKeys').spaces]: this.note.spaceId ? this.note.spaceId : [],
                    [this.$getConst('typeKeys').genetics]: this.note.geneticsId ? this.note.geneticsId : [],
                    [this.$getConst('typeKeys').plants]: this.note.plantId ? this.note.plantId : [],
                }
            }
        },
        watch: {
            customDate: function(newDate) {
                this.setCustomDatetime();
            },
            customTime: function(newTime) {
                this.setCustomDatetime();
            }
        },
        components: {
            NotificationModular,
            CardLoader,
            ActiveChips,
            PlantsSelect,
            GeneticsSelect,
            SpacesSelect,
            WYSIWYGEditor
        },
        methods: {
            removeItems(data) {
                switch (data.type) {
                    case this.$getConst('typeKeys').spaces:
                        this.note.spaceId = data.items
                        break;
                    case this.$getConst('typeKeys').genetics:
                        this.note.geneticsId = data.items
                        break;
                    case this.$getConst('typeKeys').plants:
                        this.note.plantId = data.items
                        break;
                }
            },
            getSelected(ids) {
                if (typeof ids === 'string' || ids instanceof String) {
                    return ids
                }
                return  ids ? ids.map(obj => obj._id) : ids
            },
            setCustomDatetime() {
                if (this.customDate && this.customTime){
                    let date = new Date(this.customDate + "T" + this.customTime);
                    this.note.customDate = date.toISOString();
                }
            },
            async showAddNoteModal(data) {
                this.type = null
                this.message = null
                this.dialog = true
                this.modalLoading = true
                this.$EventBus.$emit('closeNoteViewModal')
                await this.getNoteTopics()
                if (data && data.note) {
                    this.note = Object.assign({}, data.note)
                    if (data.fromAi) {
                        const aiTopic = this.noteTopics.find(obj => obj.title === 'AI Note')
                        if (aiTopic) {
                            this.note.topicId = aiTopic._id
                        }
                        this.setCustomDatetime()
                    } else {
                        this.edit = true
                    }
                } else {
                    this.defaultNote()
                    if (data && data.associatedId && data.type) {
                        let response
                        switch (data.type) {
                            case this.$getConst('typeKeys').spaces:
                                response = await SpacesService.get(data.associatedId)
                                this.note.spaceId = [response.data.space]
                                break;
                            case this.$getConst('typeKeys').genetics:
                                response = await GeneticsService.get(data.associatedId)
                                this.note.geneticsId = [response.data.results]
                                break;
                            case this.$getConst('typeKeys').plants:
                                response = await PlantsService.getById(data.associatedId)
                                this.note.plantId = [response.data.plant]
                                break;
                        }
                    }
                }
                this.modalLoading = false
                // this.setCustomDatetime()
            },
            async getNoteTopics() {
                this.noteTopicsLoading = true
                try {
                    const results = await NotesService.getTopics()
                    this.noteTopics = results.data.noteTopics
                } catch (error) {
                    console.log('Error getting note topics', error)
                }
                this.noteTopicsLoading = false
            },
            defaultNote() {
                this.$set(this, 'note', {
                    spaceId: null,
                    geneticsId: null,
                    plantId: null,
                    topicId: null,
                    title: '',
                    description: '',
                    customDate: new Date().toISOString()
                })
            },
            async addEditNote() {
                this.type = null
                this.message = null
                try {
                    this.loading = true
                    let data = Object.assign({}, this.note)
                    let loop = [
                        'spaceId',
                        'geneticsId',
                        'plantId'
                    ]
                    loop.forEach(obj => {
                        if (data[obj]) {
                            data[obj] = this.getSelected(data[obj])
                        }
                    })
                    if (this.edit) {
                        await NotesService.put(this.note._id, data)
                    } else {
                        await NotesService.post(data)
                    }
                    await this.$nextTick(() => {
                        if (this.edit) {
                            this.scrollPosition = window.scrollY
                        }
                        this.$EventBus.$emit('refreshNotes')
                        this.close()
                    })
                } catch ({response}) {
                    this.type = 'error'
                    this.message = response.data.message
                } finally {
                    this.loading = false
                }
            },
            close() {
                this.defaultNote();
                this.edit = false
                this.loading = false
                this.dialog = false
            },
            closeSelect() {
                this.$refs.select.blur();
            }
        },
        created() {
            this.$EventBus.$on('showAddNoteModal', (data) => this.showAddNoteModal(data))
        }
    }
</script>