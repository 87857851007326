import {store} from "@/store";

export default {
    computed: {
        user: {
            get() {
                return this.$store.getters['users/User']
            },
            set(user) {
                this.$store.commit('users/SetUser', user)
            }
        },
        isAdmin() {
            if (this.user) {
                return _.includes(this.user.role, 'administrator')
            }
        },
        getLimit() {
            if (this.user && this.levels.length) {
                const index = _.findIndex(this.levels, {subscriptionId: this.user.subscriptionLevel})
                if (index === 0 || index > 0) {
                    return this.levels[index].allowance
                }
            }
        },
        paidSub() {
            return this.user && this.user.subscriptionLevel > 1
        }
    },
    methods: {
        canEdit(idHash) {
            return this.user &&
                this.user.idHash === idHash
        }
    }
}