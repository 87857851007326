import { SpacesService } from '../services/spaces.service'
import {ErrorService} from "../services/error.service";

const spacesGetterTypes = {
    error: 'GetError',
    spaces: 'Spaces',
    singleSpace: 'SingleSpace',
    loading: 'Loading',
}

const spacesMutationTypes = {
    setLoading: 'SetLoading',
    setSuccess: 'SetSuccess',
    setSingle: 'SetSingle',
    setError: 'SetError',
}

const spacesActionTypes = {
    get: 'GetAll',
    getSingle: 'GetSingle',
    clearSingle: 'ClearSingle',
    add: 'Add',
    update: 'Update',
    delete: 'Delete'
}

const state = () => ({
    error: null,
    records: undefined,
    singleRecord: null,
    loading: false,
})

const getters = {
    [spacesGetterTypes.error]: (state) => state.error,
    [spacesGetterTypes.spaces]: (state) => state.records,
    [spacesGetterTypes.loading]: (state) => state.loading,
    [spacesGetterTypes.singleSpace]: (state) => state.singleRecord,
}

const mutations = {
    [spacesMutationTypes.setLoading](state) {
        state.loading = true
    },

    [spacesMutationTypes.setSuccess](state, data) {
        state.records = data
        state.loading = false
    },

    [spacesMutationTypes.setSingle](state, data) {
        state.singleRecord = data
        state.loading = false
    },

    [spacesMutationTypes.setError](state, message) {
        state.error = message
        state.loading = false
    },
}

const actions = {
    async [spacesActionTypes.get]({ commit }) {
        commit(spacesMutationTypes.setLoading)

        try {
            const response = await SpacesService.get(null, true)
            commit(spacesMutationTypes.setSuccess, response.data.spaces)
        } catch ({error, response}) {
            commit(spacesMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [spacesActionTypes.getSingle]({ state, commit }, id) {
        commit(spacesMutationTypes.setLoading)

        try {
            const response = await SpacesService.get(id)
            commit(spacesMutationTypes.setSingle, response.data.space)
        } catch ({error, response}) {
            commit(spacesMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [spacesActionTypes.clearSingle]({ state, commit }) {
        try {
            commit(spacesMutationTypes.setSingle, undefined)
        } catch ({error, response}) {
            commit(spacesMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [spacesActionTypes.add]({ dispatch, commit }, spacesData) {
        try {
            const space = await SpacesService.post(spacesData)
            await dispatch(spacesActionTypes.get, true)
            return space.data.spaces._id
        } catch ({error, response}) {
            commit(spacesMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [spacesActionTypes.update]({ dispatch, commit }, payload) {
        try {
            await SpacesService.put(payload.id, payload)
            await dispatch(spacesActionTypes.get, true)
            dispatch(spacesActionTypes.getSingle, payload.id)
        } catch ({error, response}) {
            commit(spacesMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [spacesActionTypes.delete]({ dispatch, commit }, id) {
        try {
            await SpacesService.delete(id)
            await dispatch(spacesActionTypes.get, true)
            await window.vm.$store.dispatch('images/GetUsage')
        } catch ({error, response}) {
            commit(spacesMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },
}

export const SpacesStoreModule = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}