<template>
    <div class="code-block">
        <div class="copy-button">
            <v-tooltip bottom>
                <template #activator="{ on }">
                    <v-btn icon v-on="on" @click="copyCode">
                        <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                </template>
                <span>Copy</span>
            </v-tooltip>
        </div>
        <pre :class="languageClass">
            <code
                v-html="highlightedCode"
                style="white-space: pre-wrap;">
            </code>
        </pre>
    </div>
</template>

<script>
import Prism from 'prismjs';
import 'prismjs/themes/prism-okaidia.css';
import 'prismjs/plugins/line-numbers/prism-line-numbers.min.js';
import 'prismjs/components/prism-javascript.min.js';
import 'prismjs/components/prism-python.min.js';

export default {
    props: {
        code: {
            type: String,
            required: true,
        },
        language: {
            type: String,
            default: 'javascript',
        },
    },
    data() {
        return {
            copied: false,
        };
    },
    computed: {
        highlightedCode() {
            return Prism.highlight(this.code, Prism.languages[this.language], this.language);
        },
        languageClass() {
            return `language-${this.language} line-numbers`;
        },
    },
    methods: {
        copyCode() {
            const el = document.createElement('textarea');
            el.value = this.code;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.copied = true;
            setTimeout(() => {
                this.copied = false;
            }, 1000);
        },
    },
};
</script>

<style>
.code-block {
    position: relative;
}

.copy-button {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
}

.copy-button .v-btn {
    background-color: rgba(255, 255, 255, 0.5);
    color: black;
}

.copy-button .v-btn:hover {
    background-color: rgba(255, 255, 255, 0.8);
}

.copied {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    transition: opacity 0.5s;
    opacity: 0;
}

.copied.show {
    opacity: 1;
}
</style>
