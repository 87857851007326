<template>
    <v-dialog
        persistent
        max-width="1020"
        v-model="dialog"
        :fullscreen="isMobilePort"
        style="z-index: 215">
        <v-card>
            <v-card-title>
                <v-row align="center">
                    <v-col>
                        {{edit ? 'Edit' : 'Add'}} Genetics
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            icon
                            small
                            @click="close()">
                            <v-icon>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <card-loader
                v-if="modalLoading"
                class="mx-3"
                flat>
            </card-loader>
            <v-card-text
                v-else
                class="pt-2">
                <notification-modular
                    v-if="message && type"
                    :notification-type="type"
                    :message="message">
                </notification-modular>
                <v-form ref="addGeneticsForm">
                    <v-row>
                        <v-col
                            cols="12"
                            sm="6">
                            <v-text-field
                                :rules="nameRules"
                                outlined
                                hide-details="auto"
                                v-model="strain.name"
                                placeholder="Ex: Crimson Watermelon"
                                label="Genetic Name">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <p class="caption mb-1">
                                Description:
                            </p>
                            <w-y-s-i-w-y-g-editor
                                v-if="dialog"
                                :default-description="strain.description"
                                @updateDescription="(data) => strain.description = data">
                            </w-y-s-i-w-y-g-editor>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card outlined>
                                <v-card-title
                                    @click="details = !details"
                                    style="cursor: pointer;">
                                    <h5>
                                        Additional Details
                                    </h5>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-icon>
                                        {{details ? 'mdi-chevron-up' : 'mdi-chevron-down'}}
                                    </v-icon>
                                </v-card-title>
                                <v-expand-transition>
                                    <div v-show="details">
                                        <v-card-text>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    sm="6">
                                                    <v-autocomplete
                                                        chips
                                                        small-chips
                                                        multiple
                                                        outlined
                                                        hide-details="auto"
                                                        label="Parent Genetics"
                                                        clearable
                                                        v-model="strain.parentStrains"
                                                        :items="genetics"
                                                        item-text="name"
                                                        item-value="_id"
                                                        no-data-text="No strains to select from">
                                                    </v-autocomplete>
                                                    <p class="caption mb-0">
                                                        As you add genetics they will be added to the list above.
                                                    </p>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="3">
                                                    <color-picker
                                                        :default-color="strain.color"
                                                        @updateColor="(color) => strain.color = color">
                                                    </color-picker>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col
                                                    cols="6"
                                                    sm="3">
                                                    <v-text-field
                                                        v-model="strain.thcLevel"
                                                        outlined
                                                        hide-details
                                                        type="number"
                                                        label="THC Level (%)"
                                                        append-icon="mdi-percent">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="6"
                                                    sm="3">
                                                    <v-text-field
                                                        v-model="strain.cbdLevel"
                                                        outlined
                                                        hide-details
                                                        type="number"
                                                        label="CBD Level (%)"
                                                        append-icon="mdi-percent">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="6"
                                                    sm="3">
                                                    <v-text-field
                                                        v-model="strain.maxYieldIndoor"
                                                        outlined
                                                        hide-details
                                                        type="number"
                                                        label="Max Yield Indoors (g)"
                                                        placeholder="Weight in grams"
                                                        append-icon="mdi-weight-gram">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="6"
                                                    sm="3">
                                                    <v-text-field
                                                        v-model="strain.maxYieldOutdoor"
                                                        outlined
                                                        hide-details
                                                        type="number"
                                                        label="Max Yield Outdoors (g)"
                                                        placeholder="Weight in grams"
                                                        append-icon="mdi-weight-gram">
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col
                                                    cols="6"
                                                    sm="3">
                                                    <v-text-field
                                                        v-model="strain.heightIndoor"
                                                        outlined
                                                        hide-details
                                                        type="number"
                                                        label="Height Indoors (ft)"
                                                        placeholder="Height in feet"
                                                        append-icon="mdi-human-male-height">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="6"
                                                    sm="3">
                                                    <v-text-field
                                                        v-model="strain.heightOutdoor"
                                                        outlined
                                                        hide-details
                                                        type="number"
                                                        label="Height Outdoors (ft)"
                                                        placeholder="Height in feet"
                                                        append-icon="mdi-human-male-height">
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <p class="caption mb-1">
                                                        Effects:
                                                    </p>
                                                    <w-y-s-i-w-y-g-editor
                                                        v-if="dialog"
                                                        :default-description="strain.effects"
                                                        @updateDescription="(data) => strain.effects = data">
                                                    </w-y-s-i-w-y-g-editor>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </div>
                                </v-expand-transition>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer>
                </v-spacer>
                <v-btn
                    text
                    rounded
                    @click="close()">
                    Cancel
                </v-btn>
                <v-btn
                    rounded
                    color="success"
                    @click="addEditGenetics()"
                    :disabled="loading"
                    :loading="loading">
                    {{ edit ? 'Save' : 'Add' }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import ColorPicker from "../Forms/ColorPicker";
    import WYSIWYGEditor from "../Forms/WYSIWYGEditor";
    import AddEditPanel from "../TabPanels/AddEditPanel.vue";
    import NotificationModular from "@/components/Application/NotificationModular.vue";
    import {GeneticsService} from "@/services/genetics.service";
    import CardLoader from "@/components/Application/CardLoader.vue";

    export default {
        data() {
            return {
                dialog: false,
                loading: false,
                modalLoading: false,
                edit: false,
                details: false,
                strain: {
                    name: '',
                    color: this.$getConst('defaultColor'),
                    parentStrains: [],
                    thcLevel: null,
                    cbdLevel: null,
                    maxYieldIndoor: null,
                    maxYieldOutdoor: null,
                    heightIndoor: null,
                    heightOutdoor: null,
                    floweringTime: null,
                    effects: '',
                    description: '',
                },
                redirectAfter: false,
                type: null,
                message: null
            }
        },
        components: {
            CardLoader,
            NotificationModular,
            AddEditPanel,
            ColorPicker,
            WYSIWYGEditor
        },
        methods: {
            async showGeneticsModalView(data = {}) {
                this.type = null
                this.message = null
                this.modalLoading = true
                this.dialog = true
                this.$store.dispatch('genetics/GetAll');
                if (data && data._id) {
                    try {
                        const response = await GeneticsService.get(data._id)
                        this.strain = response.data.results
                    } catch ({response}) {
                        this.type = 'error'
                        this.message = response.data.message
                        return false
                    }

                    this.edit = true
                } else {
                    this.defaultGenetics()
                    if (data.redirectAfter) {
                        this.redirectAfter = true
                    }
                }
                this.modalLoading = false
            },
            defaultGenetics() {
                this.$set(this, 'strain', {
                    name: '',
                    color: this.$getConst('defaultColor'),
                    parentStrains: [],
                    thcLevel: null,
                    cbdLevel: null,
                    maxYieldIndoor: null,
                    maxYieldOutdoor: null,
                    heightIndoor: null,
                    heightOutdoor: null,
                    floweringTime: null,
                    effects: '',
                    description: ''
                })
            },
            async addEditGenetics() {
                if (this.strain.name === '') {
                    this.$refs.addGeneticsForm.validate()
                    return false;
                }
                this.type = null
                this.message = null
                this.loading = true
                let responseData

                try {
                    if (this.edit) {
                        responseData = await GeneticsService.put(this.strain._id, this.strain)
                    } else {
                        responseData = await GeneticsService.post(this.strain)
                    }

                    const responseId = responseData.data.results._id
                    if (responseId && this.redirectAfter) {
                        this.$router.push({name: 'GeneticsView', params: {id: responseId}})
                    }
                    this.$EventBus.$emit('refreshData', {geneticsId: responseId})
                    this.close();
                } catch ({response}) {
                    this.type = 'error'
                    this.message = response.data.message
                    this.loading = false
                }
            },
            close() {
                this.dialog = false
                this.redirectAfter = false
                this.edit = false
                this.loading = false
                // const _this = this
                // setTimeout(function() {
                //     _this.defaultGenetics()
                //     _this.edit = false
                //     _this.loading = false
                // }, this.$getConst('modalClearTimeout'))
            }
        },
        created() {
            this.$EventBus.$on('showGeneticsModal', (data) => this.showGeneticsModalView(data))
        }
    }
</script>