<template>
    <v-dialog
        persistent
        max-width="1020"
        v-model="modal"
        :fullscreen="isMobilePort">
        <v-card>
            <v-card-title>
                <v-row align="center">
                    <v-col>
                        {{edit ? 'Edit Todo Item' : 'Add Todo Items'}}
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            icon
                            small
                            @click="close()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <card-loader
                v-if="modalLoading"
                class="mx-3"
                flat>
            </card-loader>
            <v-card-text
                v-else
                class="pt-2">
                <notification-modular
                    v-if="message && type"
                    :message="message"
                    :notification-type="type">
                </notification-modular>
                <template v-else>
                    <v-form>
                        <v-row
                            align="center"
                            v-for="(item, index) in todoItems"
                            :key="index">
                            <v-col>
                                <v-text-field
                                    v-model="item.title"
                                    label="Title"
                                    outlined
                                    hide-details="auto"
                                    required>
                                </v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="3">
                                <v-dialog
                                    ref="pickerModal"
                                    v-model="pickerModal"
                                    persistent
                                    width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="item.dueDate"
                                            outlined
                                            label="Due Date"
                                            v-bind="attrs"
                                            v-on="on"
                                            hide-details>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="item.dueDate"
                                        scrollable
                                        @input="pickerModal = false">
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            rounded
                                            color="primary"
                                            @click="pickerModal = false">
                                            Cancel
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col
                                v-if="!edit"
                                cols="auto">
                                <v-btn
                                    icon
                                    @click="todoItems.splice(index, 1)">
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row
                        v-if="!edit"
                        justify="end">
                        <v-col cols="auto">
                            <v-btn
                                rounded
                                color="primary"
                                @click="addTodoItem()">
                                <v-icon>mdi-plus</v-icon>
                                Add Todo Item
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
            </v-card-text>
            <v-card-actions>
                <v-spacer>
                </v-spacer>
                <v-btn
                    rounded
                    text
                    @click="close()">
                    Cancel
                </v-btn>
                <v-btn
                    rounded
                    color="success"
                    @click="saveData()"
                    :disabled="loading || modalLoading"
                    :loading="loading">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import CardLoader from "@/components/Application/CardLoader.vue";
    import NotificationModular from "@/components/Application/NotificationModular.vue";
    import {TodoService} from "@/services/todo.service";

    export default {
        name: 'AddTodoItemModal',
        components: {NotificationModular, CardLoader},
        data() {
            return {
                modal: false,
                pickerModal: false,
                edit: false,
                loading: false,
                modalLoading: false,
                message: '',
                type: '',
                listId: null,
                todoItems: [
                    {
                        listId: '',
                        title: '',
                        dueDate: ''
                    }
                ],
            }
        },
        methods: {
            showModal(data) {
                this.modalLoading = true
                this.modal = true
                if (!data.listId) {
                    this.message = 'List ID is required'
                    this.type = 'error'
                    this.modalLoading = false
                    return
                }
                this.listId = data.listId
                if (data && data.todoListItem) {
                    this.edit = true
                    this.todoItems = [data.todoListItem]
                } else {
                    this.edit = false
                    this.todoItems = [
                        {
                            listId: data.listId,
                            title: '',
                            dueDate: ''
                        }
                    ]
                }
                this.modalLoading = false
            },
            close() {
                this.modal = false
                this.edit = false
                this.listId = null
                this.todoItems = [
                    {
                        listId: '',
                        title: '',
                        dueDate: ''
                    }
                ]
            },
            async addTodoItem() {
                this.todoItems.push({
                    listId: this.listId,
                    title: '',
                    dueDate: ''
                })
            },
            async saveData() {
                this.loading = true
                try {
                    if (this.edit) {
                        await TodoService.updateTodoListItem(this.listId, this.todoItems[0])
                    } else {
                        await TodoService.postTodoListItem(this.listId, this.todoItems)
                    }
                    this.$EventBus.$emit('refreshTodoList')
                    this.close()
                } catch({response}) {
                    this.message = response.data.message
                    this.type = 'error'
                } finally {
                    this.loading = false
                }
            }
        },
        created() {
            this.$EventBus.$on('showAddTodoItemModal', (data) => this.showModal(data))
        },
    }
</script>