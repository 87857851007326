const usersGetterTypes = {
    user: 'User'
}

const usersMutationTypes = {
    setUser: 'SetUser',
    setUserData: 'SetUserData'
}

const state = () => ({
    user: null,
})

const getters = {
    [usersGetterTypes.user]: (state) => state.user
}

const mutations = {
    [usersMutationTypes.setUser](state, value) {
        state.user = value
    },
    [usersMutationTypes.setUserData](state, data) {
        state.user[data.key] = data.value;
    }
}

export const usersStoreModule = {
    namespaced: true,
    state,
    getters,
    mutations,
}
