export default {
    data() {
        return {
            specialCharMessage: 'Special characters are not allowed.',
            passwordRules: [
                v => !!v || 'Password cannot be empty',
                v => (v && v.length >= 8) || 'Password must be at least 8 characters long',
                v => (v && /[A-Z]/.test(v)) || 'Password must have at least one uppercase letter',
                v => (v && /[a-z]/.test(v)) || 'Password must have at least one lowercase letter',
                v => (v && /[0-9]/.test(v)) || 'Password must have at least one number',
                v => (v && /[^A-Za-z0-9]/.test(v)) || 'Password must have at least one special character'
            ],
            firstNameRules: [
                v => !!v || 'First name is required',
            ],
            lastNameRules: [
                v => !!v || 'Last name is required',
            ],
            codeRules: [
                v => !!v || 'Code is required',
            ],
        }
    },
    methods: {
        validateSpecialCharacters(value) {
            const regex = /[^a-zA-Z0-9 ]/;
            return !regex.test(value);
        },
        emailRule: v => !!v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) || 'E-mail must be valid',
    }
}