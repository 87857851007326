import api from "./api";

export const MembershipService = {
    getInvoices() {
        return api.get('/membership/invoices');
    },
    getBilling() {
        return api.get('/membership/billing');
    }
}
