<template>
    <v-dialog
        persistent
        max-width="800"
        v-model="dialog">
        <v-card>
            <v-card-title>
                <v-spacer>
                </v-spacer>
                <v-btn
                    icon
                    small
                    @click="dialog = false">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="text-center pb-4">
                <v-row>
                    <v-col>
                        <v-icon class="mb-3">
                            mdi-sprout
                        </v-icon>
                        <h2 class="mb-3 font-weight-medium">
                            An account is required to access this feature
                        </h2>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <v-btn
                            rounded
                            large
                            :block="isMobilePort"
                            color="success"
                            to="/pricing"
                            @click="dialog = false">
                            Create Free Account
                        </v-btn>
                    </v-col>
                    <v-col cols="12">
                        <v-btn
                            rounded
                            large
                            :block="isMobilePort"
                            to="/sign-in"
                            color="success"
                            @click="dialog = false">
                            Sign In
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'AccountRequiredModal',
        data() {
            return {
                dialog: false
            }
        },
        created() {
            this.$EventBus.$on('showAccountRequired', () => this.dialog = true)
        }
    }
</script>