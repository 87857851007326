export default {
    methods: {
        editNote(note) {
            let data = {
                note: note,
                associatedId: this.associatedId,
                type: this.type
            }
            this.$EventBus.$emit('showAddNoteModal', data)
        },
        confirmDeleteNote(note) {
            let data = {
                noteId: note._id
            }
            this.$EventBus.$emit('showConfirmNoteDelete', data)
        },
        getNoteLink(noteId) {
            let basePath = this.$route.path.split('/')[1]
            let id = this.$route.params.id

            let note = {}
            switch (basePath) {
                case this.$getConst('typeKeys').spaces:
                    note.name = 'SpacesViewNote'
                    break
                case this.$getConst('typeKeys').genetics:
                    note.name = 'GeneticsViewNote'
                    break
                case this.$getConst('typeKeys').plants:
                    note.name = 'PlantsViewNote'
                    break
                default:
                    note.name = 'NotesNote'
            }
            note.params = { id: id, noteId: noteId }
            return note
        }
    }
}