<template>
    <div>
        <v-menu
            left
            nudge-top="-8"
            offset-y
            z-index="220">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    :small="small"
                    icon
                    v-bind="attrs"
                    v-on="on">
                    <v-icon>
                        mdi-dots-vertical
                    </v-icon>
                </v-btn>
            </template>
            <v-card
                flat
                class="rounded-xl"
                max-width="300">
                <v-list class="my-0 py-0">
                    <v-list-item @click="addNote(item.response)">
                        <v-list-item-icon class="mr-1">
                            <v-icon>
                                mdi-note-plus-outline
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            Save Note
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        v-if="showRestore"
                        @click="restore(item)">
                        <v-list-item-icon class="mr-1">
                            <v-icon>
                                mdi-restore
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            Restore
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        v-if="item.status === undefined && showSubmit"
                        @click="qaAction(item)">
                        <v-list-item-icon class="mr-1">
                            <v-icon>
                                mdi-tooltip-question-outline
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            <template>
                                Submit to QA
                            </template>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
    import ShowDown from "showdown";

    export default {
        name: 'AiMenu',
        props: {
            item: {
                required: true,
                type: Object
            },
            small: {
                required: false,
                type: Boolean,
                default: false
            },
            showRestore: {
                required: false,
                type: Boolean,
                default: true
            },
            showSubmit: {
                required: false,
                type: Boolean,
                default: true
            }
        },
        methods: {
            qaAction(item) {
                if (item.status === undefined) {
                    this.$EventBus.$emit('showQaCSubmitModal', item._id)
                }
            },
            addNote(content) {
                const converter = new ShowDown.Converter()
                content = converter.makeHtml(content)
                const noteData = {
                    note: {
                        description: content
                    },
                    fromAi: true
                }
                this.$EventBus.$emit('showAddNoteModal', noteData)
            },
            restore(data) {
                this.$EventBus.$emit('restoreChat', data)
                this.$EventBus.$emit('closeHistoryModal')
            }
        }
    }
</script>