var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"to":("/app/mona-ai/" + (_vm.image._id))}},[_c('v-list-item-title',[_vm._v(" Ask Mona AI ")])],1),_c('v-list-item',{attrs:{"to":_vm.getImageLink(_vm.image._id),"rel":"nofollow noopener noreferrer"}},[_c('v-list-item-title',[_vm._v(" View ")])],1),(_vm.canEdit(_vm.image.userId._id))?[_c('v-list-item',{on:{"click":function($event){return _vm.editImage(_vm.image)}}},[_c('v-list-item-title',[_vm._v(" Edit ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteImage(_vm.image)}}},[_c('v-list-item-title',[_vm._v(" Delete ")])],1)]:[_c('v-list-item',{on:{"click":function($event){_vm.showReportContentModal({
                        id: _vm.image._id,
                        userId: _vm.image.userId._id,
                        type: _vm.$getConst('typeKeys').images
                    })}}},[_c('v-list-item-title',[_vm._v(" Report ")])],1)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }