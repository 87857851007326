<template>
    <v-select
        ref="select"
        outlined
        hide-details="auto"
        v-model="plantsId"
        :items="resultsPlants"
        item-text="name"
        label="Plants"
        @change="$emit('updatePlantsId', plantsId)"
        :menu-props="{
            offsetY: true,
            closeOnClick: true ,
            top: true,
            nudgeTop: 10
        }"
        :multiple="multiple"
        :small-chips="multiple"
        :loading="loading"
        return-object>
        <template v-slot:selection="data">
            <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="remove(data.item)">
                <profile-image
                    :size="20"
                    :item-id="data.item._id"
                    :type="$getConst('typeKeys').plants"
                    :image="data.item.profileImage"
                    :item-color="data.item.color"
                    :left="true">
                </profile-image>
                {{ data.item.name }}
            </v-chip>
        </template>
        <template v-slot:prepend-item>
            <v-list-item class="pb-2">
                <v-row align="center">
                    <v-col>
                        <v-btn
                            small
                            rounded
                            color="success"
                            @click="$EventBus.$emit('showPlantModal')">
                            Add Plant
                        </v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            icon
                            @click="closeSelect">
                            <v-icon>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-list-item>
            <v-divider>
            </v-divider>
        </template>
        <template v-slot:item="data">
            <v-list-item-avatar
                v-if="data.item"
                size="35">
                <profile-image
                    :item-id="data.item._id"
                    :type="$getConst('typeKeys').plants"
                    :image="data.item.profileImage"
                    :item-color="data.item.color"
                    :size="35">
                </profile-image>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title v-html="data.item.name">
                </v-list-item-title>
                <v-list-item-subtitle>
                    Updated: {{ data.item.updatedAt | moment('MM/DD/YYYY') }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </v-select>
</template>

<script>
    import ProfileImage from "../../components/Images/ProfileImage.vue";
    import {PlantsService} from "../../services/plants.service";

    export default {
        name: 'PlantsSelect',
        components: {ProfileImage},
        data() {
            return {
                loading: true,
                plantsId: null,
                resultsPlants: []
            }
        },
        props: {
            selected: {
                required: false,
                type: [String,Array,Object],
                default: null
            },
            multiple: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        methods: {
            remove(item) {
                if (Array.isArray(this.plantsId)) {
                    this.plantsId = this.plantsId.filter(plant => plant._id !== item._id)
                } else {
                    this.plantsId = []
                    this.$emit('updatePlantsId', [])
                }
            },
            async getPlants(options) {
                this.resultsPlants = []
                this.loading = true
                try {
                    const resultsPlants = await PlantsService.get(null, true)

                    let plants = resultsPlants.data.plants
                    const groups = plants.reduce((accumulator, plant) => {
                        const { spaceId } = plant
                        let plantSpace
                        if (spaceId) {
                            plantSpace = spaceId.name
                        } else {
                            plantSpace = 'Unassigned'
                        }
                        const existingGroupIndex = accumulator.findIndex(group => group.header === plantSpace)

                        if (existingGroupIndex === -1) {
                            accumulator.push({ header: plantSpace, children: [plant] })
                        } else {
                            accumulator[existingGroupIndex].children.push(plant)
                        }

                        return accumulator
                    }, [])

                    plants = groups.flatMap(group => [group, ...group.children])
                    if (plants.length) {
                        this.resultsPlants = plants
                    }

                    if (options && options.plantId) {
                        this.plantsId.push(options.plantId)
                        this.$emit('updatePlantsId', this.plantsId)
                    }

                    this.loading = false
                } catch (error) {
                    this.loading = false
                    this.type = 'error'
                    this.message = error
                }
            },
            closeSelect() {
                this.$refs.select.blur();
            }
        },
        created() {
            this.$EventBus.$on('refreshData', (options) => this.getPlants(options))
            this.$EventBus.$on('removePlantSelect', (item) => this.remove(item))
        },
        mounted() {
            if (this.selected) {
                this.plantsId = Array.isArray(this.selected) ? this.selected : [this.selected]
            } else {
                this.plantsId = []
            }
            this.getPlants()
        }
    }
</script>

<style scoped>
    .result-info {
        position: fixed;
        width: 100%;
        bottom: 65px;
        margin-bottom: 10px;
    }
</style>