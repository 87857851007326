<template>
    <div>
        <v-row
            :dense="isMobilePort"
            class="my-0"
            v-if="item.question">
            <v-col cols="auto">
                <v-avatar
                    color="primary"
                    class="white--text">
                    <template v-if="useText">
                        Q
                    </template>
                    <v-icon
                        v-else
                        dark>
                        mdi-account-outline
                    </v-icon>
                </v-avatar>
            </v-col>
            <v-col>
                <v-sheet
                    :color="`grey-lighten-4`"
                    class="mb-1 pa-3"
                    style="border-radius: 5px;">
                    <markdown-it-vue
                        class="markdown-it-container"
                        :content="item.question" />
                    <v-card
                        v-if="item && item.image"
                        class="mt-2"
                        outlined
                        max-width="220px">
                        <single-image
                            v-if="item && item.image"
                            :image="item.image"
                            :size="350">
                        </single-image>
                    </v-card>
                </v-sheet>
            </v-col>
        </v-row>
        <v-row
            :dense="isMobilePort"
            class="my-0"
            v-if="item.response">
            <v-col cols="auto">
                <v-avatar
                    color="success"
                    class="white--text">
                    <template v-if="useText">
                        A
                    </template>
                    <v-icon
                        v-else
                        dark>
                        mdi-creation-outline
                    </v-icon>
                </v-avatar>
            </v-col>
            <v-col>
                <v-sheet
                    :color="`grey-lighten-4`"
                    class="mb-1 pa-3"
                    style="border-radius: 5px;">
                    <template>
                        <markdown-it-vue
                            class="markdown-it-container"
                            :content="item.response" />
                    </template>
                </v-sheet>
            </v-col>
            <v-col
                v-if="!hideMenu"
                cols="auto">
                <ai-menu
                    :item="item"
                    :show-restore="showRestore"
                    :show-submit="showSubmit">
                </ai-menu>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import MarkdownItVue from 'markdown-it-vue'
    import 'markdown-it-vue/dist/markdown-it-vue.css'
    import SingleImage from "@/components/Images/SingleImage.vue";
    import AiMenu from "@/components/Ai/AiMenu.vue";

    export default {
        name: 'AIQuestionAnswer',
        components: {
            SingleImage,
            MarkdownItVue,
            AiMenu
        },
        props: {
            item: {
                required: true,
                type: Object
            },
            hideMenu: {
                required: false,
                type: Boolean,
                default: false
            },
            showRestore: {
                required: false,
                type: Boolean,
                default: true
            },
            showSubmit: {
                required: false,
                type: Boolean,
                default: true
            },
            useText: {
                required: false,
                type: Boolean
            }
        }
    }
</script>