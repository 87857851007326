export default {
    computed: {
        spaces() {
            if (this.$store.getters['spaces/Spaces']) {
                return this.$store.getters['spaces/Spaces'];
            }
            return [];
        },
        singleSpace() {
            if (this.$store.getters['spaces/SingleSpace']) {
                return this.$store.getters['spaces/SingleSpace'];
            }
            return {};
        },
        spacesLoading() {
            return this.$store.getters['spaces/Loading']
        }
    },
    methods: {
        showSpaceModal() {
            this.$EventBus.$emit('showSpaceModal', [])
        }
    }
}