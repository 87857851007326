import {GeneticsService} from "../services/genetics.service";
import {ErrorService} from "../services/error.service";

const geneticsGetterTypes = {
    error: 'GetError',
    genetics: 'Genetics',
    singleGenetics: 'SingleGenetics',
    loading: 'Loading'
}

const geneticsMutationTypes = {
    setLoading: 'SetLoading',
    setSuccess: 'SetSuccess',
    setSingle: 'SetSingle',
    setError: 'SetError',
}

const geneticsActionTypes = {
    get: 'GetAll',
    add: 'Add',
    getSingle: 'GetSingle',
    update: 'Update',
    delete: 'Delete',
    clearSingle: 'ClearSingle'
}

const state = () => ({
    error: null,
    records: undefined,
    singleRecord: null,
    loading: false,
})

const getters = {
    [geneticsGetterTypes.error]: (state) => state.error,
    [geneticsGetterTypes.genetics]: (state) => state.records,
    [geneticsGetterTypes.singleGenetics]: (state) => state.singleRecord,
    [geneticsGetterTypes.loading]: (state) => state.loading,
}

const mutations = {
    [geneticsMutationTypes.setLoading](state) {
        state.loading = true
    },

    [geneticsMutationTypes.setSuccess](state, data) {
        state.records = data
        state.loading = false
    },

    [geneticsMutationTypes.setSingle](state, data) {
        state.singleRecord = data
        state.loading = false
    },

    [geneticsMutationTypes.setError](state, message) {
        state.error = message
        state.loading = false
    },
}

const actions = {
    async [geneticsActionTypes.get]({ commit }) {
        commit(geneticsMutationTypes.setLoading)
        try {
            const response = await GeneticsService.get(null, true)
            commit(geneticsMutationTypes.setSuccess, response.data.genetics)
        } catch ({error, response}) {
            commit(geneticsMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [geneticsActionTypes.getSingle]({ state, commit }, id) {
        commit(geneticsMutationTypes.setLoading)
        try {
            const response = await GeneticsService.get(id)
            commit(geneticsMutationTypes.setSingle, response.data.results)
        } catch ({error, response}) {
            commit(geneticsMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [geneticsActionTypes.add]({ dispatch, commit }, data) {
        try {
            const genetics = await GeneticsService.post(data)
            await dispatch(geneticsActionTypes.get)
            return genetics.data.genetics._id
        } catch ({error, response}) {
            commit(geneticsMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [geneticsActionTypes.update]({ dispatch, commit }, payload) {
        try {
            await GeneticsService.put(payload.id, payload)
            await dispatch(geneticsActionTypes.get)
            dispatch(geneticsActionTypes.getSingle, payload.id)
        } catch ({error, response}) {
            commit(geneticsMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [geneticsActionTypes.delete]({ dispatch, commit }, id) {
        try {
            await GeneticsService.delete(id)
            await dispatch(geneticsActionTypes.get, true)
            await window.vm.$store.dispatch('images/GetUsage')
        } catch ({error, response}) {
            commit(geneticsMutationTypes.setError, ErrorService.parseError(error, response))
        }
    },

    async [geneticsActionTypes.clearSingle]({ state, commit }) {
        try {
            commit(geneticsMutationTypes.setSingle, undefined)
        } catch (error) {
            commit(geneticsMutationTypes.setError, ErrorService.parseError(error))
        }
    },
}

export const GeneticsStoreModule = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}