<template>
    <v-dialog
        persistent
        max-width="1020"
        v-model="dialog"
        :fullscreen="isMobilePort"
        style="z-index: 215">
        <v-card>
            <v-card-title>
                <v-row align="center">
                    <v-col>
                        {{edit ? 'Edit' : 'Add'}} Space
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            icon
                            small
                            @click="close()">
                            <v-icon>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <card-loader
                v-if="modalLoading"
                class="mx-3"
                flat>
            </card-loader>
            <v-card-text
                v-else
                class="pt-2">
                <notification-modular
                    v-if="type && message"
                    :notification-type="type"
                    :message="message">
                </notification-modular>
                <v-form
                    ref="addSpaceForm"
                    class="mt-2 mb-4">
                    <v-row>
                        <v-col
                            cols="12"
                            sm="6">
                            <v-text-field
                                :rules="nameRules"
                                outlined
                                hide-details="auto"
                                v-model="space.name"
                                label="Name">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <p class="caption mb-1">
                                Description:
                            </p>
                            <w-y-s-i-w-y-g-editor
                                v-if="dialog"
                                :default-description="space.description"
                                @updateDescription="(data) => space.description = data">
                            </w-y-s-i-w-y-g-editor>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card outlined>
                                <v-card-title
                                    @click="details = !details"
                                    style="cursor: pointer;">
                                    <h5>
                                        Additional Details
                                    </h5>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-icon>
                                        {{details ? 'mdi-chevron-up' : 'mdi-chevron-down'}}
                                    </v-icon>
                                </v-card-title>
                                <v-expand-transition>
                                    <div v-show="details">
                                        <v-card-text>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    sm="3">
                                                    <v-select
                                                        outlined
                                                        hide-details="auto"
                                                        v-model="space.spaceStatus"
                                                        :items="$getConst('spaceStatuses')"
                                                        item-text="name"
                                                        item-value="value"
                                                        label="Status"
                                                        :menu-props="{ offsetY: true }">
                                                    </v-select>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="3">
                                                    <color-picker
                                                        :default-color="space.color"
                                                        @updateColor="(color) => space.color = color">
                                                    </color-picker>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="3">
                                                    <v-text-field
                                                        outlined
                                                        hide-details="auto"
                                                        v-model="space.sqft"
                                                        label="SQ Feet">
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </div>
                                </v-expand-transition>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer>
                </v-spacer>
                <v-btn
                    rounded
                    text
                    @click="close()">
                    Cancel
                </v-btn>
                <v-btn
                    rounded
                    color="success"
                    @click="addEditSpace()"
                    :disabled="loading"
                    :loading="loading">
                    {{ edit ? 'Save' : 'Add' }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import ColorPicker from "../Forms/ColorPicker";
    import WYSIWYGEditor from "../Forms/WYSIWYGEditor";
    import AddEditPanel from "../TabPanels/AddEditPanel.vue";
    import NotificationModular from "@/components/Application/NotificationModular.vue";
    import {SpacesService} from "@/services/spaces.service";
    import CardLoader from "@/components/Application/CardLoader.vue";

    export default {
        data() {
            return {
                dialog: false,
                loading: false,
                modalLoading: false,
                edit: false,
                space: {
                    userId: 0,
                    name: '',
                    color: this.$getConst('defaultColor'),
                    spaceStatus: 2,
                    sqft: '',
                    description: ''
                },
                details: false,
                type: null,
                message: null,
            }
        },
        components: {
            CardLoader,
            NotificationModular,
            AddEditPanel,
            ColorPicker,
            WYSIWYGEditor
        },
        methods: {
            async showSpaceModalView(data = {}) {
                this.type = null
                this.message = null
                this.edit = false;
                this.modalLoading = true
                this.dialog = true
                if (data && data._id) {
                    try {
                        const response = await SpacesService.get(data._id)
                        this.space = response.data.space
                    } catch ({response}) {
                        this.type = 'error'
                        this.message = response.data.message
                        return false
                    }
                    this.edit = true
                } else {
                    this.defaultSpace()
                    if (data.redirectAfter) {
                        this.redirectAfter = true
                    }
                }
                this.modalLoading = false
            },
            defaultSpace() {
                this.$set(this, 'space', {
                    userId: 0,
                    name: '',
                    color: this.$getConst('defaultColor'),
                    spaceStatus: 2,
                    sqft: '',
                    description: ''
                })
            },
            async addEditSpace() {
                try {
                    if (this.space.name === '') {
                        this.$refs.addSpaceForm.validate()
                        return false;
                    }

                    this.loading = true
                    let responseData

                    if (this.edit) {
                        responseData = await SpacesService.put(this.space._id, this.space)
                    } else {
                        responseData = await SpacesService.post(this.space)
                    }

                    const responseId = responseData.data.results._id
                    if (responseData && this.redirectAfter) {
                        this.$router.push({name: 'SpacesView', params: {id: responseId}})
                    }
                    this.$EventBus.$emit('refreshData', {spaceId: responseId})
                    this.close()
                } catch ({response}) {
                    this.type = 'error'
                    this.message = response.data.message
                    this.loading = false;
                }
            },
            close() {
                this.dialog = false
                this.redirectAfter = false
                const _this = this
                setTimeout(function() {
                    _this.defaultSpace()
                    _this.edit = false
                    _this.loading = false
                }, this.$getConst('modalClearTimeout'))
            }
        },
        created() {
            this.$EventBus.$on('showSpaceModal', (data) => this.showSpaceModalView(data))
        }
    }
</script>

<style lang="scss">
    .custom-tabs {
        .theme--light.v-tabs > .v-tabs-bar {
            background-color: unset;
        }
    }
</style>