export default {
    computed: {
        genetics() {
            if (this.$store.getters['genetics/Genetics']) {
                return this.$store.getters['genetics/Genetics'];
            }
            return [];
        },
        singleGenetics() {
            if (this.$store.getters['genetics/SingleGenetics']) {
                return this.$store.getters['genetics/SingleGenetics'];
            }
            return [];
        },
        geneticsLoading() {
            return this.$store.getters['genetics/Loading'];
        }
    },
    methods: {
        showGeneticsModal() {
            this.$EventBus.$emit('showGeneticsModal')
        }
    }
}