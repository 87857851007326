import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.blue,
                secondary: colors.blueGrey.darken1,
                accent: colors.lightBlue.accent4,
                bright: colors.shades.white,
                success: colors.green.base,
                info: colors.lightBlue.base,
                error: colors.red.base,
                lightGreen: '#D6EDDE'
            },
            dark: {
                primary: colors.blue,
                secondary: colors.grey.darken2,
                accent: colors.blue.darken4,
                bright: colors.shades.white,
                success: colors.green.darken2,
                info: colors.purple.darken2,
                error: colors.red.darken1,
                lightGreen: '#D6EDDE'
            },
        },
        icons: {
            iconfont: 'fa' || 'mdi' // || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
        }
    },
})
