import api from "./api";
const qs = require('qs');

export const SpacesService = {
    get(id = null, mine = false) {
        if (id) {
            return api.get(`/spaces/id/${id}`)
        } else if (mine) {
            return api.get('/spaces/mine')
        }
        return api.get('/spaces');
    },
    getResults(data) {
        const queryString = qs.stringify(data)
        return api.get(`/spaces?${queryString}`)
    },
    post(data) {
        return api.post('/spaces', data)
    },
    put(id, data) {
        return api.put(`/spaces/id/${id}`, data)
    },
    delete(id) {
        return api.delete(`/spaces/id/${id}`)
    }
}
