<template>
    <v-dialog
        max-width="800"
        v-model="dialog"
        :fullscreen="isMobilePort">
        <v-card>
            <v-card-title>
                <v-row align="center">
                    <v-col>
                        Edit Profile Picture
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            icon
                            small
                            @click="close()">
                            <v-icon>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-divider>
            </v-divider>
            <v-card-text class="pt-5">
                <v-row>
                    <v-col class="text-center">
                        <v-btn
                            rounded
                            class="mx-1"
                            v-if="hasProfile"
                            color="error"
                            @click="selectProfileImage(type, item._id)">
                            Remove Profile Picture
                        </v-btn>
                        <v-btn
                            rounded
                            class="mx-1"
                            color="success"
                            :loading="isSelecting || loading"
                            @click="addImage()"
                            :disabled="loading">
                            Upload Image
                        </v-btn>
                    </v-col>
                </v-row>
                <input
                    ref="uploader"
                    class="d-none"
                    type="file"
                    @change="onFileChanged">
                <v-row>
                    <v-col>
                        <p class="text-center">
                            or select from an existing image
                        </p>
                    </v-col>
                </v-row>
                <images-grid
                    v-if="item && item._id"
                    :item="item"
                    :profile-select="true"
                    :associated-id="item._id"
                    type="all"
                    :size="120"
                    :show-per-page="false"
                    :store-key="type">
                </images-grid>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import ImagesGrid from "./ImagesGrid";
    import {ImageService} from "../../services/image.service";

    export default {
        data() {
            return {
                dialog: false,
                item: null,
                type: null,
                hasProfile: false,
                isSelecting: false,
                selectedFile: null,
                loading: false
            }
        },
        components: {
            ImagesGrid
        },
        methods: {
            showModal(data) {
                this.hasProfile = data.item.profileImage
                this.item = data.item
                this.type = data.type
                this.dialog = true
            },
            close() {
                this.item = null;
                this.itemKey = null;
                this.hasProfile = false;
                this.selectedFile = null;
                this.dialog = false;
            },
            handleFileImport() {
                this.isSelecting = true;

                window.addEventListener('focus', () => {
                    this.isSelecting = false
                }, { once: true });

                this.$refs.uploader.click();
            },
            async onFileChanged(e) {
                this.loading = true;
                this.selectedFiles = e.target.files[0];
                const date = Date.now();
                const formData = new FormData();
                const originalFile = this.selectedFiles;
                const filePathArray = originalFile.name.split('.');
                const newFileName = this.type+'_'+this.item._id+'_'+date+'_0.'+filePathArray[filePathArray.length - 1];
                const file = new File([originalFile], newFileName, {
                    type: originalFile.type,
                    lastModified: originalFile.lastModified,
                });
                formData.append("files", file);
                const data = {
                    type: this.type,
                    associatedId: this.item._id,
                    profile: true
                }
                _.forEach(data, (value, key) => {
                    formData.append(key, value);
                })
                const response = await ImageService.upload(formData);
                await this.selectProfileImage(this.type, this.item._id, response.data.results[0]._id)
                this.loading = false;
                this.close();
            },
            addImage() {
                let data
                if (this.item) {
                    data = {
                        associatedId: this.item._id,
                        type: this.type
                    }
                }
                this.$EventBus.$emit('showAddImageModal', data)
            }
        },
        mounted() {
            this.$EventBus.$on('showProfileSelectModal', (data) => this.showModal(data));
            this.$EventBus.$on('hideProfileSelectModal', () => this.close());
        }
    }
</script>