<template>
    <div
        class="wave-content"
        :class="$vuetify.theme.dark ? '' : 'white'">
        <div
            class="success mb-2"
            :class="{'px-3': isMobilePort}"
            style="z-index: 1; position: relative;"
            :style="`background-color: ${$vuetify.theme.dark ? '#388e3c!important' : ''}`">
            <v-row
                dense
                align="center"
                justify="center"
                class="pt-16">
                <v-col
                    cols="auto">
                    <v-icon
                        x-large
                        color="white">
                        mdi-leaf
                    </v-icon>
                </v-col>
                <v-col cols="auto">
                    <h1
                        class="font-weight-medium white--text"
                        style="font-size: 24px">
                        GrowLab AI
                    </h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="text-center">
                    <h1
                        class="font-weight-medium white--text"
                        :style="`font-size: ${isMobilePort ? 30 : 44}px`">
                        Welcome to GrowLab AI
                    </h1>
                    <h2
                        class="font-weight-regular white--text"
                        style="font-size: 20px">
                        Master Your Plant Management with GrowLab AI™
                    </h2>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <v-img
                        max-width="700px"
                        contain
                        src="/images/ad_png.png">
                    </v-img>
                </v-col>
            </v-row>
        </div>
        <svg id="wave" style="transform:rotate(180deg); transition: 0.3s" viewBox="0 0 1440 180" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
            <stop :stop-color="$vuetify.theme.dark ? '#212121 ' : 'rgba(214, 237, 222, 1)'" offset="0%"></stop>
            <stop :stop-color="$vuetify.theme.dark ? '#212121 ' : 'rgba(214, 237, 222, 1)'" offset="100%"></stop>
        </linearGradient></defs><path style="transform:translate(0, 0px); opacity:1" fill="url(#sw-gradient-0)" d="M0,72L60,69C120,66,240,60,360,54C480,48,600,42,720,60C840,78,960,120,1080,129C1200,138,1320,114,1440,90C1560,66,1680,42,1800,45C1920,48,2040,78,2160,87C2280,96,2400,84,2520,75C2640,66,2760,60,2880,75C3000,90,3120,126,3240,120C3360,114,3480,66,3600,48C3720,30,3840,42,3960,39C4080,36,4200,18,4320,12C4440,6,4560,12,4680,12C4800,12,4920,6,5040,18C5160,30,5280,60,5400,63C5520,66,5640,42,5760,30C5880,18,6000,18,6120,39C6240,60,6360,102,6480,105C6600,108,6720,72,6840,60C6960,48,7080,60,7200,81C7320,102,7440,132,7560,123C7680,114,7800,66,7920,57C8040,48,8160,78,8280,87C8400,96,8520,84,8580,78L8640,72L8640,180L8580,180C8520,180,8400,180,8280,180C8160,180,8040,180,7920,180C7800,180,7680,180,7560,180C7440,180,7320,180,7200,180C7080,180,6960,180,6840,180C6720,180,6600,180,6480,180C6360,180,6240,180,6120,180C6000,180,5880,180,5760,180C5640,180,5520,180,5400,180C5280,180,5160,180,5040,180C4920,180,4800,180,4680,180C4560,180,4440,180,4320,180C4200,180,4080,180,3960,180C3840,180,3720,180,3600,180C3480,180,3360,180,3240,180C3120,180,3000,180,2880,180C2760,180,2640,180,2520,180C2400,180,2280,180,2160,180C2040,180,1920,180,1800,180C1680,180,1560,180,1440,180C1320,180,1200,180,1080,180C960,180,840,180,720,180C600,180,480,180,360,180C240,180,120,180,60,180L0,180Z"></path><defs><linearGradient id="sw-gradient-1" x1="0" x2="0" y1="1" y2="0">
            <stop :stop-color="$vuetify.theme.dark ? '#388e3c ' : 'rgba(76, 175, 80, 1)'" offset="0%"></stop>
            <stop :stop-color="$vuetify.theme.dark ? '#388e3c ' : 'rgba(76, 175, 80, 1)'" offset="100%"></stop>
        </linearGradient></defs><path style="transform:translate(0, 50px); opacity:1" fill="url(#sw-gradient-1)" d="M0,126L60,108C120,90,240,54,360,42C480,30,600,42,720,60C840,78,960,102,1080,102C1200,102,1320,78,1440,78C1560,78,1680,102,1800,114C1920,126,2040,126,2160,114C2280,102,2400,78,2520,78C2640,78,2760,102,2880,108C3000,114,3120,102,3240,105C3360,108,3480,126,3600,120C3720,114,3840,84,3960,75C4080,66,4200,78,4320,87C4440,96,4560,102,4680,87C4800,72,4920,36,5040,45C5160,54,5280,108,5400,123C5520,138,5640,114,5760,93C5880,72,6000,54,6120,39C6240,24,6360,12,6480,9C6600,6,6720,12,6840,33C6960,54,7080,90,7200,111C7320,132,7440,138,7560,117C7680,96,7800,48,7920,51C8040,54,8160,108,8280,114C8400,120,8520,78,8580,57L8640,36L8640,180L8580,180C8520,180,8400,180,8280,180C8160,180,8040,180,7920,180C7800,180,7680,180,7560,180C7440,180,7320,180,7200,180C7080,180,6960,180,6840,180C6720,180,6600,180,6480,180C6360,180,6240,180,6120,180C6000,180,5880,180,5760,180C5640,180,5520,180,5400,180C5280,180,5160,180,5040,180C4920,180,4800,180,4680,180C4560,180,4440,180,4320,180C4200,180,4080,180,3960,180C3840,180,3720,180,3600,180C3480,180,3360,180,3240,180C3120,180,3000,180,2880,180C2760,180,2640,180,2520,180C2400,180,2280,180,2160,180C2040,180,1920,180,1800,180C1680,180,1560,180,1440,180C1320,180,1200,180,1080,180C960,180,840,180,720,180C600,180,480,180,360,180C240,180,120,180,60,180L0,180Z"></path></svg>
        <v-container
            :class="{'px-8': isMobilePort, 'mt-n8': !isMobilePort}"
            style="max-width: 1100px;">
            <v-row>
                <v-col>
                    <h3 class="mb-3">
                        What is GrowLab AI?
                    </h3>
                    <p>
                        GrowLab AI™ is a digital assistant that revolutionizes plant management. We simplify the complexities of gardening with an easy-to-use app where you can catalogue your spaces, genetics, and individual plants. Add personal notes, observations, and images to enrich your cultivation journey. Whoever you are, from a novice to a seasoned gardener, GrowLab AI™ makes plant management fun and hassle-free. Ready to cultivate a brighter, greener future?
                    </p>
                </v-col>
            </v-row>
            <v-row
                dense
                justify="center">
                <v-col cols="auto">
                    <v-btn
                        rounded
                        x-large
                        class="mr-1"
                        color="primary"
                        to="/about">
                        Learn More
                    </v-btn>
                    <v-btn
                        rounded
                        x-large
                        class="ml-1"
                        color="success"
                        to="/pricing">
                        Sign Up
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <div :class="{'mx-2': isMobilePort, 'mx-auto': !isMobilePort}">
            <div
                class="mt-16 pt-8 pb-12"
                :class="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-5'">
                <v-container style="max-width: 1100px;">
                    <v-row justify="center">
                        <v-col sm="8">
                            <h2 class="mb-2 text-center">
                                Mona AI - Your Personal Plant Assistant
                            </h2>
                            <p class="text-center">
                                Discover, diagnose, and nurture your plants with Mona AI - Your anytime, anywhere plant care expert, blending cutting-edge technology with botanical wisdom.
                            </p>
                        </v-col>
                    </v-row>
                    <h1 class="mt-6 mb-6 text-center">
                        Try It Out!
                    </h1>
                    <ai-sample-questions>
                    </ai-sample-questions>
                    <v-row align="center">
<!--                        <v-col-->
<!--                            cols="12"-->
<!--                            sm="6">-->
<!--                            <v-img-->
<!--                                max-width="500px"-->
<!--                                contain-->
<!--                                class="rounded-lg"-->
<!--                                src="/images/mona_ai.png">-->
<!--                            </v-img>-->
<!--                        </v-col>-->
                        <v-col class="text-center">
<!--                            <h4>-->
<!--                                What can Mona do?-->
<!--                            </h4>-->
<!--                            <div class="mb-6 mt-2">-->
<!--                                <v-row-->
<!--                                    dense-->
<!--                                    v-for="(item, key) in whatCanMonaDo"-->
<!--                                    :key="key"-->
<!--                                    align="center">-->
<!--                                    <v-col cols="auto">-->
<!--                                        <v-avatar-->
<!--                                            rounded-->
<!--                                            size="30"-->
<!--                                            :color="$vuetify.theme.dark ? 'grey darken-4' : 'white'">-->
<!--                                            <v-icon color="success">-->
<!--                                                {{item.icon}}-->
<!--                                            </v-icon>-->
<!--                                        </v-avatar>-->
<!--                                    </v-col>-->
<!--                                    <v-col>-->
<!--                                        <p class="mb-0">-->
<!--                                            {{item.title}}-->
<!--                                        </p>-->
<!--                                    </v-col>-->
<!--                                </v-row>-->
<!--                            </div>-->
<!--                            <ask-mona-button-->
<!--                                v-if="user"-->
<!--                                class="mr-2"-->
<!--                                large>-->
<!--                            </ask-mona-button>-->
                            <br/>
                            <br/>
                            <v-btn
                                v-if="!user"
                                rounded
                                large
                                color="success"
                                to="/pricing">
                                <v-icon left>
                                    mdi-gift-outline
                                </v-icon>
                                Try for Free
                            </v-btn>
                            <ask-mona-button
                                v-else>
                            </ask-mona-button>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
        <v-container
            class="mt-12"
            style="max-width: 1100px;">
            <v-row class="mt-2">
                <v-col>
                    <h2 class="text-center">
                        Tools to Help Your Grow
                    </h2>
                </v-col>
            </v-row>
            <v-row
                class="mt-6"
                justify="center">
                <v-col
                    cols="12"
                    sm="4"
                    v-for="(item, key) in features"
                    :key="key"
                    class="text-center">
                    <v-row>
                        <v-col>
                            <v-avatar
                                rounded
                                class="elevation-3"
                                :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
                                size="80">
                                <v-icon
                                    x-large
                                    color="success">
                                    {{item.icon}}
                                </v-icon>
                            </v-avatar>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <h3 v-html="item.headline">
                            </h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <p
                                class="caption"
                                v-html="item.text">
                            </p>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
<!--            <v-row>-->
<!--                <v-col>-->
<!--                    <p class="font-weight-medium text-center">-->
<!--                        Dive into the world of intelligent gardening. <a class="success&#45;&#45;text" href="/pricing">Get started</a> with GrowLab AI™ today, it's FREE!-->
<!--                    </p>-->
<!--                </v-col>-->
<!--            </v-row>-->
<!--            <v-row class="mt-16 mb-4">-->
<!--                <v-col>-->
<!--                    <h2 class="text-center">-->
<!--                        Fresh Picks from our Community Gardeners-->
<!--                    </h2>-->
<!--                </v-col>-->
<!--            </v-row>-->
<!--            <v-row>-->
<!--                <v-col>-->
<!--                    <item-cards-->
<!--                        title="Spaces"-->
<!--                        :type="$getConst('typeKeys').spaces"-->
<!--                        :only-me="false"-->
<!--                        :has-profile="true">-->
<!--                        <template v-slot:button>-->
<!--                            <v-btn-->
<!--                                rounded-->
<!--                                color="success"-->
<!--                                to="/community/spaces">-->
<!--                                View All-->
<!--                            </v-btn>-->
<!--                        </template>-->
<!--                    </item-cards>-->
<!--                </v-col>-->
<!--            </v-row>-->
        </v-container>
<!--        <div class="success mt-16 py-8">-->
<!--            <v-container-->
<!--                style="max-width: 1100px;">-->
<!--                <v-row>-->
<!--                    <v-col>-->
<!--                        <item-cards-->
<!--                            title="Plants"-->
<!--                            additional-class="white&#45;&#45;text"-->
<!--                            :type="$getConst('typeKeys').plants"-->
<!--                            :only-me="false"-->
<!--                            :has-profile="true">-->
<!--                            <template v-slot:button>-->
<!--                                <v-btn-->
<!--                                    rounded-->
<!--                                    color="success"-->
<!--                                    to="/community/plants">-->
<!--                                    View All-->
<!--                                </v-btn>-->
<!--                            </template>-->
<!--                        </item-cards>-->
<!--                    </v-col>-->
<!--                </v-row>-->
<!--            </v-container>-->
<!--        </div>-->
<!--        <v-container-->
<!--            class="mt-16"-->
<!--            style="max-width: 1100px;">-->
<!--            <v-row>-->
<!--                <v-col>-->
<!--                    <item-cards-->
<!--                        title="Genetics"-->
<!--                        :type="$getConst('typeKeys').genetics"-->
<!--                        :only-me="false"-->
<!--                        :has-profile="true">-->
<!--                        <template v-slot:button>-->
<!--                            <v-btn-->
<!--                                rounded-->
<!--                                color="success"-->
<!--                                to="/community/genetics">-->
<!--                                View All-->
<!--                            </v-btn>-->
<!--                        </template>-->
<!--                    </item-cards>-->
<!--                </v-col>-->
<!--            </v-row>-->
<!--        </v-container>-->
        <div class="mt-16"
            :class="$vuetify.theme.dark ? 'grey darken-4' : 'lightGreen'">
            <v-container
                class="py-16"
                style="max-width: 1100px;">
                <v-row>
                    <v-col class="text-center">
                        <h2>
                            Ready to get started?
                        </h2>
                        <existing-account-text class="mt-3">
                        </existing-account-text>
                        <v-btn
                            class="mt-4"
                            rounded
                            x-large
                            color="success"
                            to="/pricing">
                            Register Today
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container
            class="my-16"
            style="max-width: 1100px;">
            <v-row>
                <v-col>
                    <v-alert
                        border="left"
                        colored-border
                        color="success"
                        elevation="0">
                        Our plant management app is the perfect tool for serious gardeners and plant enthusiasts who want to take their plant game to the next level. With our easy-to-use interface and powerful features, you can manage your plants like a pro, track their growth progress, and experiment with new growing strategies. Sign up now and start mastering your plant management!
                    </v-alert>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card
                        class="ma-auto mt-6 mb-12"
                        outlined>
                        <v-card-text>
                            <v-row align="center">
                                <v-col>
                                    <h2>
                                        Join Our Referral Program
                                    </h2>
                                    <p class="mt-4 mb-0">
                                        Invite friends and earn rewards! Get a 20% commission for every referral that purchases a subscription.
                                    </p>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="auto"
                                    :class="isMobilePort ? 'text-center' : ''">
                                    <v-btn
                                        color="success"
                                        rounded
                                        to="/about/referrals">
                                        Learn More
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import SubscriptionLevels from "../components/Application/SubscriptionLevels";
    import AiChat from "../components/Ai/AiChat";
    import ExistingAccountText from "../components/Application/ExistingAccountText.vue";
    import ItemCards from "../components/Application/ItemCards.vue";
    import AskMonaButton from "../components/Buttons/AskMona.vue";
    import MonaContentMixin from "../mixins/content/mona.content.mixin";
    import CardLoader from "@/components/Application/CardLoader.vue";
    import {AiService} from "@/services/ai.service";
    import SingleImage from "@/components/Images/SingleImage.vue";
    import AIQuestionAnswer from "@/components/Ai/QuestionAnswer.vue";
    import AiSampleQuestions from "@/components/Ai/SampleQuestions.vue";

    export default {
        name: 'Home',
        metaInfo: {
            title: 'GrowLab AI',
            titleTemplate: '%s - Simplify Space, Plants, and Genetics Management',
            htmlAttrs: {
                lang: 'en',
                amp: true
            },
            meta: [
                { 'charset': 'utf-8' },
                {
                    name: 'description',
                    content: 'Streamline your indoor growing journey with GrowLab AI. With our advanced platform, you can easily manage your spaces, plants, and genetics while adding notes and images for efficient cultivation. Enhance your experience further with Mona AI, our AI-powered chatbot that analyzes images and provides crucial insights to ensure the optimal health of your plants. Upgrade your growing game with GrowLab AI today!',
                    vmid: 'description'
                },
                {
                    property: 'og:title',
                    content: 'GrowLab AI',
                    template: chunk => `${chunk} - Simplify Space, Plants, and Genetics Management`,
                    vmid: 'og:title'
                },
                {
                    property: 'og:image',
                    content: '/images/ad_png.png',
                    vmid: 'og:image'
                }
            ],
        },
        components: {
            AiSampleQuestions,
            AIQuestionAnswer,
            SingleImage,
            CardLoader,
            AskMonaButton,
            ItemCards,
            ExistingAccountText,
            SubscriptionLevels,
            AiChat
        },
        mixins: [MonaContentMixin],
        data() {
            return {
                monaDialog: false,
                features: [
                    {
                        icon: 'mdi-sprout-outline',
                        headline: 'Plant Management',
                        text: 'Organize and oversee all your plant varieties in one place. Monitor growth stages, health, and maintenance needs.'
                    },
                    {
                        icon: 'mdi-grid-large',
                        headline: 'Space Management',
                        text: 'Easily add, edit, and delete grow spaces for your plants. Keep track of the conditions with notes and images.'
                    },
                    {
                        icon: 'mdi-dna',
                        headline: 'Genetic Tracking',
                        text: 'Keep detailed records of your plants\' genetic information, including photo logs, breeding history, and phenotypic traits.'
                    },
                    {
                        icon: 'mdi-watering-can-outline',
                        headline: 'Feeding Schedules',
                        text: 'Create and maintain feeding schedules. Track watering, fertilizing, and nutrient adjustments for each plant.'
                    },
                    {
                        icon: 'mdi-database-check-outline',
                        headline: 'Note Taking and Photos',
                        text: 'Take detailed notes on your plants\' growth progress and add photos to document their development over time.'
                    },
                    {
                        icon: 'mdi-format-list-checks',
                        headline: 'Todo Lists',
                        text: 'Stay organized with customizable todo lists. Plan and track your gardening tasks to ensure thorough care.'
                    }
                ]
            }
        }
    }
</script>